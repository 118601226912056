


































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { Document } from '@/models/user-documents.model';
import { fetchUserDocuments, fetchUserDocumentsByINN } from '@/controllers/user-documents.controllers';
import { getDateRepresentation } from '@/commons/filters.main';
import { TreeViewData } from '../../models/view.model';
import types from '@/store/types';

interface QueryDocumentsOption {
  id: number;
  value: string;
  document: Document;
}

const baseClassName = 'elib-documents-linked-documents';

@Component({
  name: baseClassName,
  computed: {
    ...mapState('documents', ['activeDocument', 'documentsList']),
    ...mapState(['token']),
    ...mapGetters('catalogues', ['cataloguesTreeViewNodeById']),
    ...mapGetters('session', ['hasAuthorityByCode']),
  },
  methods: {
    ...mapMutations('documents', ['updateActiveDocumentProperty']),
    ...mapActions('documents', [types.FETCH_DOCUMENT_BY_ID]),
  },
})
export default class LinkedDocumetns extends Vue {
  @Prop({ default: () => false }) private preview!: boolean;
  @Prop({ default: () => true }) private canSwitch!: boolean;
  private baseClassName = baseClassName;
  private self: any;
  private documentsQuery = '';
  private selectionMode = false;
  private documentsSuggestion: Array<{ id: number, value: string }> = [];
  private forParent = true;
  private loading = false;

  private get childDocuments() {
    return [];
  }
  private async openDocument(id: any) {
    this.$router.push({ name: 'documents-profile', params: { id: id.toString() } });
  }
  private async queryDocuments(query: string) {
    this.documentsQuery = query;
    if (!query || !query.trim() || query.length < 4 || this.loading) {
      this.documentsSuggestion = [];
      return;
    }
    this.loading = true;
    const documents = await fetchUserDocumentsByINN({ offset: 0, query });

    if (documents) {
      this.documentsSuggestion = documents
        .filter((doc) => !this.documentIds.includes(doc.documentId))
        .map((doc) => {
          return {
            id: doc.documentId,
            value: doc.documentName,
            document: doc,
          };
        });
    }
    this.loading = false;
  }

  private get documentIds() {
    const document = this.self.activeDocument as Document;
    if ((document as any).parentDocuments || (document as any).childDocuments) {
      const parentDocuments = (document as any).parentDocuments ?
        JSON.parse(JSON.stringify((document as any).parentDocuments)) : [];
      const childDocuments = (document as any).childDocuments ?
        JSON.parse(JSON.stringify((document as any).childDocuments)) : [];
      return [...parentDocuments, ...childDocuments].map((doc) => doc.documentId);
    }
    return [];
  }

  private onDocumentsSelect(option: QueryDocumentsOption) {
    const document = this.self.activeDocument as Document;
    this.addLinkedDocumentIds(document, option.id, this.forParent);
    this.addLinkedDocument(document, option.document, this.forParent);
    this.selectionMode = false;
    this.documentsQuery = '';
  }

  private addLinkedDocumentIds(document: Document, id: number, forParents: boolean) {
    const ids = [...(forParents ? document.parentIds : document.childrenIds)];
    ids.push(id);
    this.self.updateActiveDocumentProperty(
      {
        prop: forParents ? 'parentIds' : 'childrenIds',
        value: ids,
      },
    );
  }

  private addLinkedDocument(document: Document, linkedDocument: Document, forParents: boolean) {
    const linkedDocumetns = [...((forParents ? document.parentDocuments : document.childDocuments) || [])];
    linkedDocumetns.push(linkedDocument);
    this.self.updateActiveDocumentProperty(
      {
        prop: forParents ? 'parentDocuments' : 'childDocuments',
        value: linkedDocumetns,
      },
    );
  }

  private onDocumentsSelectCancle() {
    this.documentsQuery = '';
    this.selectionMode = false;
  }

  private showAutoComplete(forParents = true) {
    const parents = (this.$refs as any).parentSection as Element;
    const children = (this.$refs as any).childrenSection as Element;
    const autocomplete = (this.$refs as any).autocomplete as Vue;
    this.forParent = forParents;
    this.selectionMode = true;
    forParents ? parents.appendChild(autocomplete.$el) : children.appendChild(autocomplete.$el);
  }

  private created() {
    this.onComponentEnter();
  }

  private async onComponentEnter() {
    if (this.preview) {
      return;
    }
    const documents = await fetchUserDocuments({ offset: 0, query: '' });
    if (documents) {
      this.documentsSuggestion = documents
        .filter((doc) => !this.documentIds.includes(doc.documentId))
        .map((doc) => {
          return { id: doc.documentId, value: doc.documentName, document: doc };
        });
    }
  }

  private getLinkedDocumentDateNumber(document: Document) {
    return `№ ${document.documentNumber} от ${getDateRepresentation(document.created)}`;
  }

  private getDocumentsTypePath(document: Document) {
    const node = this.self.cataloguesTreeViewNodeById((document as any).documentTypeId) as TreeViewData;
    if (!node) {
      return;
    }
    return node.path;
  }

  private removeLinkedDocument(id: number) {
    const document = this.self.activeDocument as Document;
    const ids = [...(this.forParent ? document.parentIds : document.childrenIds)].filter((v) => v !== id);
    const linkedDocumetns = [...((this.forParent ? document.parentDocuments : document.childDocuments) || [])].
      filter((doc) => doc.documentId !== id);
    this.self.updateActiveDocumentProperty(
      {
        prop: this.forParent ? 'parentIds' : 'childrenIds',
        value: ids,
      },
    );
    this.self.updateActiveDocumentProperty(
      {
        prop: this.forParent ? 'parentDocuments' : 'childDocuments',
        value: linkedDocumetns,
      },
    );
  }

  private goToDocument(document: Document) {
    if (!this.canSwitch) {
      return;
    }
    if (document.deleted) {
      this.$notifier({
        title: 'Нельзя открыть документ!',
        message: `Документ ${document.documentName} логически удален`,
        type: 'error',
      });
      return;
    }
    this.$emit('document-link-click', document.documentId);
  }

  private beforeCreate() {
    this.self = this;
  }
}
