









import { Component, Vue, Prop, Model } from 'vue-property-decorator';
import { Catalogue, createNewCatalogue } from '@/models/catalogues.model';

const baseClassName = 'elib-catalogue-input';

@Component({
  name: baseClassName,
})
export default class CatalogueInput extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  @Prop({ default: () => null }) private value!: Catalogue;
  private baseClassName = baseClassName;
  private editableValue = {...this.value};

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private saveChanges() {
    this.$emit('save', this.editableValue);
  }
}
