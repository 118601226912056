import { DocumentsAttribute } from '@/models/document-attributes.model';
import { $HTTPServicesClient as axiosClient, getUrlString, QueryParams } from '@/commons/axios.config';
import { AUTH_TOKEN_HEADER, AUTH_TOKEN_NAME } from '@/commons/constants';

export async function fetchDocumentAttributes(params: QueryParams) {
  try {
    const request = await axiosClient.get('/attribute-definitions', {
      params,
      headers: {
        [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME),
      },
    });
    const documentAttributes = request.data.data as DocumentsAttribute[];
    return setDefaultParamsForAttribute(documentAttributes);
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
}

export async function fetchRequiredDocumentAttributes(params: QueryParams = {}) {
  let documentAttributes;
  try {
    const request = await axiosClient.get('/attribute-definitions/required', {
      params,
      headers: {
        [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME),
      },
    });
    documentAttributes = request.data.data as DocumentsAttribute[];
    setDefaultParamsForAttribute(documentAttributes);
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
  return documentAttributes;
}

export async function fetchDocumentsAttributeById(token: string, id: number) {
  let documentsAttribute;
  try {
    const request = await axiosClient.get(`/attribute-definitions/${id}`, {
      headers: {
        'x-auth-token': token,
      },
    });
    documentsAttribute = (request.data as { data: DocumentsAttribute }).data;
    setDefaultParamsForAttribute([documentsAttribute]);
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
  return documentsAttribute;
}

export async function queryDocumentAttributesByName(query: string) {
  let documentAttributes;
  try {
    const request = await axiosClient.get('/attribute-definitions', {
      params: {
        query,
      },
      headers: {
        [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME),
      },
    });
    documentAttributes = (request.data as { data: DocumentsAttribute[] }).data.map((attrib) => {
      return {
        id: attrib.id,
        value: attrib.title,
        attributeDefinition: attrib,
      };
    });
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
  return documentAttributes || [];
}

export async function postNewDocumentsAttribute(token: string, data: DocumentsAttribute) {
  let documentsAttribute;
  try {
    const request = await axiosClient.post(`/attribute-definitions`, JSON.stringify(data), {
      headers: {
        'x-auth-token': token,
        'content-type': 'application/json',
      },
    });
    documentsAttribute = (request.data as { data: DocumentsAttribute }).data;
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
  return documentsAttribute;
}
export async function updateExistingDocumentsAttribute(token: string, data: DocumentsAttribute) {
  let documentsAttribute;
  try {
    const request = await axiosClient.put(`/attribute-definitions/${data.id}`, JSON.stringify(data),
      {
        headers: {
          'x-auth-token': token,
          'content-type': 'application/json',
        },
      },
    );
    documentsAttribute = (request.data as { data: DocumentsAttribute }).data;
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
  return documentsAttribute;
}

function setDefaultParamsForAttribute(attributes: DocumentsAttribute[]) {
  return attributes.map((attribute) => {
    if (!attribute.group) {
      attribute.group = '';
    }
    return attribute;
  });
}

export async function deleteAttribute(token: string, data: DocumentsAttribute) {
  try {
    await axiosClient.delete(`/attribute-definitions/${data.id}`,
      {
        headers: {
          'x-auth-token': token,
        },
      },
    );
    return true;
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
  return false;
}
