






import { Vue, Component } from 'vue-property-decorator';

const baseClassName = 'elib-user-documents-page';

@Component({
    name: baseClassName,
})
export default class UserDocumentsPage extends Vue {
    private baseClassName = baseClassName;
}
