






























import { Component, Vue, Prop } from 'vue-property-decorator';
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import { User } from '@/models/users.model';
import {
  syncUsersRdm,
  syncDepartmentsRdm,
} from '@/controllers/users.controllers';
import types from '@/store/types';
import { DocumentClassification } from '@/models/user-documents.model';

const baseClassName = 'elib-users-list';

@Component({
  name: baseClassName,
  computed: {
    ...mapState('users', ['usersList', 'filterString', 'activeUser']),
    ...mapGetters('users', ['filteredUsers']),
    ...mapGetters('session', ['hasAuthorityByCode']),
  },
  methods: {
    ...mapMutations('users', ['setActiveUser']),
    ...mapActions('users', [
      types.FETCH_USERS_ACTION,
      'getActiveUserById',
      'setFilterString',
    ]),
  },
})
export default class UsersList extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  private baseClassName = baseClassName;
  private syncUsersRdm = syncUsersRdm;
  private syncDepartmentsRdm = syncDepartmentsRdm;
  private usersList!: User[];

  private get className() {
    return (this.incomingClasses || []).reduce(
      (acc: string, value: string) => `${acc} ${value}`,
      baseClassName,
    );
  }

  private get userTableColumns() {
    return [
      {
        key: 'user',
        title: 'ФИО',
        width: 30,
        component: 'elib-profile-account-info',
      },
      { key: 'roles', title: 'Права', width: 40 },
      { key: 'documetAccess', title: 'Доступ', width: 30 },
    ];
  }

  private get usersTableData() {
    return this.usersList.map((user) => {
      return {
        user,
        roles: user.roles.map((v) => v.title).join(', '),
        documetAccess: this.maxDocumentSecrecy(user),
      };
    });
  }

  private maxDocumentSecrecy(user: User) {
    const categories = user.roles
      .map((v) => v.roleAuthorities)
      .flat()
      .filter((v) => /^.*(C[0-4])$/gi.test(v.title))
      .map((v) => parseInt(v.title.substring(v.title.length - 1), 10))
      .sort((a, b) => b - a);
    const accessCode = categories.map((category: any) => {
      return `C${category}`;
    });
    const documentClassification = accessCode.map((doc: any) => {
      return (DocumentClassification as any)[doc];
    });
    const sortArr = documentClassification.filter((it, index) => {
      return index === documentClassification.indexOf((it = it.trim()));
    });
    return sortArr.join(', ');
  }

  private created() {
    this.onComponentEnter();
  }

  private activated() {
    this.onComponentEnter();
  }

  private mounted() {
    (this.$el as HTMLElement).addEventListener('scroll', this.onScroll);
  }

  private onComponentEnter() {
    (this as any)[types.FETCH_USERS_ACTION]();
  }

  private onRowClick(row: { user: User }) {
    if (!(this as any).hasAuthorityByCode(1)) {
      this.$notifier({
        title: 'Недостаточно прав!',
        message: 'У вас нет прав для редактирования прав пользователей',
        type: 'error',
      });
      return;
    }
    if (row.user && row.user.id) {
      (this as any).getActiveUserById(row.user.id);
    }
  }

  private get stringFilter() {
    return (this as any).filterString;
  }

  private set stringFilter(value: string) {
    (this as any).setFilterString(value);
    (this as any)[types.FETCH_USERS_ACTION]({ offset: 0 });
  }

  private onScroll() {
    if (this.$el.scrollHeight - this.$el.clientHeight === this.$el.scrollTop) {
      (this as any)[types.FETCH_USERS_ACTION]();
    }
  }

  private onClickOutside() {
    if ((this as any).activeUser) {
      (this as any).setActiveUser(undefined);
    }
  }
}
