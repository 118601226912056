import Vue from 'vue';
import ProfileAccountInfo from '@/components/Profile/AccountInfo.vue';
import ProfileSection from '@/components/Profile/ProfileSection.vue';
import ProfileEmployeeInfo from '@/components/Profile/ProfileSection__employee-info.vue';

export default (vue: typeof Vue, options: {[key: string]: any}): void => {
        Vue.component('elib-profile-account-info', ProfileAccountInfo);
        Vue.component('elib-profile-section', ProfileSection);
        Vue.component('elib-profile-employee-info', ProfileEmployeeInfo);
};
