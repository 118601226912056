

































import { Component, Vue, Prop } from 'vue-property-decorator';

const baseClassName = 'elib-data-table';

@Component({
    name: baseClassName,
})
export default class AdminSideBar extends Vue {
    @Prop({default: () => []}) private incomingClasses!: string[];
    @Prop({default: () => []}) private columns!: Array<{key: string, title: string, width: number}>;
    @Prop({default: () => []}) private data!: Array<{key: string, value: any}>;
    @Prop({default: () => 0}) private onRowClick!: (...args: any) => any;
    private baseClassName = baseClassName;

    private get className() {
        return (this.incomingClasses || []).reduce(
            (acc: string, value: string) => `${acc} ${value}`, baseClassName);
    }

}
