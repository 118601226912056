var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{class:[_vm.className, _vm.inSidebar ? 'sidebar-wrapper' : '']},[_c('li',[_c('section',{class:[
        (_vm.baseClassName + "__content"),
        _vm.selectedBySearchQuery ? 'founded' : '',
        _vm.inSidebar ? 'sidebar' : '' ],on:{"mouseenter":function($event){_vm.showActions = true},"mouseleave":function($event){_vm.showActions = false},"click":function($event){return _vm.handleFilter(_vm.value)}}},[_c('section',{class:(_vm.baseClassName + "__title-section"),on:{"click":function($event){return _vm.foldCataloguesLevel(_vm.value)}}},[(!_vm.inSidebar)?_c('div',{class:[
            (_vm.baseClassName + "__arrow"),
            _vm.value.fold ? " is-rotated" : "" ]}):(_vm.inSidebar)?_c('elib-icon-arrow-twist'):_vm._e(),_c('span',{directives:[{name:"elib-tooltip",rawName:"v-elib-tooltip",value:(_vm.value.title),expression:"value.title"}],class:(_vm.baseClassName + "__title")},[_vm._v(_vm._s(_vm.value.title))]),(_vm.inSidebar && _vm.value.children.length)?_c('div',{class:[
            (_vm.baseClassName + "__arrow"),
            (_vm.baseClassName + "__arrow-sidebar"),
            _vm.value.fold ? " is-rotated" : "" ]}):_vm._e()],1),_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.showActions && !_vm.inSidebar),expression:"showActions && !inSidebar"}],class:(_vm.baseClassName + "__actions-section")},[_vm._l((_vm.catalogueActions),function(action){return _c('section',{key:action.id,class:(_vm.baseClassName + "__actions-row"),on:{"click":function($event){return action.handler()}}},[_c(action.icon,{tag:"component",class:(_vm.baseClassName + "__actions-icon")}),(action.title)?_c('span',{class:(_vm.baseClassName + "__actions-title")},[_vm._v(_vm._s(action.title))]):_vm._e()],1)}),(_vm.showModal)?_c('elib-modal-delete',{on:{"confirm":function($event){_vm.deleteCatalogueLevel(), (_vm.showModal = false)},"close":function($event){_vm.showModal = false}}},[_c('h3',{attrs:{"slot":"header"},slot:"header"},[_vm._v("Удалить категорию?")])]):_vm._e()],2)]),(_vm.activeCatalogueLevel)?_c('elib-catalogue-input',{attrs:{"incomingClasses":[(_vm.baseClassName + "__catalogues-input")],"value":_vm.activeCatalogueLevel},on:{"cancel":function($event){_vm.activeCatalogueLevel = null},"save":function($event){return _vm.saveChanges($event)}}}):_vm._e(),(_vm.childrenAreVsisble)?_vm._l((_vm.value.children),function(child){return _c('elib-catalogue-tree',{key:child.id,class:[
          (_vm.baseClassName + "__child"),
          _vm.childrenAreVsisble ? 'children-tree' : '' ],attrs:{"inSidebar":_vm.inSidebar,"value":child,"level":_vm.level + 1,"parent":_vm.value}})}):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }