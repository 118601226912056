




















import { Vue, Component, Prop } from 'vue-property-decorator';

const baseClassName = 'elib-icon-star';

@Component({
  name: baseClassName,
})
export default class IconStar extends Vue {
  @Prop({ default: () => 20 }) private width!: number;
  @Prop({ default: () => 19 }) private height!: number;
  @Prop({ default: () => false }) private isActive!: boolean;
  private baseClassName = baseClassName;
}
