









import { Vue, Component, Prop } from 'vue-property-decorator';

const baseClassName = 'elib-toggle-button';

@Component({
  name: baseClassName,
})
export default class ToggleButton extends Vue {
  @Prop({ default: () => true, type: Boolean, required: false })
  private value!: boolean;
  @Prop({default: () => '', type: String, required: false})
  private label!: string;
  private baseClassName = baseClassName;

  private onChange() {
    this.$emit('change', !this.value);
  }
}
