import Vue, { DirectiveOptions, VNode } from 'vue';
import TooltipTemplate from './Tooltip.vue';
import { CombinedVueInstance } from 'vue/types/vue';

type NotifierVueInstance = CombinedVueInstance<Record<never, any> &
    Vue, TooltipOptions, object, object, Record<never, any>> | null;

export interface TooltipOptions {
    id: string;
    message: string;
    parent: HTMLElement | Element;
}

let mountedTooltip: Vue | null = null;

function mountTooltip(el: HTMLElement, message: string, ev: MouseEvent) {
    if (el.offsetWidth >= el.scrollWidth) {
        return;
    }
    mountedTooltip = new TooltipTemplate({
        data: () => {
            return { left: ev.clientX, top: ev.clientY, message };
        },
    }) as Vue;
    if (!!mountedTooltip) {
        mountedTooltip.$mount();
        document.body.appendChild(mountedTooltip.$el as HTMLElement);
    }
}

function changeTooltipPosition(ev: MouseEvent) {
    if (mountedTooltip && !!mountedTooltip) {
        (mountedTooltip.$data as any).left = ev.clientX - 16;
        (mountedTooltip.$data as any).top = ev.clientY + 16;
    }
}

function destroyTooltip(el: HTMLElement | null) {
    if (mountedTooltip && !!mountedTooltip && !!mountedTooltip.$el) {
        mountedTooltip.$destroy();
        try {
            document.body.removeChild(mountedTooltip.$el as HTMLElement);
        // tslint:disable-next-line: no-empty
        } catch (error) {

        }
    }
}

const tooltipDirective: DirectiveOptions = {
    bind: (el: HTMLElement, binding: any, node: VNode, oldNode: VNode) => {
        el.addEventListener('mouseenter', (ev) => mountTooltip(el, binding.value, ev));
        el.addEventListener('mousemove', (ev) => changeTooltipPosition(ev));
        el.addEventListener('mouseleave', (ev) => destroyTooltip(ev.target as HTMLElement));
    },
    unbind: (el: HTMLElement, binding: any, node: VNode, oldNode: VNode) => {
        destroyTooltip(el as HTMLElement);
    },
};

export default tooltipDirective;
