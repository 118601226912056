





























import { Vue, Component, Prop } from 'vue-property-decorator';

const baseClassName = 'elib-input-classification';

@Component({
  name: baseClassName,
})
export default class InputClassification extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  @Prop({ default: () => '' }) private label!: string;
  @Prop({ default: () => '' }) private name!: string;
  @Prop({ default: () => '' }) private value!: string;
  @Prop({ default: () => false }) private required!: boolean;

  @Prop({ default: () => [] }) private items!: Array<{
    id: string;
    value: boolean;
    label: string;
    item: string;
  }>;
  @Prop({ default: () => false }) private disabled!: boolean;
  @Prop({ default: () => false }) private horizontal!: boolean;
  private baseClassName = baseClassName;

  private get className() {
    return (this.incomingClasses || []).reduce(
      (acc: string, value: string) => `${acc} ${value}`,
      baseClassName,
    );
  }
  private get isValid() {
    return !this.required || (this.required && !!this.value);
  }
}
