





























































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import { DocumentsStatus, Document, DocumentOwnershipType, DocumentKindType, Notifications } from '@/models/user-documents.model';
import { SelectOption } from '../../models/view.model';
import { User } from '../../models/users.model';
import { DocumentsAttribute, PredefinedAttributes } from '../../models/document-attributes.model';
import DocumentFormCustomAttributes from '@/components/UserDocuments/UserDocumentsСustomAttributes.vue';
import types from '@/store/types';
import moment from 'moment';
import DocumentAttribute from '@/components/UserDocuments/EditForm/FormAttribute.vue';

const baseClassName = 'elib-documents-form-attributes';

@Component({
  name: baseClassName,
  components: {
    'elib-documents-form-custom-attributes': DocumentFormCustomAttributes, DocumentAttribute,
  },
  computed: {
    ...mapState('documents', ['activeDocument']),
    ...mapState('users', ['usersList']),
    ...mapGetters('users', [types.FILTERED_USERS_LIST]),
  },
  methods: {
    ...mapMutations('documents', ['updateActiveDocumentProperty',
      'updateDocumentArrayProp', 'deleteActiveDocumentComments', 'deleteActiveDocumentNote']),
    ...mapActions('users', [types.FETCH_USERS_ACTION]),
  },
})
export default class DocumentFormAttributes extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  private baseClassName = baseClassName;
  private signatureQueryStrings: string[] = ['', ''];
  private currentSignaturiesQuery = '';
  private DocumentsQuarter = ['1', '2', '3', '4'];
  private self!: any;

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private get documentStatusRepresentation() {
    const activeDocument = (this as any).activeDocument as Document;
    return activeDocument.documentStatus && (DocumentsStatus as any)[activeDocument.documentStatus];
  }
  private get documentQuarterRepresentation() {
    const activeDocument = (this as any).activeDocument as Document;
    return activeDocument.quarter;
  }
  private formatDate(date: any) {
    if (!date) { return ''; }
    return `${moment(date).format('DD.MM.YYYY')}`;
  }

  private get documentStatuses(): SelectOption[] {
    return Object.keys(DocumentsStatus).map((item, index) => {
      return {
        id: index,
        name: (DocumentsStatus as any)[item],
        selected: false,
        item,
      };
    });
  }
  private get documentQuarter(): SelectOption[] {
    return this.DocumentsQuarter.map((item: any, index: any) => {
      return {
        id: index,
        name: (this.DocumentsQuarter as any)[index],
        selected: false,
        item,
      };
    });
  }

  private get documentOwnershipTypeItems() {
    const activeDocument = this.self.activeDocument as Document;
    const currentValue = activeDocument.ownershipType || 'BANK';
    return Object.keys(DocumentOwnershipType).
      map((item, index) => {
        return {
          id: index,
          value: item === currentValue,
          label: (DocumentOwnershipType as any)[item],
          item,
        };
      });
  }
  private get documentKindAttribute() {
    const activeDocument = this.self.activeDocument as Document;

    return activeDocument.attributes
      .find((attribute) => attribute.code === PredefinedAttributes.DOCUMENT_KIND);
  }
  private get notificationsAttribute() {

    const activeDocument = this.self.activeDocument as Document;

    const attr = {
      ...(activeDocument.attributes
        .find((attribute) => attribute.code === PredefinedAttributes.NOTIFICATIONS)), value: Notifications.NotActive,
    };
    return attr;
  }
  private get ownerAttribute() {
    const activeDocument = this.self.activeDocument as Document;
    return activeDocument.attributes
      .find((attribute) => attribute.code === PredefinedAttributes.DOCUMENT_OWNERSHIP);
  }
  private get yearAttribute() {
    const activeDocument = this.self.activeDocument as Document;
    return activeDocument.attributes
      .find((attribute) => attribute.code === PredefinedAttributes.DOCUMENT_PERIOD_YEAR);
  }
  private get periodAttribute() {
    const activeDocument = this.self.activeDocument as Document;
    return activeDocument.attributes
      .find((attribute) => attribute.code === PredefinedAttributes.DOCUMENT_PERIOD);
  }
  private get documentKindTypeItems() {
    const activeDocument = this.self.activeDocument as Document;
    const currentValue = activeDocument.documentKind || 'COMPANY_GROUP';
    return Object.keys(DocumentKindType).
      map((item, index) => {
        return {
          id: index,
          value: item === currentValue,
          label: (DocumentKindType as any)[item],
          item,
        };
      });
  }

  private get YesNoValues() {
    return [{ id: 0, value: true, label: 'Да' }, { id: 1, value: false, label: 'Нет' }];
  }

  private get TypeValues() {
    return [{ id: 0, value: true, label: 'Документ ГК' }, { id: 1, value: false, label: 'Документ ЮЛ' }];
  }

  private get OwnerValues() {
    return [{ id: 0, value: true, label: 'Документ Клиента' }, { id: 1, value: false, label: 'Документ Банка' }];
  }

  private onSignatoriesChange(value: string, index: number) {
    (this as any).updateDocumentArrayProp({ prop: 'signatories', index, value });
  }

  private beforeCreate() {
    this.self = this;
  }
}
