import { render, staticRenderFns } from "./DocumentAttributesList.vue?vue&type=template&id=6cdb8a15&scoped=true&"
import script from "./DocumentAttributesList.vue?vue&type=script&lang=ts&"
export * from "./DocumentAttributesList.vue?vue&type=script&lang=ts&"
import style0 from "./DocumentAttributesList.vue?vue&type=style&index=0&id=6cdb8a15&lang=scss&scoped=true&"
import style1 from "./DocumentAttributesList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cdb8a15",
  null
  
)

export default component.exports