






import { Component, Vue, Watch } from 'vue-property-decorator';
import SideBar from '@/components/Administration/SideBar.vue';
import UsersList from '@/components/Administration/UsersList.vue';
import RolesList from '@/components/Administration/Roles/RolesList.vue';
import SystemsList from '@/components/Administration/Administration__external-systems-list.vue';
import DocumentAttributesList from '@/components/Administration/DocumentAttributesList.vue';
import CatalogueList from '@/components/Administration/CataloguesList.vue';
import { Route } from 'vue-router';
import { mapState } from 'vuex';
import { User } from '../models/users.model';

const baseClassName = 'elib-administration-page';

@Component({
  name: baseClassName,
  components: {
      'elib-side-bar': SideBar,
      'elib-users-list': UsersList,
      'elib-roles-list': RolesList,
      'elib-external-systems-list': SystemsList,
      'elib-document-attributes-list': DocumentAttributesList,
      'elib-catalogues-list': CatalogueList,
  },
  computed: {
      ...mapState('session', ['user', 'isAdmin']),
  },
})
export default class AdministrationPage  extends Vue {
    private baseClassName = baseClassName;

    private get contentId() {
        return this.$route.params.contentId || 'elib-users-list';
    }

    private created() {
     const user = (this as any).user as User;
     const rolesString = user && user.roles ? JSON.stringify(user.roles.map((v) => v.title)) : '';
     if (!(this as any).isAdmin) {
         this.$router.push({ name: 'documents'});
     }
    }
}
