import { $app } from '@/main';
import store, { ModuleNames } from '@/store/store';
import * as types from '@/store/types';

const notifiersData: { [key: number]: any } = {
    19: {
        title: 'Недостаточно прав!', message: 'У вас нет прав для скачивание документа',
        type: 'error',
    },
    20: {
        title: 'Недостаточно прав!', message: 'У вас нет прав для удаления документа',
        type: 'error',
    },
    23: {
        title: 'Недостаточно прав!', message: 'У вас нет прав для создания документа на основании',
        type: 'error',
    },
    27: {
        title: 'Недостаточно прав!', message: 'У вас нет прав для загрузки файлов',
        type: 'error',
    },
    33: {
        title: 'Недостаточно прав!', message: 'У вас нет прав для скачивание отчета',
        type: 'error',
    },
};

export function hasAuthoritiesByCode(code: number) {
    const hasAuthority = store.getters[`${ModuleNames.Session}/${types.HAS_AUTHORITY_BY_CODE}`](code);
    if (hasAuthority) {
        return true;
    }
    $app.$notifier(notifiersData[code]);
    return false;
}
