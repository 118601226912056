

















import { Vue, Component, Prop } from 'vue-property-decorator';

const baseClassName = 'elib-input-colors';

type AssumptionsFunction = (params: { query: string, offset: number }) => Array<{ id: string | number, value: any }>;

@Component({
  name: baseClassName,
})
export default class InputColors extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  @Prop({ default: () => '' }) private label!: string;
  @Prop({ default: () => [] }) private selectedValues!: Array<{ id: number, value: string }>;
  @Prop({ default: () => false }) private disabled!: boolean;
  @Prop({ default: () => false }) private readonly!: boolean;
  @Prop({ default: () => false }) private required!: boolean;
  @Prop({ default: () => false }) private horizontal!: boolean;
  @Prop({ default: () => () => null }) private assumptionsFunction!: AssumptionsFunction;
  @Prop() private formatFunction!: (...args: any) => any;
  @Prop({ default: () => 'id' }) private idKey!: string;
  @Prop() private valueKey!: string;
  private value: string = '';
  private assumptions: Array<{ id: string | number, value: any }> = [];
  private baseClassName = baseClassName;
  private isFocused = false;

  private get isValid() {
    return (this.required && this.value !== '') || !this.required;
  }

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private onOptionSelect(mark: boolean, item: any) {
    item.selected = mark;
    const values = this.assumptions.filter((v: any) => v.selected);
    this.$emit('select', values);
  }

  private async onInput($event: string) {
    const queryParams = { query: this.value, offset: this.assumptions.length };
    const rawAssumptions = await this.assumptionsFunction(queryParams) as Array<{ [key: string]: any }>;
    this.assumptions = rawAssumptions.map((rawValue) => {
      const valueKey = this.valueKey;
      const id = rawValue[this.idKey];
      const value = this.formatFunction ? this.formatFunction(rawValue) : rawValue[this.valueKey];
      return { id, value, item: rawValue, selected: false };
    }) as Array<{ id: string | number, value: any }>;
    this.selectedValues.forEach((selected) => {
      if (!this.assumptions.find((assumption) => selected.id === assumption.id)) {
        this.assumptions.unshift({ ...selected, ...{ selected: true } });
      }
    });
    this.$emit('input', $event);
  }

  private created() {
    this.onInput('');
  }
}
