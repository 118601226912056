






















import { Vue, Component, Prop } from 'vue-property-decorator';
import { DropDownActions } from '../../models/view.model';

const baseClassName = 'elib-dropdown-dotted';

@Component({
  name: baseClassName,
})
export default class DropdownDotted extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  @Prop({ default: () => [] }) private actions!: DropDownActions[];
  private baseClassName = baseClassName;
  private focused = false;

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private onFocus() {
    this.focused = true;
  }

  private onBlur() {
    this.focused = false;
  }

  private onActionClick(action: DropDownActions) {
    if (!this.$refs.section) {
      return;
    }
    action.action();
    this.onBlur();
    (this.$refs.section as HTMLElement).blur();
  }
}
