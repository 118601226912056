



























import { Vue, Component, Prop } from 'vue-property-decorator';
import { RadioType } from '@/models/view.model';

const baseClassName = 'elib-input-radio';

@Component({
  name: baseClassName,
})
export default class InputRadio extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  @Prop({ default: () => '' }) private label!: string;
  @Prop({ default: () => '' }) private name!: string;
  @Prop({ default: () => false }) private value!: string | boolean;
  @Prop({ default: () => '' }) private radiotype!: RadioType;
  @Prop({ default: () => [] }) private items!: Array<{
    id: string;
    value: boolean;
    label: string;
  }>;
  @Prop({ default: () => false }) private disabled!: boolean;
  @Prop({ default: () => false }) private horizontal!: boolean;
  private baseClassName = baseClassName;

  private get className() {
    return (this.incomingClasses || []).reduce(
      (acc: string, value: string) => `${acc} ${value}`,
      baseClassName,
    );
  }
}
