var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{ref:"mainContainer",class:[
    _vm.className,
    _vm.isFocused ? 'is-focused' : '',
    _vm.horizontal ? 'horizontal' : '' ]},[_c('label',{class:(_vm.baseClassName + "__label")},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"required-star"},[_vm._v("*")]):_vm._e()]),_c('section',{ref:"input",class:[
      _vm.disabled ? (" " + _vm.baseClassName + "__disabled") : '',
      (_vm.baseClassName + "__group"),
      _vm.isValid ? 'valid' : 'not-valid' ]},[_c('input',{class:(_vm.baseClassName + "__input"),attrs:{"type":_vm.type,"label":_vm.label,"readonly":_vm.readonly,"disabled":_vm.disabled,"required":_vm.required},domProps:{"value":_vm.query},on:{"input":_vm.onInput,"focus":function($event){_vm.isFocused = !_vm.readonly && true, _vm.$emit('focus')}}}),(!_vm.disabled)?_c('img',{class:[
        (_vm.baseClassName + "__arrow-down"),
        _vm.isFocused ? 'arrow-rotated' : '' ],attrs:{"src":require("@/assets/icons/arrow-down.svg")},on:{"click":function($event){_vm.isFocused = !_vm.isFocused}}}):_vm._e(),(_vm.isFocused && !_vm.disabled)?_c('ul',{class:[(_vm.baseClassName + "__options"), _vm.isFocused ? "is-visible" : ""]},_vm._l((_vm.options),function(option){return _c('li',{key:option.id,class:(_vm.baseClassName + "__option"),on:{"click":function($event){return _vm.onSelect(option)}}},[_vm._v(" "+_vm._s(option.name || option.title || option.value)+" ")])}),0):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }