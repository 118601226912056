import { DictionaryDTO, DictionaryValueDTO, QueryDictionaryParams, PREDEFINED_DICTIONARY_NAMES } from '@/models/dictionaries.model';
import { FieldsDictionary as fields } from '@/dictionaries/fields';
import { Module } from 'vuex';
import * as types from '@/store/types';
import { fetchDictionaries, fetchDictionaryValuesById, fetchRelatedDictionaryValuesById } from '@/controllers/dictionaries.controller';

interface ModuleState {
    [types.DICTIONARIES]: DictionaryDTO[];
    [types.LOADING]: boolean;
    [types.ERROR]: any;
}

export const module: Module<ModuleState, any> = {
    namespaced: true,
    state: {
        [types.DICTIONARIES]: [],
        [types.LOADING]: false,
        [types.ERROR]: null,
    },
    mutations: {
        [types.SET_DICTIONARIES](state: ModuleState, payload: DictionaryDTO[]) {
            state[types.DICTIONARIES] = payload;
        },
        // tslint:disable-next-line
        [types.SET_DICTIONARY_VALUES](state: ModuleState, payload: { id: number, value: DictionaryValueDTO[] }) {
            const dictionary = state[types.DICTIONARIES].find((dic) => dic.id === payload.id);
            if (dictionary) {
                dictionary.dictionaryValues = payload.value;
            }
            state[types.DICTIONARIES] = [...state[types.DICTIONARIES]];
        },
        // tslint:disable-next-line
        [types.SET_RELATED_DICTIONARY_VALUES](state: ModuleState, payload: { dictionaryId: number, valueId: number, related: DictionaryValueDTO[] }) {
            const dictionaryValue = state[types.DICTIONARIES].find((dic) => dic.id === payload.dictionaryId)
                ?.dictionaryValues.find((value) => value.id === payload.valueId);
            if (dictionaryValue) {
                dictionaryValue.related = payload.related;
            }
            state[types.DICTIONARIES] = [...state[types.DICTIONARIES]];
        },
        [types.LOADING](state: ModuleState, payload: boolean) {
            state.loading = payload;
        },
        [types.ERROR](state: ModuleState, payload: any) {
            state.error = payload;
        },
    },
    getters: {
        [types.DOCUMENT_FORM_DICTIONARIES]({ dictionaries }): {} {
            return [...Object.keys(fields)]
                .reduce((acc, key) => {
                    const dictionary = dictionaries.find((dic) => dic.code === key);
                    if (dictionary && fields[key].alias) {
                        const alias = fields[key].alias as PREDEFINED_DICTIONARY_NAMES;
                        acc[alias] = dictionary.dictionaryValues;
                    }
                    return acc;
                }, {} as { [key in PREDEFINED_DICTIONARY_NAMES]: DictionaryValueDTO[] });
        },
    },
    actions: {
        [types.FETCH_DICTIONARIES_INFO]: async ({ commit, dispatch }) => {
            commit(types.LOADING, true);
            const data = await fetchDictionaries() || [];
            commit(types.SET_DICTIONARIES, data);
            dispatch(types.FETCH_DICTIONARY_VALUES, data);
            commit(types.LOADING, false);
            return data;
        },
        [types.FETCH_DICTIONARY_VALUES]: async ({ commit }, dictionaries: DictionaryDTO[]) => {
            const codes = Object.keys(fields);
            const ids = dictionaries.filter((dic) => codes.includes(dic.code)).map((v) => v.id);
            await Promise.all(ids.map(async (id) => {
                const value = await fetchDictionaryValuesById(id) || [];
                commit(types.SET_DICTIONARY_VALUES, { id, value });
            }));
        },
        [types.QUERY_DICTIONARY_VALUES]: async ({ commit, state }, payload: QueryDictionaryParams) => {
            if (!payload.code && !payload.id) {
                throw new Error('Bad request params');
            }
            const idByCode = state[types.DICTIONARIES].find((v) => v.code === payload.code)?.id;
            const id = payload.id ? payload.id : idByCode ? idByCode : 0;
            const value = await fetchDictionaryValuesById(id, { query: payload.query });
            commit(types.SET_DICTIONARY_VALUES, { id, value });
            return value;
        },
        // tslint:disable-next-line
        [types.FETCH_RELATED_DICTIONARY_VALUES]: async ({ commit, state }, param: { code: string, value: number }) => {
            const dictionaryId = state[types.DICTIONARIES].find((dic) => dic.code === param.code)?.id;
            return dictionaryId ? await fetchRelatedDictionaryValuesById(dictionaryId, param.value) || [] : [];
        },
    },
};
