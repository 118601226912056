import { $HTTPServicesClient as axiosClient, QueryParams } from '@/commons/axios.config';
import { Dictionary, DictionaryDTO, DictionaryValueDTO, AdditionalDictionaryAttributeValueDTO } from '@/models/dictionaries.model';
import { AUTH_TOKEN_NAME, AUTH_TOKEN_HEADER } from '@/commons/constants';

export async function fetchDictionaries() {
    let dictionaries;
    try {
        const request = await axiosClient.get('/dictionaries', {
            headers: {[AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME)},
        });
        dictionaries = request.data.data as DictionaryDTO[];
    } catch (error) {
        // tslint:disable-next-line: no-console
        console.error(error);
    }
    return dictionaries || [];
}

export async function fetchDictionaryValuesById(id: number, params: QueryParams = {}) {
    let dictionaries;
    try {
        const request = await axiosClient.get(`/dictionaries/${id}/dictionary-values`, {
            params,
            headers: {[AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME)},
        });
        dictionaries = request.data.data as DictionaryValueDTO[];
    } catch (error) {
        // tslint:disable-next-line: no-console
        console.error(error);
    }
    return dictionaries || [];
}

export async function fetchAdditionalDictionaryAttributeValuesById(id: number, name: string, params: QueryParams = {}) {
    let dictionaries;
    try {
        const request = await axiosClient.get(`/dictionaries/${id}/dictionary-values/${name}`, {
            params,
            headers: {[AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME)},
        });
        dictionaries = request.data.data as AdditionalDictionaryAttributeValueDTO[];
    } catch (error) {
        // tslint:disable-next-line: no-console
        console.error(error);
    }
    return dictionaries || [];
}

export async function fetchRelatedDictionaryValuesById(id: number, valueId: number, params: QueryParams = {}) {
    let dictionaries;
    try {
        const request = await axiosClient.get(`/dictionaries/${id}/dictionary-related-values/${valueId}`, {
            params,
            headers: {[AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME)},
        });
        dictionaries = request.data.data as DictionaryValueDTO[];
    } catch (error) {
        // tslint:disable-next-line: no-console
        console.error(error);
    }
    return dictionaries || [];
}

export async function fetchDictionaryValueById(valueId?: number | string) {
    try {
        const request = await axiosClient.get(`/dictionaries/dictionary-values/${valueId}`, {
            headers: {[AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME)},
        });
        return request.data.data as DictionaryValueDTO;
    } catch (error) {
        // tslint:disable-next-line: no-console
        console.error(error);
    }
}

export async function fetchDictionaryById(id: number) {
    let dictionary;
    try {
        const request = await axiosClient.get(`/dictionaries/${id}`, {
            headers: { [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME) },
        });
        dictionary = (request.data as { data: Dictionary }).data;
    } catch (error) {
        // tslint:disable-next-line: no-console
        console.error(error);
    }
    return dictionary;
}
export async function fetchDictionaryCompanyGroup(token: string) {
    let dictionary;
    try {
        const request = await axiosClient.get(`/dictionaries/companies/company-groups`, {
            headers: {[AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME)},
        });
        dictionary = (request.data as { data: Dictionary }).data;
    } catch (error) {
        // tslint:disable-next-line: no-console
        console.error(error);
    }
    return dictionary;
}
export async function fetchDictionaryLegalEntity(token: string) {
    let dictionary;
    try {
        const request = await axiosClient.get(`/dictionaries/companies/legal-entities`, {
            headers: {[AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME)},
        });
        dictionary = (request.data as { data: Dictionary }).data;
    } catch (error) {
        // tslint:disable-next-line: no-console
        console.error(error);
    }
    return dictionary;
}
export async function fetchDictionaryDocumentFullName(token: string) {
    let dictionary;
    try {
        const request = await axiosClient.get(`/dictionaries/document-names/full-names`, {
            headers: {
                'x-auth-token': token,
            },
        });
        dictionary = (request.data as { data: Dictionary }).data;
    } catch (error) {
        // tslint:disable-next-line: no-console
        console.error(error);
    }
    return dictionary;
}
export async function fetchDictionaryDocumentShortName(token: string) {
    let dictionary;
    try {
        const request = await axiosClient.get(`/dictionaries/document-names/short-names`, {
            headers: {
                'x-auth-token': token,
            },
        });
        dictionary = (request.data as { data: Dictionary }).data;
    } catch (error) {
        // tslint:disable-next-line: no-console
        console.error(error);
    }
    return dictionary;
}
export async function fetchDictionaryDossier(token: string) {
    let dictionary;
    try {
        const request = await axiosClient.get(`/dictionaries/dossiers`, {
            headers: {
                'x-auth-token': token,
            },
        });
        dictionary = (request.data as { data: Dictionary }).data;
    } catch (error) {
        // tslint:disable-next-line: no-console
        console.error(error);
    }
    return dictionary;
}
