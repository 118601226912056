








import { Vue, Component, Prop } from 'vue-property-decorator';
import { User } from '@/models/users.model';

export const baseClassName = 'elib-profile-section';

@Component({
  name: baseClassName,
})
export default class ProfileSection extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  @Prop() private user!: User;
  @Prop({ default: () => false }) private isActive!: boolean;
  private baseClassName = baseClassName;

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private get rolesString() {
    return this.user.roles.map((v) => v.title).join(', ');
  }
}
