





































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import inputmask from 'inputmask';
import moment from 'moment';
const baseClassName = 'elib-input-datepicker-range';

interface DateItem {
  value: number;
  inMonth: boolean;
  date: Date;
  selected?: boolean;
}

@Component({
  name: baseClassName,
})
export default class InputDatepickerRange extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  @Prop({ default: () => '' }) private label!: string;
  @Prop({ default: () => 0 }) private value!: number;
  @Prop({ default: () => false }) private disabled!: boolean;
  @Prop({ default: () => false }) private disabledPast!: boolean;
  @Prop({ default: () => false }) private required!: boolean;
  @Prop({ default: () => '' }) private placeholder!: string;
  @Prop({ default: () => false }) private horizontal!: boolean;
  private baseClassName = baseClassName;
  private isFocused = false;
  private daysOfTheWeek = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];
  private months = ['Январь',
    'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
    'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
  private currentDate = new Date();
  private dateRange = `${this.formatDate(new Date())} - ${this.formatDate(new Date())}`;
  private currentDateFrom = new Date();
  private currentDateTo = new Date();

  private isDateInPast(date: Date) {
    if (this.disabledPast) {
      const today = new Date();
      today.setDate(today.getDate() - 1);
      return today > date;
    }
  }
  private setToday() {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    this.currentDateFrom = today;
    this.currentDateTo = today;
    this.onDateSelect();
  }
  private setWeek() {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    const first = today.getDate() - today.getDay(); // First day is the day of the month - the day of the week
    const last = first + 6; // last day is the first day + 6

    const firstday = new Date(today.setDate(first));
    const lastday = new Date(today.setDate(last));
    this.currentDateFrom = firstday;
    this.currentDateTo = lastday;
    this.onDateSelect();
  }
  private setMonth() {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    this.currentDateFrom = firstDay;
    this.currentDateTo = lastDay;
    this.onDateSelect();
  }
  private setPeriod() {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    const quarter = Math.floor((today.getMonth() / 3));
    const firstDate = new Date(today.getFullYear(), quarter * 3, 1);
    const endDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0);
    this.currentDateFrom = firstDate;
    this.currentDateTo = endDate;
    this.onDateSelect();
  }
  private setYear() {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    const firstDay = new Date(new Date().getFullYear(), 0, 1);
    const lastDay = new Date(new Date().getFullYear(), 11, 31);
    this.currentDateTo = lastDay;
    this.currentDateFrom = firstDay;
    this.onDateSelect();
  }


  private formatDate(date: Date) {
    return `${moment(date).format('DD.MM.YYYY')}`;
  }
  private get dateRepresentation() {
    // if (!this.value || this.value === null) {
    //   return '';
    // }
    // let dateValue = new Date(this.value);

    // if (isNaN(dateValue.valueOf())) {
    //   return '';
    // }
    // const dateString = dateValue.getDate() < 10 ? `0${dateValue.getDate()}` : dateValue.getDate();
    // const monthString = dateValue.getMonth() < 9 ? `0${dateValue.getMonth() + 1}` : dateValue.getMonth() + 1;
    // const yearString = dateValue.getFullYear().toString();
    return this.dateRange;
  }

  private get isValid() {
    return (this.required && this.value !== 0) || !this.required;
  }

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private get currentMonth() {
    return this.months[this.currentDate.getMonth()];
  }

  private get currentYear() {
    return this.currentDate.getFullYear().toString();
  }
  private get currentMonthFrom() {
    return this.months[this.currentDateFrom.getMonth()];
  }

  private get currentYearFrom() {
    return this.currentDateFrom.getFullYear().toString();
  }
  private get currentMonthTo() {
    return this.months[this.currentDateTo.getMonth()];
  }

  private get currentYearTo() {
    return this.currentDateTo.getFullYear().toString();
  }

  private get dateRows() {
    this.currentDate.setHours(0, 0, 0, 0);
    const monthEnd = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
    const dates = new Array(6).fill(0).map(() => new Array(7).fill({ value: 0 }));
    new Array(monthEnd.getDate()).fill(0).reduce((acc: Date, value: number) => {
      const week = this.getWeekOfMonth(acc);
      const currentDay = this.getDaySratingFromMonday(acc);
      const currentDate = new Date(acc).getDate();
      const selected = this.currentDate.valueOf() === acc.valueOf();
      const date = new Date(acc);
      dates[week][currentDay] = { value: currentDate, date, inMonth: true, selected };
      acc.setDate(acc.getDate() + 1);
      return acc;
    }, new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1));
    return this.fillBorderMonths(dates);
  }
  private get dateRowsFrom() {
    this.currentDateFrom.setHours(0, 0, 0, 0);
    const monthEnd = new Date(this.currentDateFrom.getFullYear(), this.currentDateFrom.getMonth() + 1, 0);
    const dates = new Array(6).fill(0).map(() => new Array(7).fill({ value: 0 }));
    new Array(monthEnd.getDate()).fill(0).reduce((acc: Date, value: number) => {
      const week = this.getWeekOfMonth(acc);
      const currentDay = this.getDaySratingFromMonday(acc);
      const currentDateFrom = new Date(acc).getDate();
      const selected = this.currentDateFrom.valueOf() === acc.valueOf();
      const date = new Date(acc);
      dates[week][currentDay] = { value: currentDateFrom, date, inMonth: true, selected };
      acc.setDate(acc.getDate() + 1);
      return acc;
    }, new Date(this.currentDateFrom.getFullYear(), this.currentDateFrom.getMonth(), 1));
    return this.fillBorderMonths(dates);
  }
  private get dateRowsTo() {
    this.currentDateTo.setHours(0, 0, 0, 0);
    const monthEnd = new Date(this.currentDateTo.getFullYear(), this.currentDateTo.getMonth() + 1, 0);
    const dates = new Array(6).fill(0).map(() => new Array(7).fill({ value: 0 }));
    new Array(monthEnd.getDate()).fill(0).reduce((acc: Date, value: number) => {
      const week = this.getWeekOfMonth(acc);
      const currentDay = this.getDaySratingFromMonday(acc);
      const currentDateTo = new Date(acc).getDate();
      const selected = this.currentDateTo.valueOf() === acc.valueOf();
      const date = new Date(acc);
      dates[week][currentDay] = { value: currentDateTo, date, inMonth: true, selected };
      acc.setDate(acc.getDate() + 1);
      return acc;
    }, new Date(this.currentDateTo.getFullYear(), this.currentDateTo.getMonth(), 1));
    return this.fillBorderMonths(dates);
  }
  private getDaySratingFromMonday(date: Date) {
    return date.getDay() === 0 ? 6 : date.getDay() - 1;
  }

  private getWeekOfMonth(date: Date) {
    const firstWeekday = new Date(date.getFullYear(), date.getMonth(), 0).getDay();
    const offsetDate = date.getDate() + firstWeekday - 1;
    return Math.floor(offsetDate / 7);
  }

  private fillBorderMonths(dates: DateItem[][]) {
    const shiftFirstIndex = dates[0][0].value === 0 && dates[0][6].value === 0;
    const popLastIndex = dates[5][0].value === 0 && dates[5][6].value === 0;
    const hasZeroOnLastRow = dates[4].find((v) => v.value === 0);
    if (!shiftFirstIndex) {
      this.fillPreviousMonthDates(dates);
    }
    if (!popLastIndex || hasZeroOnLastRow) {
      this.fillNextMonthDates(dates, hasZeroOnLastRow ? 4 : 5);
    }
    return dates;
  }

  private fillPreviousMonthDates(dates: DateItem[][]) {
    const date = this.currentDate;
    const endDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastZeroIndex = dates[0].map((v) => v.value).lastIndexOf(0);
    new Array(lastZeroIndex + 1).fill(0).reduce((acc: Date, value, index) => {
      acc.setDate(acc.getDate() - 1);
      dates[0][lastZeroIndex - index] = { value: acc.getDate(), inMonth: false, date: acc };
      return acc;
    }, endDate);
  }

  private fillNextMonthDates(dates: DateItem[][], rowIndex = 5) {
    const date = this.currentDate;
    const startDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const firstZeroIndex = dates[rowIndex].findIndex((v) => v.value === 0);
    new Array(7 - firstZeroIndex).fill(0).reduce((acc: Date, value, index) => {
      acc.setDate(acc.getDate() + 1);
      dates[rowIndex][firstZeroIndex + index] = { value: acc.getDate(), inMonth: false, date: acc };
      return acc;
    }, startDate);
  }

  private get isValidDateString() {
    return /^([0-2][0-8]|[0-9]|30|31).([0-1][0-2]|[0][1-9]).([0-9]\d{0,3})$/.test(this.dateRepresentation);
  }

  private moveMonth(inPlus: boolean) {
    this.currentDate = new Date(this.currentDate.setMonth(this.currentDate.getMonth() + (inPlus ? 1 : -1)));
    if (this.isDateInPast(this.currentDate)) {
      this.currentDate = new Date();
    }
  }

  private onSelect(item: any) {
    this.isFocused = false;
    this.$emit('select', item);
  }

  private onDateSelect() {
    this.dateRange = this.formatDate(this.currentDateFrom) + '-' + this.formatDate(this.currentDateTo);
    this.$emit('select', this.dateRange);

  }
  private onDateFromSelect(date: Date) {
    this.currentDateFrom = date;
    this.onDateSelect();

  }
  private onDateToSelect(date: Date) {
    this.currentDateTo = date;
    this.onDateSelect();
    this.isFocused = false;

  }
  private mounted() {
    const self = this;
    this.onComponentEnter();
    // inputmask({
    //   alias: 'datetime',
    //   inputFormat: 'dd.mm.yyyy',
    //   placeholder: '__.__.____',
    //   showMaskOnHover: false,
    //   oncomplete() {
    //     self.$emit('change', self.dateRepresentation);
    //   },
    // }).mask(this.$refs.input_elm as HTMLInputElement);
  }

  private beforeDestroy() {
    this.onComponentLeave();
  }

  private onComponentEnter() {
    window.addEventListener('click', this.onClickOutised);
  }

  private onComponentLeave() {
    window.removeEventListener('click', this.onClickOutised);
  }


  private onClickOutised(event: Event) {
    const element = event.target as HTMLElement;
    this.isFocused = (this.$refs.section as HTMLElement).contains(element) || element === this.$refs.section;
  }
}
