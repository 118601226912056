































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { queryDocumentAttributesByName } from '@/controllers/document-attributes.controllers';
import {
  DocumentsAttribute, AttributesTypeEnum,
  staticAttributesDictionary as attributesDictionary, PredefinedAttributes,
} from '@/models/document-attributes.model';
import { DictionariesValue, DictionaryValueDTO } from '@/models/dictionaries.model';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import * as types from '@/store/types';
import { RolesDocumentAttribute } from '@/models/roles.model';
import { Store } from '@/models/commons';
import { fetchDictionaryValueById, fetchDictionaryValuesById } from '@/controllers/dictionaries.controller';
import { filters } from '@/dictionaries/filters';
import { getAttributesDefinition, getAttributesDefinitionForRoles, mapDefinitionToValueItem } from '@/components/UserDocuments/Filters/filters.module';
import { DocumentsFilterItem, DocumentsFilterItemDto, FilterViewType } from '@/models/filters.model';
import ExternalSystemsList from '../Administration/Administration__external-systems-list.vue';
import { DocumentsCategory } from '@/models/user-documents.model';
import FilterItem from '@/components/UserDocuments/Filters/FiltersItem.vue';
import DocumentAttribute from '@/components/UserDocuments/EditForm/FormAttribute.vue';
import { fetchFilterAttributes } from '@/controllers/filters.controller';

const baseClassName = 'roles-edit-document-attributes-edit';

@Component({
  name: baseClassName,
  components: { FilterItem },
  computed: {
    ...mapGetters('roles', [types.ROLE_DOCUMENT_ATTRIBUTES]),
  },
  methods: {
    ...mapMutations('roles', ['updateActiveRoleProperty']),
  },
})
export default class RolesAttributesEdit extends Vue {
  @Prop({ required: true }) private value!: RolesDocumentAttribute;
  @Prop() private index!: number;
  private baseClassName = baseClassName;
  private self: any;
  private query = '';
  private definitionQuery = '';
  private definition: DocumentsAttribute | null = null;
  private attributesSuggestions: DocumentsAttribute[] = [];

  protected get typeCode() {
    return this.value.attributeDefinition.attributeType.code.toUpperCase();
  }

  protected get isDictionary() {
    return this.typeCode === 'DICTIONARY';
  }

  private get item() {
    return this.value && {
      attribute: this.value.attributeDefinition,
      code: this.value.attributeDefinition.code,
      value: this.isDictionary && this.value.value ? [parseInt(this.value.value as string, 10)] : this.value.value,
    };
  }

  private async queryAttributes(query: string) {
    this.definitionQuery = query;
    await this.fetchSuggestions(query);
  }

  private async fetchSuggestions(query: string) {
    this.attributesSuggestions = await getAttributesDefinitionForRoles({query});
  }

  private onAttributeSelect(definition: DocumentsAttribute) {
    this.definition = definition;
    this.definitionQuery = definition.title;
    this.$emit('definition-select', definition);
  }

  private onAttributeCancel() {
    this.definitionQuery = '';
  }

  private onValueChange(value: DocumentsFilterItem) {
    if (value) {
      const attributes = (this.self.roleDocumentAttributes || [])
        .filter((v: RolesDocumentAttribute) => v.code !== value.attribute.code) || [];
      const attribute = this.mapFilterItemToRoleAttribute(value);
      const values = [...attributes, attribute];
      this.self.updateActiveRoleProperty({ prop: 'attributes', value: values });
      this.$emit('change', attribute.value);
    }
  }

  private mapFilterItemToRoleAttribute(item: DocumentsFilterItem): RolesDocumentAttribute {
    const value = item.value instanceof Array ? item.value[0] : item.value as string;
    return {
      attributeDefinition: item.attribute as DocumentsAttribute,
      value,
      code: item.attribute.code,
    };
  }

  private created() {
    if (this.value) {
      this.queryAttributes(this.value.attributeDefinition.title);
    }
  }

  private beforeCreate() {
    this.self = this;
  }
}
