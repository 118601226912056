import Vue from 'vue';
import { Catalogue, CataloguesView } from '@/models/catalogues.model';
import { Module } from 'vuex';
import { fetchCatalogues, postNewCatalogue, putCatalogue, deleteCatalogue, findInDocumentTypeHierarchyByQuery } from '@/controllers/catalogues.controllers';
import { QueryParams } from '@/commons/axios.config';
import { TreeViewData } from '@/models/view.model';
import Treeview from '@/components/Treeview';

type StringFilter = string | undefined;

interface ModuleState {
    cataloguesList: Catalogue[];
    filterString: StringFilter;
}

export const module: Module<ModuleState, any> = {
    namespaced: true,
    state: {
        cataloguesList: [],
        filterString: '',
    },
    mutations: {
        setCataloguesList(state: ModuleState, payload: Catalogue[]) {
            findTreesBranch(payload as CataloguesView[], state.filterString);
            state.cataloguesList = payload;
        },
        addNewCatalogue(state: ModuleState, payload: { catalogue: Catalogue, parent: Catalogue }) {
            if (payload.parent && payload.parent !== null) {
                payload.parent.children.push(payload.catalogue);
                return;
            }
            state.cataloguesList.push(payload.catalogue);
        },
        resetState(state: ModuleState) {
            state.cataloguesList = [];
            state.filterString = '';
        },
        updateCatalogue(state: ModuleState, payload: { catalogue: Catalogue, parent: Catalogue }) {
            if (payload.parent && payload.parent !== null) {
                const childIndex = payload.parent.children.findIndex((v) => v.id === payload.catalogue.id);
                payload.parent.children[childIndex] = { ...payload.catalogue };
                payload.parent.children = [...payload.parent.children];
                return;
            }
            const index = state.cataloguesList.findIndex((v) => v.id === payload.catalogue.id);
            state.cataloguesList[index] = payload.catalogue;
            state.cataloguesList = [...state.cataloguesList];
        },
        updateCatalogueNodeProperty(state: ModuleState, payload: { prop: string; value: any; id: number }) {
            let searchableValue;
            searchableValue = findTreenode(state.cataloguesList, payload.id, searchableValue as any);
            if (searchableValue) {
                (searchableValue as { [key: string]: any })[payload.prop] = payload.value;
            }
        },
        foldCataloguesChildren(state: ModuleState, payload: CataloguesView) {
            foldTreeview(payload);
        },
        foldCataloguesChild(state: ModuleState, payload: CataloguesView) {
            if (payload.fold === undefined) {
                Vue.set(payload, 'fold', true);
                return;
            }
            (payload.fold as boolean) = !payload.fold;
        },
        deleteCatalogue(state: ModuleState, payload: { catalogue: Catalogue, parent: Catalogue }) {
            if (payload.parent && payload.parent.children) {
                payload.parent.children = payload.parent.children.filter((v) => v !== payload.catalogue);
                return;
            }
            state.cataloguesList = state.cataloguesList.filter((v) => v !== payload.catalogue);
        },
        filterString(state: ModuleState, stringFilter: StringFilter) {
            state.filterString = stringFilter;
        },
    },
    getters: {
        catalogueTreeViewData({ cataloguesList }): TreeViewData[] {
            const treeData = getTreeViewData(cataloguesList);
            return treeData;
        },
        cataloguesTreeViewNodeById({ }, { catalogueTreeViewData }): (id: number) => TreeViewData | undefined {
            const treeData = catalogueTreeViewData as TreeViewData[];
            return (id: number) => {
                let searchableValue;
                searchableValue = findTreenode(treeData, id, searchableValue as any);
                return searchableValue;
            };
        },
        catalogueByNodeId({ cataloguesList }) {
            return (id?: number) => {
                if (!id) {
                    return;
                }
                let searchableValue;
                searchableValue = findTreenode(cataloguesList, id, searchableValue as any);
                return searchableValue;
            };
        },
    },
    actions: {
        async getCatalogues({ commit, state }, params?: QueryParams) {
            const query = { ...{ offset: state.cataloguesList.length }, ...params };
            const catalogues = await fetchCatalogues(query);
            commit('setCataloguesList', catalogues);
            return catalogues;
        },
        async getCataloguesByQuery({commit, state}, params?: QueryParams) {
            const query = { ...{ offset: state.cataloguesList.length }, ...params };
            const catalogues = await findInDocumentTypeHierarchyByQuery(query);
            commit('setCataloguesList', catalogues);
            return catalogues;
        },
        async saveNewCatalogue({ commit, rootState }, payload: { catalogue: Catalogue, parent: Catalogue }) {
            const catalogue = await postNewCatalogue(rootState.token, modifyCatalogueForPost(payload));
            commit('addNewCatalogue', { catalogue, parent: payload.parent });
        },
        async updateCatalogue({ commit, rootState }, payload: { catalogue: Catalogue, parent: Catalogue }) {
            const catalogue = await putCatalogue(rootState.token, { ...payload.catalogue });
            commit('updateCatalogue', {
                catalogue: { ...catalogue, ...{ children: payload.catalogue.children } },
                parent: payload.parent,
            });
        },
        async deleteCatalogue({ commit, rootState }, payload: { catalogue: Catalogue, parent: Catalogue }) {
            await deleteCatalogue(rootState.token, payload.catalogue);
            commit('deleteCatalogue', payload);
        },
        async setFilterString({ commit }, stringFilter: string) {
            commit('filterString', stringFilter);
        },
    },
};


function foldTreeview(catalogue: CataloguesView, level = 0) {
    catalogue.fold === undefined ? Vue.set(catalogue, 'fold', true) :
        catalogue.fold = !catalogue.fold;
    if (!catalogue.children || catalogue.children.length === 0) {
        return;
    }
    for (const child of catalogue.children) {
        foldTreeview(child, ++level);
    }
}

function findTreenode(catalogues: Catalogue[] | TreeViewData[], id: number, value: Catalogue | TreeViewData): any {
    if (!catalogues) {
        return value;
    }
    for (const catalogue of catalogues) {
        if (catalogue.id === id) {
            value = catalogue;
        } else {
            value = findTreenode(catalogue.children, id, value);
        }
    }
    return value;
}

function modifyCatalogueForPost(payload: { catalogue: Catalogue, parent: Catalogue }) {
    const catalogue = { ...payload.catalogue };
    if (!payload.parent) {
        delete catalogue.parentId;
        delete catalogue.parentTitle;
    } else {
        catalogue.parentId = payload.parent.id;
        catalogue.parentTitle = payload.parent.title;
    }
    return catalogue;
}

function findTreesBranch(catalogues: CataloguesView[], name: string | undefined) {
    let stack = [...catalogues];
    const content = [];
    while (stack.length > 0) {
        const current = stack.shift() as Catalogue;
        RegExp(name || '', 'gi').test(current.title) || RegExp(name || '', 'gi').test(current.code) ?
            (Vue.set(current, 'founded', true), Vue.set(current, 'selected', true)) :
            Vue.set(current, 'founded', false);
        content.push(current);
        stack = [...stack, ...current.children as CataloguesView[]];
    }
    const foundedElements = (content as CataloguesView[]).filter((v) => v.founded);
    for (const element of foundedElements) {
        let parentId = element.parentId;
        while (parentId) {
            const parent = content.find((v) => v.id === parentId) as CataloguesView;
            Vue.set(parent, 'founded', true);
            parentId = parent.parentId;
        }
    }
}

function getTreeViewData(catalogues: Catalogue[]): TreeViewData[] {
    const treesData: TreeViewData[] = [];
    let stack = [...catalogues];
    let currentStackLength = 0;
    let currentLevel = 0;
    while (stack.length > 0) {
        currentLevel = !currentStackLength ? ++currentLevel : currentLevel;
        currentStackLength = !currentStackLength ? stack.length : currentStackLength;
        const currentNode = stack.shift() as Catalogue;
        treesData.push(createTreeViewNode(currentNode, currentLevel));
        stack = [...stack, ...currentNode.children];
        currentStackLength--;
    }
    assembleTreeView(treesData);
    const treeView: TreeViewData[] = treesData.filter((v) => v.level === 1);
    return treeView;
}

function createTreeViewNode(catalogue: Catalogue, level = 0): TreeViewData {
    return {
        id: catalogue.id,
        level,
        value: catalogue.title,
        opened: false,
        onClick: () => null,
        source: catalogue,
        children: [],
    };
}

function assembleTreeView(treesData: TreeViewData[]) {
    for (const node of treesData) {
        const catalogue = node.source as Catalogue;
        if (catalogue.parentId) {
            const parent = treesData.find((v) => v.id === catalogue.parentId) as TreeViewData;
            parent.children.push(node);
            parent.children.forEach((value) => {
                value.path = `${parent.path ? parent.path + '/' : ''}${value.value}`;
            });
        } else {
            node.path = node.value;
        }
    }
}
