





















































import { Component, Vue, Prop } from 'vue-property-decorator';
import { User } from '@/models/users.model';
import { Role } from '@/models/roles.model';
import { mapState, mapActions, mapMutations } from 'vuex';
import { MultiselectAssumption } from '@/models/view.model';

const baseClassName = 'elib-users-edit-form';

interface LocalAssumption extends MultiselectAssumption {
  role: Role;
}

@Component({
  name: baseClassName,
  computed: {
    ...mapState('users', ['activeUser']),
    ...mapState('roles', ['rolesList']),
  },
  methods: {
    ...mapActions('roles', ['getRoles']),
    ...mapMutations('users', ['updateActiveRoleProperty', 'setActiveUser']),
    ...mapActions('users', ['updateUser']),
  },
})
export default class UsersEditForm extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  private baseClassName = baseClassName;
  private selectIsFocused = false;
  private showModal = false;
  private setActiveUser!: (user?: User) => void;

  private get className() {
    return (this.incomingClasses || []).reduce(
      (acc: string, value: string) => `${acc} ${value}`,
      baseClassName,
    );
  }

  private get user() {
    return (this as any).activeUser as User;
  }

  private get rolesAssumptions(): LocalAssumption[] {
    return (((this as any).rolesList as Role[]) || []).map((role: Role) => {
      return this.createRolesListItem(role);
    });
  }

  /* tslint:disable:no-empty */
  private set rolesAssumptions(roles: LocalAssumption[]) {}

  private createRolesListItem(role: Role) {
    const activeUser = (this as any).activeUser as User;
    const selected = activeUser.roles.find((v) => v.id === role.id)
      ? true
      : false;
    return { id: role.id, value: role.title, selected, role };
  }

  private created() {
    (this as any).getRoles();
  }

  private onRolesSelect(values: LocalAssumption[]) {
    const value = [...new Set(values.map((v) => v.role))];
    (this as any).updateActiveRoleProperty({ prop: 'roles', value });
    this.rolesAssumptions = values;
  }

  private onRoleSelect(role: Role, checkmark: boolean) {
    const value = this.user.roles.filter((v) => v.id !== role.id);
    (this as any).updateActiveRoleProperty({ prop: 'roles', value });
  }

  private async saveUser() {
    if (this.user && this.validateUser()) {
      await (this as any).updateUser(this.user);
      this.setActiveUser();
      this.$emit('cancel');
    }
  }

  private validateUser() {
    if (!this.user.roles.length) {
      this.$notifier({
        title: 'Ошибка!',
        message: 'У пользователя должна быть как минимум 1 роль',
        type: 'error',
      });
      return false;
    }
    return true;
  }
}
