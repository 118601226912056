



























import { Vue, Component } from 'vue-property-decorator';
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex';
import { User } from '@/models/users.model';
import types from '@/store/types';
import { Document } from '@/models/user-documents.model';

const baseClassName = 'elib-documents-edit-form-watchers';

@Component({
  name: baseClassName,
  computed: {
    ...mapGetters('users', [types.FILTERED_USERS_LIST]),
    ...mapState('documents', [types.ACTIVE_DOCUMENT]),
  },
  methods: {
    ...mapActions('users', [types.FETCH_USERS_ACTION]),
    ...mapMutations('documents', [types.UPDATE_ACTIVE_DOCUMENTS_PROP]),
  },
})
export default class DocumentsEditFormWatchers extends Vue {
  private baseClassName = baseClassName;
  private self: any;

  private query = '';
  private editMode = false;

  private get watchers(): User[] {
    const activeDocument = this.self[types.ACTIVE_DOCUMENT] as Document;
    return activeDocument.watchers || [];
  }

  private get watcherAssumptions() {
    const usersList = this.self[types.FILTERED_USERS_LIST](this.query) as User[];
    return (usersList || []).filter((user) => !this.watchers.find((item) => item.id === user.id)).map((user) => {
      return {
        id: user.id,
        value: user.fullName,
        user,
      };
    });
  }

  private onWatchersSelect(item: { user: User }) {
    const watchers = [...this.watchers];
    watchers.push(item.user);
    this.self[types.UPDATE_ACTIVE_DOCUMENTS_PROP]({ prop: 'watchers', value: watchers });
    this.query = '';
    this.editMode = false;
  }

  private onWatcherRemove(user: User) {
    const watchers = [...this.watchers].filter((value) => value.id !== user.id);
    this.self[types.UPDATE_ACTIVE_DOCUMENTS_PROP]({ prop: 'watchers', value: watchers });
    if (watchers.length === 0) {
      this.$emit('close');
    }
  }

  private onWatchersNameChange(value: string) {
    const usersList = (this as any).usersList as User[];
    const foundedUser = usersList.find((user) => user.fullName === value);
    if (!foundedUser) {
      this.query = '';
    }
  }

  private beforeCreate() {
    this.self = this;
  }
}
