













































import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';
import Logo from '@/components/TheLogo.vue';
import ProfileSection from '@/components/Profile/ProfileSection.vue';
import { sessionLogout } from '@/controllers/sessions.controllers';

export const baseClassName = 'elib-navbar';

interface ViewRouteLink {
  title: string;
  route: string;
  active: boolean;
}

@Component({
  name: baseClassName,
  components: {
    'elib-logo': Logo,
    'user-profile': ProfileSection,
  },
  computed: {
    ...mapState(['user']),
    ...mapState('session', ['serverVersion', 'isAdmin']),
  },
})
export default class AuthForm extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  private baseClassName = baseClassName;
  private sessionLogout = sessionLogout;
  private submenuStyle = { display: 'none' };
  private submenuIsActive = false;

  private get className() {
    return (this.incomingClasses || []).reduce(
      (acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private get navLinks() {
    return [
      { title: 'Документы', route: 'documents', active: false, visible: true },
      { title: 'Администрирование', route: 'admin', active: false, visible: (this as any).isAdmin },
    ];
  }

  private mounted() {
    const routeLink = this.navLinks.find((v) => v.route === this.$route.name);
    if (routeLink) {
      routeLink.active = true;
    }
    this.onComponentEnter();
  }

  private beforeDestroy() {
    this.onComponentLeave();
  }

  private onComponentEnter() {
    this.submenuStyle = this.submenuPositionAndSize() || this.submenuStyle;
    window.addEventListener('resize', this.onResize);
  }

  private onComponentLeave() {
    window.removeEventListener('resize', this.onResize);
  }

  private onResize() {
    this.submenuStyle = this.submenuPositionAndSize() || this.submenuStyle;
  }

  private submenuPositionAndSize() {
    if (!this.$refs.profile) {
      return;
    }
    const rect = (this.$refs.profile as Vue).$el.getBoundingClientRect();
    return {
      display: 'grid',
      top: `${rect.bottom}px`,
      right: 0,
      width: `${rect.width}px`,
    };
  }

  private pushToRoterLink(link: ViewRouteLink) {
    this.navLinks.forEach((value) => {
      if (link.title === value.title) {
        value.active = true;
      } else {
        value.active = false;
      }
      return value;
    });
    this.$router.push({ name: link.route });
  }

  private onClickOutside() {
    this.submenuIsActive = false;
  }
}
