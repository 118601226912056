
























































































































import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { FormMixin } from './form.mixin';
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import { Document, DocumentsCategory } from '@/models/user-documents.model';
import { fetchDocumentsContent } from '@/controllers/user-documents.controllers';
import types from '@/store/types';
import DocumentFormAttributes from '@/components/UserDocuments/UserDocumentAttributes.vue';
import LinkedDocumetns from '@/components/UserDocuments/LinkedDocuments.vue';
import DocumentsEditFormAttachments from '@/components/UserDocuments/EditForm/FormAttachments.vue';
import DocumentsEditFormWatchers from '@/components/UserDocuments/EditForm/FormWatchers.vue';
import UserDocumentHeader from '@/components/UserDocuments/EditForm/FormHeader.vue';

const baseClassName = 'elib-documents-edit-form';

@Component({
  name: baseClassName,
  components: {
    UserDocumentHeader,
    'elib-documents-form-attributes': DocumentFormAttributes,
    'elib-documents-linked-documents': LinkedDocumetns,
    DocumentsEditFormAttachments,
    DocumentsEditFormWatchers,
  },
  computed: {
    ...mapState('attributes', ['documentAttributesList', 'dictionaryValues']),
    ...mapState('documents', ['activeDocument', 'hasVersionsChanges']),
    ...mapState('catalogues', ['cataloguesList']),
    ...mapGetters('catalogues', ['catalogueTreeViewData', 'catalogueByNodeId']),
    ...mapState('users', ['usersList']),
    ...mapState('systems', ['externalSystemList']),
    ...mapGetters('users', [types.FILTERED_USERS_LIST]),
    ...mapGetters('session', ['hasAuthorityByCode']),
  },
  methods: {
    ...mapMutations('documents', ['setActiveDocument', 'updateActiveDocumentProperty',
      'copyActiveDocumentToNew', 'setHasVersionsChanges', 'updateDocumentArrayProp',
      'deleteActiveDocumentComments']),
    ...mapActions('catalogues', ['getCatalogues']),
    ...mapActions('users', [types.FETCH_USERS_ACTION]),
    ...mapActions('systems', ['getExternalSystems']),
    ...mapActions('documents', ['saveNewDocument', types.FETCH_DOCUMENT_BY_ID,
      'updateNonVersionedProps', 'updateDocument', 'deleteDocument']),
    ...mapActions('attributes', ['getDictionaries', 'setActiveDictionary']),
    ...mapActions('dictionaries', [types.QUERY_DICTIONARY_VALUES]),
  },
})
export default class DocumentsEditForm extends mixins(FormMixin) {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  private self: any;
  private baseClassName = baseClassName;
  private showCategoiresSelect = false;
  private showWatchersSelect = false;
  private showSystemSelect = false;
  private systemName = '';
  private fetchDocumentsContent = fetchDocumentsContent;
  private showModal = false;
  private spinnerTitle = 'Пожалуйста подождите, идет загрузка документа';

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private get documentFullName() {
    return this.self.activeDocument?.documentFullName?.value;
  }

  private get _documentFileType() {
    return this.self.activeDocument?._documentFileType || 'Digital';
  }
  private get document() {
    return this.self.activeDocument;
  }

  private get documentCategoryItems() {
    const activeDocument = this.self.activeDocument as Document;
    const currentValue = activeDocument.documentsCategory;
    return Object.keys(DocumentsCategory).
      map((item, index) => {
        return {
          id: index,
          value: item === currentValue,
          color: (DocumentsCategory as any)[item],
          item,
        };
      });
  }

  protected get newDocumentsMode() {
    return this.activeDocument && (this.activeDocument.documentId === 0 || this.$route.name === 'create-new-document');
  }

  protected created() {
    this.onComponentEnter();
  }

  private handleInvalid(evt: any) {
    // TODO: Handle custom fields
  }

  private removeActiveDocument() {
    this.self.deleteDocument(this.self.activeDocument);
    this.$router.push({ name: 'documents' });
  }

  private async saveDocument() {
    this.spinnerTitle = 'Пожалуйста подождите, идет сохранение документа';
    this.loading = true;
    const activeDocument = this.self.activeDocument;

    if (!this.documentIsValid()) {
      return false;
    }
    const newDocumentsMode = this.newDocumentsMode;
    let successOnSave = true;
    if (this.newDocumentsMode) {
      const saveDocument = await this.self[types.SAVE_NEW_DOCUMENT](activeDocument);
      successOnSave = !!saveDocument;
    }
    if (!this.self.hasVersionsChanges && !this.newDocumentsMode) {
      await this.self[types.UPDATE_NON_VERSIONED_PROPS](activeDocument);
      successOnSave = true;
    }
    if (this.self.hasVersionsChanges && !this.newDocumentsMode) {
      const saveDocument = await this.self[types.UPDATE_DOCUMENT](activeDocument);
      successOnSave = !!saveDocument;
    }
    this.loading = false;
    if (!successOnSave) {
      this.loading = false;
      return;
    }
    this.self.setActiveDocument(undefined);
    newDocumentsMode ? this.$router.push({ path: '/' }) : this.$router.go(-1);
  }

  private beforeCreate() {
    this.self = this;
  }

  private async onComponentEnter() {
    if (this.activeDocument) {
      this.showWatchersSelect = !!this.activeDocument.watchers && !!this.activeDocument.watchers.length;
      this.systemName = this.activeDocument.outerSystem ? this.activeDocument.outerSystem.name : '';
      this.showSystemSelect = !!this.systemName;
    }
  }

  private addNewComment() {
    const value = [...(this.self.activeDocument as Document).documentComments || []];
    value.push('');
    this.self.updateActiveDocumentProperty({ prop: 'documentComments', value });
  }

  private addNewNode() {
    const value = [...(this.self.activeDocument as Document).documentNotes || []];
    value.push('');
    this.self.updateActiveDocumentProperty({ prop: 'documentNotes', value });
  }

  private updateDocumentsCategory($event: any) {
    const activeDocument = this.self.activeDocument as Document;
    this.self.updateActiveDocumentProperty({ prop: 'documentsCategory', value: $event.item });
  }
}
