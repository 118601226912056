var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[
    _vm.className,
    _vm.horizontal ? 'horizontal' : '',
    _vm.fullWidth ? 'full-width' : '' ]},[_c('div',{class:[(_vm.baseClassName + "__label")]},[_c('span',{class:(_vm.baseClassName + "__text")},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"required-star"},[_vm._v("*")]):_vm._e()])]),_vm._l((_vm.items),function(item){return _c('label',{key:item.id,class:[
      (_vm.baseClassName + "__group"),
      _vm.isChecked(item) ? 'checked' : '',
      _vm.isValid ? 'valid' : 'not-valid',
      _vm.fullWidth ? 'full-width' : '' ]},[_c('input',{class:[(_vm.baseClassName + "__input")],attrs:{"type":"radio","label":item.value,"required":_vm.required,"name":item.value,"disabled":_vm.disabled},domProps:{"value":_vm.value,"checked":_vm.isValid},on:{"click":function($event){_vm.disabled ? function () { return null; } : _vm.$emit('change', item)}}}),_c('span',[_vm._v(_vm._s(item.value))])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }