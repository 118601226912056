

































import { Component, Vue, Prop } from 'vue-property-decorator';
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import { User } from '@/models/users.model';
import { Catalogue, createNewCatalogue } from '@/models/catalogues.model';
import { QueryParams } from '@/commons/axios.config';
import { debounce } from 'debounce';

const baseClassName = 'elib-catalogues-list';

@Component({
  name: baseClassName,
  computed: {
    ...mapState('catalogues', ['cataloguesList', 'filterString']),
    ...mapGetters('session', ['hasAuthorityByCode']),
  },
  methods: {
    ...mapActions('catalogues', ['getCatalogues', 'saveNewCatalogue', 'setFilterString', 'getCataloguesByQuery']),
  },
})
export default class CatalogueList extends Vue {
  protected filterString!: string;
  protected setFilterString!: (value: string) => void;
  protected getCatalogues!: (params?: QueryParams) => PromiseLike<Catalogue[]>;
  protected getCataloguesByQuery!: (params?: QueryParams) => PromiseLike<Catalogue[]>;
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  private baseClassName = baseClassName;
  private newCatalogueLevel: Catalogue | null = null;

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private get stringFilter() {
    return this.filterString;
  }

  private set stringFilter(query: string) {
    this.setFilterString(query);
    this.debounceSearch({ offset: 0, query });
  }

  private get debounceSearch() {
    return debounce(async (params: QueryParams) => {
      await this.getCataloguesByQuery({...params});
    }, 1000);
  }

  private created() {
    this.onComponentEnter();
  }

  private activated() {
    this.onComponentEnter();
  }

  private onComponentEnter() {
    this.getCatalogues();
  }

  private createNewCatalogueLevel(parent: Catalogue) {
    const hasPermission = (this as any).hasAuthorityByCode(6);
    if (!hasPermission) {
      this.$notifier({
        title: 'Недостаточно прав!', message: 'У вас нет прав для редактирования каталога документов',
        type: 'error',
      });
      return;
    }
    this.newCatalogueLevel = createNewCatalogue(parent);
  }

  private async addNewCatalogue(newLevel: Catalogue) {
    const hasPermission = (this as any).hasAuthorityByCode(6);
    if (!hasPermission) {
      this.$notifier({
        title: 'Недостаточно прав!', message: 'У вас нет прав для редактирования каталога документов',
        type: 'error',
      });
      return;
    }
    newLevel.id = 0;
    await (this as any).saveNewCatalogue({ catalogue: newLevel, parent: undefined });
    this.newCatalogueLevel = null;
  }

  private onCancel() {
    this.newCatalogueLevel = null;
  }
}
