













import { Vue, Component, Prop } from 'vue-property-decorator';

const baseClassName = 'elib-icon-check-circle';

@Component({
  name: baseClassName,
})
export default class IconCheckCircle extends Vue {
  @Prop({ default: () => 24 }) private width!: number;
  @Prop({ default: () => 24 }) private height!: number;
  private baseClassName = baseClassName;
}
