































import { Store } from '@/models/commons';
import { Vue, Component, Prop, Model } from 'vue-property-decorator';

const baseClassName = 'elib-input-autocomplete';

@Component({
  name: baseClassName,
})
export default class InputAutocomplete extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  @Prop({ default: () => '' }) private placeholder!: string;
  @Model('change', { type: String, default: () => '' }) private value!: string;
  @Prop({ default: () => [] }) private suggestions!: Store[];
  @Prop({ default: () => true }) private clearble!: boolean;
  @Prop({ default: () => 'id'}) private idKey!: string;
  @Prop({ default: () => 'value'}) private valueKey!: string;
  private isFocused = false;
  private baseClassName = baseClassName;

  private onValueSelect(ev: Event, suggestion: Store) {
    this.isFocused = false;
    this.$emit('select', suggestion);
    ev.stopPropagation();
  }

  private onClickOutised(event: Event) {
    const element = event.target as HTMLElement;
    this.isFocused = (this.$refs.section as HTMLElement).contains(element) || element === this.$refs.section;
  }

  private mounted() {
    this.onComponentEnter();
  }

  private beforeDestroy() {
    this.onComponentLeave();
  }

  private onComponentEnter() {
    window.addEventListener('click', this.onClickOutised);
  }

  private onComponentLeave() {
    window.removeEventListener('click', this.onClickOutised);
  }

  private getValueLabel(suggestion: Store) {
    return suggestion[this.valueKey];
  }
}
