
























import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapActions } from 'vuex';
import { Route } from 'vue-router';

const baseClassName = 'elib-auth-form';

@Component({
  name: baseClassName,
  methods: {
    ...mapActions('session', ['authenticate']),
  },
})
export default class AuthForm extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  private baseClassName = baseClassName;
  private login = process.env.NODE_ENV === 'development' ? 'admin_elib' : '';
  private password = process.env.NODE_ENV === 'development' ? 'Q1w2e3r4' : '';

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private async auth(ev: Event) {
    ev.preventDefault();
    const authData = { login: this.login, password: this.password };
    const session = await (this as any).authenticate(authData);
    if (session && session.token && session.user) {
      this.$router.push({ name: 'documents' });
    }
  }
}
