import { DocumentsAttribute } from '@/models/document-attributes.model';
import { $HTTPServicesClient as axiosClient, QueryParams, getUrlString } from '@/commons/axios.config';
import { DocumentsFilterDto } from '@/models/filters.model';
import { AUTH_TOKEN_HEADER, AUTH_TOKEN_NAME } from '@/commons/constants';


export async function fetchFilterAttributes(params: QueryParams) {
    try {
        const request = await axiosClient.get('/attribute-definitions', {
            params: {
                ...params,
                sortColumn: 'title',
            },
            headers: {
                [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME),
            },
        });
        return request.data.data as DocumentsAttribute[];
    } catch (error) {
        // tslint:disable-next-line: no-console
        console.error(error);
        return [];
    }
}

export async function fetchFilters(token = '', params: QueryParams) {
    let filters;
    try {
        const url = getUrlString('document-filters', params);
        const request = await axiosClient.get(url + '&sortColumn=name', {
            headers: {
                'x-auth-token': token || localStorage.getItem('elib-token'),
            },
        });
        filters = (request.data as {data: DocumentsFilterDto[]}).data;
    } catch (error) {
        // tslint:disable-next-line: no-console
        console.error(error);
    }
    return filters;
}

export async function postNewFilter(payload: DocumentsFilterDto) {
    try {
      const request = await axiosClient.post(`/document-filters`, JSON.stringify(payload), {
        headers: {
            [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME),
            'content-type': 'application/json;charset=UTF-8',
        },
      });
      return request.data.data as DocumentsFilterDto;
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.error(error);
    }
}

export async function putFilter(payload: DocumentsFilterDto) {
    try {
      const request = await axiosClient.put(`/document-filters/${payload.id}`, JSON.stringify(payload), {
        headers: {
          [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME),
          'content-type': 'application/json;charset=UTF-8',
        },
      });
      return request.data.data as DocumentsFilterDto;
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.error(error);
    }
}

export async function deleteFilter(filterId: number) {
    try {
        await axiosClient.delete(`/document-filters/${filterId}`,
            {
                headers: {
                    [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME),
                },
            },
        );
        return true;
    } catch (error) {
        // tslint:disable-next-line: no-console
        console.error(error);
        return false;
    }
  }
