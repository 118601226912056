import { Vue } from 'vue-property-decorator';
import { Module } from 'vuex';
import {
  DocumentsAttribute,
  getEmptyDocumentsAttribute,
  AttributesTypeFilter,
  defaultValueIsBoolean,
} from '@/models/document-attributes.model';
import {
  fetchDocumentAttributes,
  fetchDocumentsAttributeById,
  postNewDocumentsAttribute,
  updateExistingDocumentsAttribute,
  deleteAttribute,
  fetchRequiredDocumentAttributes,
} from '@/controllers/document-attributes.controllers';
import {
  fetchDictionaries, fetchDictionaryById,

} from '@/controllers/dictionaries.controller';
import { Dictionary } from '@/models/dictionaries.model';
import { QueryParams } from '@/commons/axios.config';
import { parseBooleanAsString } from '@/commons/dom.helpers';
import types from '../types';

type StringFilter = string | undefined;

interface ModuleState {
  documentAttributesList: DocumentsAttribute[];
  filterString: StringFilter;
  activeDocumentsAttribute: DocumentsAttribute | undefined;
  dictionaryValues: Dictionary[];
  activeDictionary: Dictionary | undefined;
  typeFilter: AttributesTypeFilter | null;
}

export const module: Module<ModuleState, any> = {
  namespaced: true,
  state: {
    documentAttributesList: [],
    filterString: undefined,
    activeDocumentsAttribute: undefined,
    dictionaryValues: [],
    activeDictionary: undefined,
    typeFilter: null,
  },
  mutations: {
    [types.ADD_DOCUMENT_ATTRIBUTES_TO_LIST](state: ModuleState, payload: DocumentsAttribute[]) {
      payload.forEach((attribute) => {
        if (!state.documentAttributesList.some((v) => v.id === attribute.id)) {
          state.documentAttributesList.push(attribute);
        }
      });
      state.documentAttributesList = [...state.documentAttributesList];
      state.documentAttributesList.sort((a, b) => a.title > b.title ? 1 : -1);
    },
    [types.SET_DOCUMENT_ATTRIBUTES_LIST](state: ModuleState, payload: DocumentsAttribute[] = []) {
      state.documentAttributesList = [...payload];
    },
    filterString(state: ModuleState, stringFilter: StringFilter) {
      state.filterString = stringFilter;
    },
    setActiveDocumentsAttribute(state: ModuleState, payload: DocumentsAttribute) {
      state.activeDocumentsAttribute = payload;
    },
    [types.SET_EMPTY_DOCUMENT_ATTRIBUTE](state: ModuleState) {
      state.activeDocumentsAttribute = getEmptyDocumentsAttribute();
    },
    updateActiveAttributeProperty(state: ModuleState, payload: { prop: string; value: any }) {
      if (state.activeDocumentsAttribute) {
        Vue.set(state.activeDocumentsAttribute, payload.prop, payload.value);
      }
    },
    updateDocumentAttributes(state: ModuleState, payload: DocumentsAttribute) {
      const index = state.documentAttributesList.findIndex((v) => v.id === payload.id);
      if (index > -1) {
        state.documentAttributesList[index] = payload;
      } else {
        state.documentAttributesList.push(payload);
      }
      state.documentAttributesList = [...state.documentAttributesList].sort((a, b) => a.title > b.title ? 1 : -1);
    },
    setDictionaryValues(state: ModuleState, payload: Dictionary[]) {
      state.dictionaryValues = payload;
    },
    [types.DELETE_DOCUMENTS_ATTRIBUTE](state: ModuleState, payload: DocumentsAttribute) {
      state.documentAttributesList = state.documentAttributesList.filter((v) => v.id !== payload.id);
    },
    setActiveDictionary(state: ModuleState, payload: Dictionary) {
      state.activeDictionary = payload;
    },
    setAttributesTypeFilter(state: ModuleState, payload: AttributesTypeFilter) {
      state.typeFilter = payload;
    },
  },
  getters: {
    actualDocumentAttributesList({ documentAttributesList, filterString, typeFilter }) {
      return getTypeFilteredAttributes(getFilteredDocumentAttributes(
        documentAttributesList,
        filterString,
      ), typeFilter);
    },
    dictionaryValueOptions({ activeDictionary }) {
      return activeDictionary ? activeDictionary.dictionaryValues : [];
    },
  },
  actions: {
    async getDocumentsAttribute({ commit, state }, params?: QueryParams) {
      const queryParams = {
        ...{ offset: state.documentAttributesList.length, limit: 1000, query: state.filterString, sortColumn: 'title' },
        ...{attributeType: state.typeFilter?.type?.code},
        ...params,
      };
      const attributes = await fetchDocumentAttributes(queryParams);
      commit(!!queryParams.offset ? types.ADD_DOCUMENT_ATTRIBUTES_TO_LIST : types.SET_DOCUMENT_ATTRIBUTES_LIST,
        attributes);
      return attributes || [];
    },
    async [types.FETCH_REQUIRED_DOCUMENT_ATTRIBUTES]({ commit }) {
      const attributes = await fetchRequiredDocumentAttributes() || [];
      commit(types.ADD_DOCUMENT_ATTRIBUTES_TO_LIST, attributes);
      return attributes;
    },
    async setFilterString({ commit }, stringFilter: string) {
      commit('filterString', stringFilter);
    },
    async getActiveDocumentsAttributeById({ commit, rootState, state, dispatch }, payload: number) {
      const attribute = await fetchDocumentsAttributeById(rootState.token, payload) as DocumentsAttribute;
      formatAttributeBooleanDefaultValue(attribute);
      commit('setActiveDocumentsAttribute', attribute);
      if (state.dictionaryValues.length === 0) {
        await dispatch('getDictionaries');
      }
      if (attribute.dictionary) {
        await dispatch('setActiveDictionary', attribute.dictionary);
      }
    },
    async updateDocumentsAttribute({ commit, rootState }, payload: DocumentsAttribute) {
      const documentsAttribute = await updateExistingDocumentsAttribute(rootState.token, payload);
      if (documentsAttribute) {
        commit('updateDocumentAttributes', documentsAttribute);
      }
    },
    async createNewDocumentsAttribute({ commit, rootState }, payload: DocumentsAttribute) {
      const documentsAttribute = await postNewDocumentsAttribute(rootState.token, payload);
      if (documentsAttribute) {
        commit('updateDocumentAttributes', documentsAttribute);
      }
    },
    async getDictionaries({ commit }) {
      const dictionaryValues = await fetchDictionaries();
      commit('setDictionaryValues', dictionaryValues);
      return dictionaryValues;
    },
    async [types.DELETE_DOCUMENTS_ATTRIBUTE]({ commit, rootState }, payload: DocumentsAttribute) {
      const deleted = await deleteAttribute(rootState.token, payload);
      if (deleted) {
        commit(types.DELETE_DOCUMENTS_ATTRIBUTE, payload);
      }
    },
    async setActiveDictionary({ commit, rootState }, payload: Dictionary) {
      const dictionary = await fetchDictionaryById(payload.id);
      commit('setActiveDictionary', dictionary);
    },
  },
};

function getFilteredDocumentAttributes(attributesList: DocumentsAttribute[], filter: StringFilter) {
  return attributesList.filter((v) => {
    const titleSatisfy = filter ? RegExp(filter, 'gi').test(v.title) : true;
    const codeSatisfy = filter ? RegExp(filter, 'gi').test(v.code) : true;
    return titleSatisfy || codeSatisfy;
  });
}

function getTypeFilteredAttributes(attributesList: DocumentsAttribute[], typeFilter: AttributesTypeFilter | null) {
  if (!typeFilter) {
    return attributesList;
  }

  return attributesList.filter((v) => {

    const typeSatisfy = !!typeFilter.type ? v.attributeType.code === typeFilter.type.code : true;

    const dictionarySatisfy = !!typeFilter.dictionary && v.dictionary && !!v.dictionary ?
      v.dictionary.id === typeFilter.dictionary.id : true;
    const valueDictionary = v.dictionary;
    if (!valueDictionary || valueDictionary === null) {
      return typeSatisfy && dictionarySatisfy;
    }
    const defaultValue = parseInt(v.defaultValue as string, 10);
    const dicValueById = valueDictionary.dictionaryValues.find((v1) => v1.id === defaultValue) as any;
    const valueSatisfy = !!typeFilter.dictionariesValue ?
      typeFilter.dictionariesValue.value === dicValueById.value : true;
    return typeSatisfy && dictionarySatisfy && valueSatisfy;
  });
}


function formatAttributeBooleanDefaultValue(attribute: DocumentsAttribute) {
  if (defaultValueIsBoolean(attribute)) {
    attribute.defaultValue = parseBooleanAsString(attribute.defaultValue as string);
  }
}
