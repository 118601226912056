var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:_vm.className},[_c('form',{ref:"mainForm",class:(_vm.baseClassName + "__form"),on:{"submit":function ($event) { return $event.preventDefault(); }}},[_c('h3',{class:(_vm.baseClassName + "__title")},[_vm._v("Атрибут")]),_c('elib-input-default',{class:[(_vm.baseClassName + "__name"), _vm.isFocused ? 'is-focused' : ''],attrs:{"label":"Наименование","value":_vm.documentsAttribute.title,"disabled":!_vm.isNewAttributteCreation,"required":true},on:{"change":function($event){return _vm.updateActiveAttributeProperty({ prop: 'title', value: $event })}}}),_c('section',{class:[
        (_vm.baseClassName + "__code-type-section"),
        _vm.isSelectFromReference ? 'is-reference-select' : '' ]},[_c('elib-input-default',{class:[(_vm.baseClassName + "__code"), _vm.isFocused ? 'is-focused' : ''],attrs:{"label":"Код","disabled":!_vm.isNewAttributteCreation,"required":true},on:{"change":function($event){return _vm.updateActiveAttributeProperty({ prop: 'code', value: $event })}},model:{value:(_vm.documentsAttribute.code),callback:function ($$v) {_vm.$set(_vm.documentsAttribute, "code", $$v)},expression:"documentsAttribute.code"}}),_c('elib-input-select',{attrs:{"label":"Тип","placeholder":"Выберите тип данных для атрибута","options":_vm.typeOptions,"value":_vm.attributeTypeName,"incomingClasses":[(_vm.baseClassName + "__types")],"disabled":!_vm.isNewAttributteCreation},on:{"selected":function($event){return _vm.onTypeSelect($event)}}})],1),(_vm.isSelectFromReference)?_c('section',{class:(_vm.baseClassName + "__type-section")},[_c('elib-input-assumption-select',{attrs:{"fullWidth":true,"label":"Наименование справочника","assamptions":_vm.attributesSuggestions,"value":_vm.definitionQuery,"incomingClasses":[(_vm.baseClassName + "__dictionaries-name")],"disabled":!_vm.isNewAttributteCreation,"readonly":!_vm.isNewAttributteCreation,"required":true},on:{"select":function($event){return _vm.onDictionarySelect($event)},"input":function($event){return _vm.queryAttributes($event)}}}),_vm._v(_vm._s(_vm.query)+" "),(_vm.dictionaryView === 'COLORS')?_c('elib-input-categories',{attrs:{"label":"Значение по умолчанию","fullWidth":true,"incomingClasses":[(_vm.baseClassName + "__document-categories")],"items":_vm.dictionaryValues,"value":_vm.query,"name":"document-categoires"},on:{"change":function($event){return _vm.updateActiveAttributeProperty({
            prop: 'defaultValue',
            value: $event.id,
          })}}}):_c('elib-input-assumption-select',{attrs:{"label":"Значение по умолчанию","fullWidth":true,"assamptions":_vm.dictionaryValues,"value":_vm.query,"incomingClasses":[(_vm.baseClassName + "__dictionary-values")],"disabled":!_vm.isNewAttributteCreation,"readonly":!_vm.isNewAttributteCreation},on:{"input":_vm.queryDictionaryValues,"select":function($event){return _vm.updateActiveAttributeProperty({
            prop: 'defaultValue',
            value: $event.id,
          })}}})],1):_vm._e(),_c('elib-input-radio',{attrs:{"label":"Поисковый","radiotype":"search","value":_vm.documentsAttribute.searchable,"items":_vm.YesNoValues,"incomingClasses":[(_vm.baseClassName + "__searchable")],"disabled":!_vm.isNewAttributteCreation},on:{"change":function($event){return _vm.updateActiveAttributeProperty({ prop: 'searchable', value: $event })}}}),_c('elib-input-radio',{attrs:{"label":"Отображение по умолчанию","radiotype":"required","value":_vm.documentsAttribute.required,"items":_vm.YesNoValues,"incomingClasses":[(_vm.baseClassName + "__required")],"disabled":!_vm.isNewAttributteCreation},on:{"change":function($event){return _vm.updateActiveAttributeProperty({ prop: 'required', value: $event })}}}),(_vm.hasDefaultValues && _vm.isSelectFromBoolean)?[_c('elib-input-boolean',{class:[
          (_vm.baseClassName + "__default-value"),
          _vm.isFocused ? 'is-focused' : '' ],attrs:{"label":"Значение по-умолчанию","value":_vm.documentsAttribute.defaultValue,"readonly":!_vm.isNewAttributteCreation},on:{"change":function($event){return _vm.updateActiveAttributeProperty({
            prop: 'defaultValue',
            value: $event,
          })}}})]:(_vm.hasDefaultValues && _vm.isSelectFromNumber)?[_c('elib-input-default',{class:[
          (_vm.baseClassName + "__default-value"),
          _vm.isFocused ? 'is-focused' : '' ],attrs:{"label":"Значение по-умолчанию","value":_vm.documentsAttribute.defaultValue,"disabled":!_vm.isNewAttributteCreation,"input-type":'number'},on:{"change":function($event){return _vm.updateActiveAttributeProperty({
            prop: 'defaultValue',
            value: $event,
          })}}})]:(_vm.hasDefaultValues && _vm.isSelectFromDate)?[_c('elib-input-datepicker',{class:[ (_vm.baseClassName + "__default-value"), _vm.isFocused ? 'is-focused' : ''],attrs:{"label":"Дата документа","value":_vm.documentsAttribute.documentsDate},on:{"select":function($event){return _vm.updateActiveAttributeProperty({ prop: 'documentsDate', value: $event })}}})]:(_vm.hasDefaultValues)?[_c('elib-input-default',{class:[
          (_vm.baseClassName + "__default-value"),
          _vm.isFocused ? 'is-focused' : '' ],attrs:{"label":"Значение по-умолчанию","value":_vm.documentsAttribute.defaultValue,"disabled":!_vm.isNewAttributteCreation},on:{"change":function($event){return _vm.updateActiveAttributeProperty({
            prop: 'defaultValue',
            value: $event,
          })}}})]:_vm._e()],2),_c('footer',{class:(_vm.baseClassName + "__actions")},[(_vm.isNewAttributteCreation)?_c('button',{class:(_vm.baseClassName + "__actions-save btn btn_lg btn_red"),on:{"click":_vm.saveDocumentsAttribute}},[_vm._v(" Сохранить ")]):_vm._e(),_c('button',{class:(_vm.baseClassName + "__actions-cancel btn btn_lg btn_default"),on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" Отменить ")]),_c('button',{class:(_vm.baseClassName + "__actions-delete btn btn_lg btn_default"),on:{"click":function($event){_vm.showModal = true}}},[_vm._v(" Удалить ")]),(_vm.showModal)?_c('elib-modal-delete',{on:{"confirm":function($event){_vm.deleteAttribute(), (_vm.showModal = false)},"close":function($event){_vm.showModal = false}}},[_c('h3',{attrs:{"slot":"header"},slot:"header"},[_vm._v("Вы действительно хотите удалить атрибут?")]),_c('span',{attrs:{"slot":"body"},slot:"body"},[_vm._v("Это не повлияет на связанные с атрибутом сущности, но отменить данное действие будет невозможно")])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }