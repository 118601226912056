













































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { Document, DocumentsCategory, DocumentsContent } from '@/models/user-documents.model';
import { TreeViewData, DropDownActions } from '@/models/view.model';
import { getDateRepresentation } from '@/commons/filters.main';
import { Catalogue } from '../../models/catalogues.model';
import { visibleProfileAttributes, getProfileAttribute, DocumentProfileAttribute } from '@/components/UserDocuments/UserDocuments.module';
import { additionalInfoRows } from '@/components/UserDocuments/UserDocuments.module';
import { fetchDocumentsContent, fetchChangeDocumentsFavorites } from '@/controllers/user-documents.controllers';
import { getDateTimeSimpleRepresentation, getUserShortFullName } from '@/commons/dom.helpers';
import LinkedDocuments from '@/components/UserDocuments/LinkedDocuments.vue';
import VersionsBar from '@/components/UserDocuments/DocumentsVersionsBar.vue';
import DeleteDialog from './DocumentsDeleteDialog.vue';
import * as types from '@/store/types';
import { Store } from '@/models/commons';

const baseClassName = 'elib-documents-profile';

@Component({
  name: baseClassName,
  components: {
    LinkedDocuments,
    'elib-documents-versions-bar': VersionsBar,
    'elib-documents-delete-dialog': DeleteDialog,
  },
  computed: {
    ...mapState('documents', [types.ACTIVE_DOCUMENT, 'hasVersionsChanges']),
    ...mapState('catalogues', ['cataloguesList']),
    ...mapGetters('catalogues', ['cataloguesTreeViewNodeById']),
    ...mapGetters('session', ['hasAuthorityByCode', 'canEditDocument']),
  },
  methods: {
    ...mapActions('documents', [types.FETCH_DOCUMENT_BY_ID, types.CREATE_NEW_DOCUMENT_ON_BASIS,
      types.FETCH_DOCUMENT_VERSIONS,
      'deleteDocument', 'saveNewDocument', 'updateNonVersionedProps', 'updateDocument']),
    ...mapActions('catalogues', ['getCatalogues']),
    ...mapMutations('documents', ['setActiveDocument', 'setHasVersionsChanges', 'updateActiveDocumentProperty',
      types.UPDATE_ACTIVE_DOCUMENTS_PROP]),
  },
})
export default class DocumentsProfile extends Vue {
  protected fetchDocumentVersions!: (id: number) => Promise<Document>;
  protected fetchDocumentById!: (id: number) => Promise<Document>;
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  private baseClassName = baseClassName;
  private self: any;
  private loading = true;
  private documentsCategory = DocumentsCategory;
  private showVersions = false;
  private fetchDocumentsContent = fetchDocumentsContent;
  private versionBarHeight = 0;
  private getDateTimeSimpleRepresentation = getDateTimeSimpleRepresentation;
  private showModal = false;
  private permanentlyDelete = false;
  private fetchChangeDocumentsFavorites = fetchChangeDocumentsFavorites;
  private spinnerTitle = 'Пожалуйста подождите, идет загрузка документа';
  private visibleDocumentAttributes: Store[] = [];
  private visibleAdditinalRows: Store[] = [];
  private isCurrentVersion: boolean = true;

  private get documentId(): number | string {
    return this.self.activeDocument?.documentId || this.$route.params?.id;
  }

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private get attachments(): File[] {
    return this.self.activeDocument.attachments || [];
  }

  private async saveDocument() {
    this.spinnerTitle = 'Пожалуйста подождите, идет сохранение документа';
    this.loading = true;
    const activeDocument = this.self.activeDocument;

    let successOnSave = true;
    if (!this.self.hasVersionsChanges) {
      await this.self[types.UPDATE_NON_VERSIONED_PROPS](activeDocument);
      successOnSave = true;
    }
    if (this.self.hasVersionsChanges) {
      const saveDocument = await this.self[types.UPDATE_DOCUMENT](activeDocument);
      successOnSave = !!saveDocument;
    }

    this.loading = false;
    if (!successOnSave) {
      this.loading = false;
      return;
    }
    this.onComponentEnter().then(() => setTimeout(() => this.loading = false, 1000));
  }

  private deleteFilesAttachment(data: DocumentsContent) {
    const value: DocumentsContent[] = [...this.fileInfos].filter((v) => v.fileName !== data.fileName);
    this.self[types.UPDATE_ACTIVE_DOCUMENTS_PROP]({ prop: 'contents', value });
    const attachments: File[] = [...this.attachments].filter((v) => v.name !== data.fileName);
    this.self[types.UPDATE_ACTIVE_DOCUMENTS_PROP]({ prop: 'attachments', value: attachments });
    setTimeout(() => {
      this.saveDocument();
    }, 0);
  }

  private get documentsNumberDateString() {
    const document = this.self.activeDocument as Document;
    if (document) {
      return `№ ${document.documentNumber || '---'} от ${getDateRepresentation(document.documentsDate)}`;
    }
    return '';
  }

  private get changeLogString() {
    const document = this.self.activeDocument as Document;
    if (document && document.updated) {
      return `Изменен ${getDateRepresentation(document.updated)} ` +
        (document.author && !!document.author ? getUserShortFullName(document.author) : '')
        + (document.outerSystem ? ' в ' + document.outerSystem.name : '');
    }
    return '';
  }

  private get documentsTypePath() {
    const document = this.self.activeDocument as Document;
    const node = this.self.cataloguesTreeViewNodeById(document.documentTypeId) as TreeViewData;
    if (!node) {
      return;
    }
    return node.path;
  }

  private get profileActions(): DropDownActions[] {
    const document = this.self.activeDocument as Document;
    return [
      {
        title: 'Редактировать',
        action: this.goToEditForm,
        icon: 'elib-icon-pencil',
      },
      {
        title: 'Копировать как',
        action: () => {
          if (!this.self.hasAuthorityByCode(23)) {
            this.$notifier({
              title: 'Недостаточно прав!', message: 'У вас нет прав для создания документа на основании',
              type: 'error',
            });
            return;
          }

          this.self.copyActiveDocumentToNew().then(() => {
            this.self.$router.push({ name: 'create-new-document' });
          });
        },
        icon: 'elib-icon-copy',
      },

    ];
  }

  private get fileInfos() {
    const activeDocument = this.self[types.ACTIVE_DOCUMENT] as Document;
    return activeDocument.contents || [];
  }

  private get sortedAttributes() {
    return this.visibleDocumentAttributes.sort((a, b) => a.order - b.order);
  }

  private get customAttributes() {
    return this.visibleDocumentAttributes.sort((a, b) => a.order - b.order);
  }

  private get canEditAttachments() {
    return this.isCurrentVersion && this.self.canEditDocument(this.self.activeDocument);
  }

  private removeActiveDocument() {
    this.self.deleteDocument(this.self.activeDocument);
    this.$router.push({ name: 'documents' });
  }

  private created() {
    this.onComponentEnter();
  }

  private updateAdditinalAttributes(isCurrentVersion: boolean) {
    this.visibleAdditinalRows = [];
    this.visibleDocumentAttributes = [];
    visibleProfileAttributes.forEach(async (attr) =>
      this.visibleDocumentAttributes.push(await getProfileAttribute(this.self.activeDocument as Document, attr)));
    additionalInfoRows.forEach(async (attr) =>
      this.visibleAdditinalRows.push(await getProfileAttribute(this.self.activeDocument as Document, attr)));
    this.isCurrentVersion = isCurrentVersion;
  }

  private beforeCreate() {
    this.self = this;
  }

  private async onComponentEnter() {
    this.loading = true;
    await this.fetchDocumentById((this.$route.params as any).id);
    await this.fetchDocumentVersions((this.$route.params as any).id);
    const catalogues = this.self.cataloguesList as Catalogue[];
    this.self.getCatalogues({all: true});
    this.updateAdditinalAttributes(true);
    this.loading = false;
  }

  private goToEditForm() {
    const activeDocument = this.self.activeDocument as Document;
    if (!this.self.canEditDocument(activeDocument)) {
      this.$notifier({
        title: 'Недостаточно прав!', message: 'У вас нет прав для редактирования документа',
        type: 'error',
      });
      return;
    }
    if (activeDocument.deleted) {
      this.$notifier({
        title: 'Ошибка!',
        message: `Нельзя редактировать удаленный документ`,
        type: 'error',
      });
      return;
    }
    this.$router.push({ name: 'edit-document', params: { id: activeDocument.documentId.toString() } });
  }

  private toggleVersionBar() {
    if (!this.self.hasAuthorityByCode(18)) {
      this.$notifier({
        title: 'Недостаточно прав!', message: 'У вас нет прав для просмотр версий документов',
        type: 'error',
      });
      this.showVersions = false;
      return;
    }
    this.showVersions = !this.showVersions;
  }

  @Watch('documentId', {deep: true, immediate: false})
  private onActiveDocumentChange(newId: number, oldId: number): void {
    if (newId !== oldId) {
      this.loading = true;
      this.updateAdditinalAttributes(true);
      this.loading = false;
    }
  }

  private async navigateToDocument(documentId: number) {
    if (documentId) {
      try {
        this.loading = true;
        await this.self[types.FETCH_DOCUMENT_BY_ID](documentId);
        this.loading = false;
        this.$router.push({ name: 'documents-profile', params: { id: documentId.toString() } });
      } catch (error) {
        this.loading = false;
      }
    }
  }
}
