









import { Vue, Component, Prop } from 'vue-property-decorator';

const baseClassName = 'elib-modal';

@Component({
  name: baseClassName,
})
export default class ModalDefault extends Vue {
  private baseClassName = baseClassName;
}
