















import { Component, Vue, Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

const baseClassName = 'elib-side-bar';

@Component({
  name: baseClassName,
  computed: {
    ...mapGetters('session', ['hasAuthorityByCode']),
  },
})
export default class AdminSideBar extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  private baseClassName = baseClassName;

  private get asideLinks() {
    return [

      {
        title: 'Каталог документов',
        icon: 'elib-icon-documents-catalogue',
        component: 'elib-catalogues-list',
        isActive: false,
        isVisible: (this as any).hasAuthorityByCode(5),
      },
      {
        title: 'Атрибуты документов',
        icon: 'elib-icon-document-attributes',
        component: 'elib-document-attributes-list',
        isActive: false,
        isVisible: (this as any).hasAuthorityByCode(4),
      },
      {
        title: 'Роли',
        icon: 'elib-icon-user-roles',
        component: 'elib-roles-list',
        isActive: false,
        isVisible: (this as any).hasAuthorityByCode(36),
      },
      {
        title: 'Пользователи',
        icon: 'elib-icon-users-list',
        component: 'elib-users-list',
        isActive: false,
        isVisible: (this as any).hasAuthorityByCode(2),
      },
    ];
  }

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private onLinkClick(link: { component: string, isActive: boolean, title: string }) {
    this.$router.push({ name: 'adminWithContentId', params: { contentId: link.component } });
    this.asideLinks.forEach((v) => {
      v.title === link.title ? v.isActive = true : v.isActive = false;
      return v;
    });
  }

  private created() {
    if (this.$route.params && this.$route.params.contentId) {
      this.asideLinks.forEach((v) => {
        this.$route.params.contentId === v.component ? v.isActive = true : v.isActive = false;
        return v;
      });
    }
  }
}
