






















import { Vue, Component, Prop } from 'vue-property-decorator';

const baseClassName = 'elib-icon-close';

@Component({
  name: baseClassName,
})
export default class IconClose extends Vue {
  @Prop({ default: () => 24 }) private width!: number;
  @Prop({ default: () => 24 }) private height!: number;
  @Prop({ default: () => false }) private smallSize!: boolean;
  private baseClassName = baseClassName;
}
