import { DocumentsAttribute, AttributesType, attributeTypes } from '@/models/document-attributes.model';
import { getUserShortFullName } from '@/commons/dom.helpers';
import { fetchUsers } from '@/controllers/users.controllers';
import { fetchCatalogues } from '@/controllers/catalogues.controllers';
import { QueryParams } from '@/commons/axios.config';
import { User } from './users.model';
import { DocumentsStatus, DocumentClassification, DocumentsCategory } from './user-documents.model';

export type FilterItemValue = string | number | boolean | Array<string | number>;
export type ValuesRepresentation = Array<{ id: number, value: string }>;

export enum FilterViewType {
    AUTOCOMPLETE = 'AUTOCOMPLETE',
    CHECKBOX = 'CHECKBOX',
    TYPE = 'TYPE',
    COLORS = 'COLORS',
    DATE = 'DATE',
}

export interface DocumentsFilter {
    id: number;
    name: string;
    items: DocumentsFilterItem[];
}

export interface DocumentsFilterItem {
    attribute: DocumentsAttribute | DocumentsStaticAttribute;
    value: FilterItemValue;
    valuesRepresentation: ValuesRepresentation;
}

export interface DocumentsStaticAttribute {
    attributeType: AttributesType;
    code: string;
    id: number | string;
    title: string;
    static?: true;
    idKey?: string;
    valueKey?: string;
    externalField?: string;
    externalTable?: string;
    assumptionsFunction?: (...args: any) => any | void;
    formatFunction?: (...args: any) => any | void;
}

export interface DocumentsFilterDto {
    author: User | null;
    authorId: number;
    created: number;
    id: number;
    name: string;
    specification: DocumentsFilterItemDto[];
}

export interface DocumentsFilterItemDto {
    attributeDefinition?: DocumentsAttribute;
    attributeDefinitionId?: number;
    booleanValue?: boolean;
    dateValue?: number;
    dictionaryValueId?: number;
    externalField?: string;
    externalId?: number;
    externalTable?: string;
    headerId?: number;
    id?: number;
    numberValue?: number;
    stringValue?: string;
}

export const predefinedFilterItems: DocumentsStaticAttribute[] = [
    {
        attributeType: attributeTypes[0],
        code: 'document_name',
        id: 0,
        title: 'Имя документа',
        static: true,
        externalTable: 'DOCUMENT_VERSION',
        externalField: 'TITLE',
    },
    {
        attributeType: attributeTypes[0],
        code: 'document_number',
        id: 1,
        title: 'Номер документа',
        static: true,
        externalTable: 'DOCUMENT_VERSION',
        externalField: 'DOCUMENT_NUMBER',
    },
    {
        attributeType: attributeTypes[1],
        code: 'document_date',
        id: 2,
        title: 'Дата документа',
        static: true,
        externalTable: 'DOCUMENT_VERSION',
        externalField: 'DOCUMENTS_DATE',
    },
    {
        attributeType: attributeTypes[2],
        code: 'document_is_valid',
        id: 3,
        title: 'Валидность',
        static: true,
        externalTable: 'DOCUMENT_VERSION',
        externalField: 'IS_VALID',
    },
    {
        attributeType: attributeTypes[4],
        code: 'watcher',
        id: 4,
        title: 'Наблюдатель',
        static: true,
        idKey: 'id',
        valueKey: 'fullName',
        externalTable: 'DOCUMENT_WATCHERS',
        externalField: 'USER_ID',
        assumptionsFunction: async (params: QueryParams) => {
            return await fetchUsers(params);
        },
        formatFunction: getUserShortFullName,
    },
    {
        attributeType: attributeTypes[4],
        code: 'type_ID',
        id: 5,
        title: 'Тип документа',
        static: true,
        idKey: 'id',
        valueKey: 'title',
        externalTable: 'DOCUMENT_TYPE',
        externalField: 'ID',
        assumptionsFunction: async (params: QueryParams) => {
            return await getTypesTreeAsList(params);
        },
    },
    {
        attributeType: attributeTypes[1],
        code: 'document_storage_date',
        id: 6,
        title: 'Годен до',
        static: true,
        externalTable: 'DOCUMENT_VERSION',
        externalField: 'STORE_TILL',
    },
    {
        attributeType: attributeTypes[1],
        code: 'storeOriginalTill',
        id: 7,
        title: 'Оригинал хранится до',
        static: true,
        externalTable: 'DOCUMENT_VERSION',
        externalField: 'STORE_ORIGINAL_TILL',
    },
    {
        attributeType: attributeTypes[4],
        code: 'document_status',
        id: 8,
        title: 'Статус',
        static: true,
        idKey: 'id',
        valueKey: 'title',
        externalTable: 'DOCUMENT_VERSION',
        externalField: 'STATUS',
        assumptionsFunction: async (params: QueryParams) => {
            return new Promise((resolve, reject) => {
                resolve(Object.keys(DocumentsStatus).map((key, index) => {
                    return {
                        id: index,
                        title: (DocumentsStatus as any)[key],
                    };
                }));
            });
        },
    },
    {
        attributeType: attributeTypes[0],
        code: 'signer_1',
        id: 9,
        title: 'Подписант 1',
        static: true,
        externalTable: 'DOCUMENT_SIGNATORIES',
        externalField: 'STRING_VALUE',
    },
    {
        attributeType: attributeTypes[0],
        code: 'signer_2',
        id: 10,
        title: 'Подписант 2',
        static: true,
        externalTable: 'DOCUMENT_SIGNATORIES',
        externalField: 'STRING_VALUE',
    },
    {
        attributeType: attributeTypes[4],
        code: 'document_classification',
        id: 11,
        title: 'Классификация',
        static: true,
        idKey: 'id',
        valueKey: 'title',
        externalTable: 'DOCUMENT_VERSION',
        externalField: 'CLASSIFICATION',
        assumptionsFunction: async (params: QueryParams) => {
            return new Promise((resolve, reject) => {
                resolve(Object.keys(DocumentClassification).map((key, index) => {
                    return {
                        id: index,
                        title: (DocumentClassification as any)[key],
                    };
                }));
            });
        },
    },
    {
        attributeType: attributeTypes[4],
        code: 'DOCUMENT_CATEGORY',
        id: 12,
        title: 'Метка',
        static: true,
        idKey: 'id',
        valueKey: 'title',
        externalTable: 'DOCUMENT_VERSION',
        externalField: 'DOCUMENT_CATEGORY',
        assumptionsFunction: async (params: QueryParams) => {
            return new Promise((resolve, reject) => {
                resolve(Object.keys(DocumentsCategory).map((key, index) => {
                    return {
                        id: index,
                        title: key,
                    };
                }));
            });
        },
    },
    {
        attributeType: attributeTypes[2],
        code: 'DELETED',
        id: 13,
        title: 'Показать удаленные',
        static: true,
        externalTable: 'DOCUMENT_VERSION',
        externalField: 'DELETED',
    },
];

export async function getTypesTreeAsList(params: QueryParams) {
    const filter = params.query || '';
    const treesData = await fetchCatalogues(params) as Array<{ [key: string]: any }>;
    let stack = [...treesData];
    const foundedNodes = [];
    while (stack.length > 0) {
        const currentNode = stack.shift() as { [key: string]: any };
        if (RegExp(`^.*?${filter}.*?$`, 'gim').test(currentNode.title)) {
            foundedNodes.push(currentNode);
        }
        stack = [...stack, ...currentNode.children];
    }
    return foundedNodes;
}

export function createNewDocumentsFilter(): DocumentsFilter {
    return {
        id: 0,
        name: '',
        items: [],
    };
}

export function filtersModelToDto(filter: DocumentsFilter): DocumentsFilterDto {
    return {
        author: null,
        authorId: 0,
        created: Date.now().valueOf(),
        id: filter ? filter.id : 0,
        name: filter ? filter.name : '',
        specification: filterItemsToDto(filter),
    };
}

function filterItemsToDto(filter: DocumentsFilter): DocumentsFilterItemDto[] {
    const trivialItems = filter.items.filter((v) => isTrivialTypeDomain(v));
    const trivialSpec = getTrivialItemsSpec(trivialItems);
    const dictionaryItems = filter.items.filter((v) => isDictionaryTypeDomain(v));
    const dictionariesSpec = getDictionariesSpec(dictionaryItems);
    const staticItems = filter.items.filter((v) => isStaticDomain(v));
    const staticSpec = getStaticSpec(staticItems);
    return [...trivialSpec, ...dictionariesSpec, ...staticSpec];
}

function getDtoItemsDefaultHeader(item: DocumentsFilterItem) {
    return {
        attributeDefinition: item.attribute as DocumentsAttribute,
        attributeDefinitionId: item.attribute.id as number,
    };
}

function getTrivialItemsSpec(items: DocumentsFilterItem[]): DocumentsFilterItemDto[] {
    return items.map((item: DocumentsFilterItem) => {
        const defaultHeader = getDtoItemsDefaultHeader(item);
        if (item.attribute.attributeType.code === 'DATE') {
            return { ...defaultHeader, dateValue: item.value as number };
        }
        if (item.attribute.attributeType.code === 'BOOLEAN') {
            return { ...defaultHeader, booleanValue: item.value as boolean };
        }
        if (item.attribute.attributeType.code === 'NUMBER') {
            return { ...defaultHeader, numberValue: item.value as number };
        }
        return { ...defaultHeader, stringValue: item.value as string };
    });
}

function getDictionariesSpec(items: DocumentsFilterItem[]): DocumentsFilterItemDto[] {
    const specItems = items.map((item: DocumentsFilterItem) => {
        const defaultHeader = getDtoItemsDefaultHeader(item);
        const values = item.value as number[];
        return values.map((v) => {
            return { ...defaultHeader, dictionaryValueId: v };
        });
    });

    return specItems.flat();
}

function getStaticSpec(items: DocumentsFilterItem[]): DocumentsFilterItemDto[] {
    const trivialSpecItems = items.
        filter((v) => ['DATE', 'BOOLEAN', 'NUMBER', 'STRING'].includes(v.attribute.attributeType.code)).
        map((item: DocumentsFilterItem) => {
            const defaultHeader = getDtoItemsStaticHeader(item);
            const attribute = item.attribute as DocumentsStaticAttribute;
            const typeCode = attribute.attributeType.code;
            if (typeCode === 'DATE') {
                return { ...defaultHeader, dateValue: item.value as number };
            }
            if (typeCode === 'BOOLEAN') {
                return { ...defaultHeader, booleanValue: item.value as boolean };
            }
            if (item.attribute.attributeType.code === 'NUMBER') {
                return { ...defaultHeader, numberValue: item.value as number };
            }
            return { ...defaultHeader, stringValue: item.value as string };
        });

    const dictionarySpecItems = items.
        filter((v) => v.attribute.attributeType.code === 'DICTIONARY').
        map((item: DocumentsFilterItem) => {
            const defaultHeader = getDtoItemsStaticHeader(item);
            const representations = item.valuesRepresentation as ValuesRepresentation;
            return (item.value as number[]).map((v) => {
                const representation = representations.
                    find((rep) => rep.id === v) as { id: number, value: string };
                return {
                    ...defaultHeader,
                    externalId: v,
                    stringValue: representation.value,
                };
            });
        }).flat();

    return [...trivialSpecItems, ...dictionarySpecItems];
}

function getDtoItemsStaticHeader(item: DocumentsFilterItem) {
    const attribute = item.attribute as DocumentsStaticAttribute;
    return { externalTable: attribute.externalTable as string, externalField: attribute.externalField as string };
}

function isTrivialTypeDomain(item: DocumentsFilterItem) {
    return item.attribute &&
        item.attribute.attributeType &&
        !predefinedFilterItems.find((v) => v.code === item.attribute.code) &&
        !(item.attribute as DocumentsStaticAttribute).static &&
        ['DATE', 'BOOLEAN', 'STRING', 'NUMBER'].includes(item.attribute.attributeType.code);
}

function isDictionaryTypeDomain(item: DocumentsFilterItem) {
    const attribute = item.attribute as DocumentsStaticAttribute | DocumentsAttribute;
    return attribute.attributeType && attribute.attributeType.code === 'DICTIONARY' &&
        !(attribute as DocumentsStaticAttribute).static;
}

function isStaticDomain(item: DocumentsFilterItem) {
    const attribute = item.attribute as DocumentsStaticAttribute | DocumentsAttribute;
    return (attribute as DocumentsStaticAttribute).static;
}

function isTrivialTypeDto(item: DocumentsFilterItemDto) {
    return item.attributeDefinition &&
        item.attributeDefinition.attributeType &&
        ['DATE', 'BOOLEAN', 'STRING', 'NUMBER'].includes(item.attributeDefinition.attributeType.code);
}

function isDictionaryTypeDto(item: DocumentsFilterItemDto) {
    const attribute = item.attributeDefinition as DocumentsAttribute;
    return attribute && attribute.attributeType &&
        attribute.attributeType.code === 'DICTIONARY' &&
        !item.externalTable;
}

function isStaticTypeDto(item: DocumentsFilterItemDto) {
    return item.externalTable && item.externalField;
}

export function filtersDtoToDomain(dto: DocumentsFilterDto): DocumentsFilter {
    return {
        id: dto.id as number,
        name: dto.name,
        items: filterItemsDtoToDomain(dto),
    };
}

export function filterItemsDtoToDomain(dto: DocumentsFilterDto): DocumentsFilterItem[] {
    const trivialSpec = dto.specification.filter((v) => isTrivialTypeDto(v));
    const trivialItems = getTrivialItems(trivialSpec);
    const dictionarySpec = dto.specification.filter((v) => isDictionaryTypeDto(v));
    const dictionaryItems = getDictionaryItems(dictionarySpec);
    const staticSpec = dto.specification.filter((v) => isStaticTypeDto(v));
    const staticItems = getStaticItems(staticSpec);
    return [...trivialItems, ...dictionaryItems, ...staticItems];
}

function getTrivialItems(spec: DocumentsFilterItemDto[]): DocumentsFilterItem[] {
    return spec.map((item) => {
        const definition = item.attributeDefinition as DocumentsAttribute;
        let value = null;
        if (definition.attributeType.code === 'STRING') {
            value = item.stringValue;
        }
        if (definition.attributeType.code === 'DATE') {
            value = item.dateValue;
        }
        if (definition.attributeType.code === 'BOOLEAN') {
            value = item.booleanValue;
        }
        if (definition.attributeType.code === 'NUMBER') {
            value = item.numberValue;
        }
        return {
            attribute: item.attributeDefinition as DocumentsAttribute,
            value: value as any,
            valuesRepresentation: [],
        };
    });
}

function getDictionaryItems(spec: DocumentsFilterItemDto[]): DocumentsFilterItem[] {
    const groupIds = [...new Set(spec.map((v) => (v.attributeDefinition as DocumentsAttribute).id))];
    return groupIds.map((typeId) => {
        const item = spec.
            find((v) => (v.attributeDefinition as DocumentsAttribute).id === typeId) as DocumentsFilterItemDto;
        const values = spec.
            filter((v) => (v.attributeDefinition as DocumentsAttribute).id === typeId).
            map((v) => v.dictionaryValueId);
        return {
            attribute: item.attributeDefinition as DocumentsAttribute,
            value: values as any,
            valuesRepresentation: [],
        };
    });
}

function getStaticItems(spec: DocumentsFilterItemDto[]): DocumentsFilterItem[] {
    const groupNames = [...new Set(spec.map((v) => v.externalField))];
    const specItems = groupNames.map((group) => {
        const item = spec.find((v) => v.externalField === group) as DocumentsFilterItemDto;
        const definition = predefinedFilterItems.find((v) => v.externalField === group) as DocumentsStaticAttribute;
        let value = item.stringValue as any;
        let valuesRepresentation: ValuesRepresentation = [];
        if (definition.attributeType.code === 'DATE') {
            value = item.dateValue;
        }
        if (definition.attributeType.code === 'BOOLEAN') {
            value = item.booleanValue;
        }
        if (definition.attributeType.code === 'NUMBER') {
            value = item.numberValue;
        }
        if (definition.attributeType.code === 'DICTIONARY') {
            value = spec.filter((v) => v.externalField === group).map((v) => v.externalId);
            valuesRepresentation = spec.filter((v) => v.externalField === group).
                map((v) => {
                    return { id: v.externalId as number, value: v.stringValue as string };
                });
        }
        return {
            attribute: definition,
            value,
            valuesRepresentation,
        };
    });
    return [...specItems];
}
