
































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Catalogue, createNewCatalogue, CataloguesView } from '../../models/catalogues.model';
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import { DocumentsFilterItem } from '@/models/user-documents.model';
import { DocumentsFilterItemDto } from '@/models/filters.model';
import types from '@/store/types';

const baseClassName = 'elib-catalogue-tree';

type actionModes = 'create' | 'edit' | 'foldAll' | 'fold' | 'delete' | 'copyToClipboard';

@Component({
  name: baseClassName,
  computed: {
    ...mapState('catalogues', ['filterString']),
    ...mapGetters('session', ['hasAuthorityByCode']),
  },
  methods: {
    ...mapActions('catalogues', ['saveNewCatalogue', 'updateCatalogue', 'deleteCatalogue']),
    ...mapActions('documents', ['applyCatalougeFilter', types.FETCH_DOCUMENTS_BY_TYPE_ID]),
    ...mapMutations('catalogues', ['foldCataloguesChildren', 'foldCataloguesChild',
      'updateCatalogueNodeProperty']),
    ...mapMutations('documents', [types.SET_DOCUMENTS_LIST]),
  },
})
export default class CatalogueTree extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  @Prop({ default: () => '' }) private value!: Catalogue;
  @Prop({ default: () => false }) private inSidebar?: boolean;
  @Prop({ default: () => null }) private parent!: Catalogue | null;
  @Prop() private level!: number;
  private self: any;
  private showActions = false;
  private baseClassName = baseClassName;
  private activeCatalogueLevel: Catalogue | null = null;
  private editMode!: actionModes;
  private showModal = false;

  private get className() {
    return (this.incomingClasses || []).reduce(
      (acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private get catalogueActions() {
    return [
      {
        id: 0,
        title: 'Изменить',
        icon: 'elib-icon-pencil',
        handler: this.editCatalogueLevel,
      },
      {
        id: 1,
        title: 'Уровень',
        icon: 'elib-icon-plus',
        handler: this.addNewCatalogueLevel,
      },
      {
        id: 3,
        title: (this.value as any).fold ? 'Свернуть все' : 'Развернуть всё',
        handler: this.foldAllCataloguesLevel,
      },
      {
        id: 4,
        title: 'Код',
        icon: 'elib-icon-copy',
        handler: this.copyIdToClipboard,
      },
      {
        id: 5,
        icon: 'elib-icon-trash',
        handler: () => {
          if (!this.hasEditPermissions()) {
            return;
          }
          this.showModal = true;
        },
      },
    ];
  }

  private get childrenAreVsisble() {
    const catalogue = this.value as CataloguesView;
    return catalogue.fold ||
      (catalogue.founded && (this as any).filterString);
  }

  private get selectedBySearchQuery() {
    return (this as any).filterString && (this as any).value && (this as any).value.founded;
  }

  private addNewCatalogueLevel() {
    if (!this.hasEditPermissions()) {
      return;
    }
    this.editMode = 'create';
    this.activeCatalogueLevel = createNewCatalogue(this.parent as Catalogue);
  }

  private editCatalogueLevel() {
    if (!this.hasEditPermissions()) {
      return;
    }
    this.editMode = 'edit';
    this.activeCatalogueLevel = this.value;
  }

  private foldAllCataloguesLevel() {
    (this as any).foldCataloguesChildren(this.value);
  }

  private foldCataloguesLevel(filter: Catalogue) {
    (this as any).foldCataloguesChild(this.value);

  }

  private handleFilter(filter: Catalogue) {
    if (this.inSidebar) {
      this.self.setDocumentsList([]);
      this.self.fetchDocumentsByTypeId(filter);
    }
  }

  private deleteCatalogueLevel() {
    if (this.value.children && this.value.children.length > 0) {
      this.$notifier({
        title: 'Невозможно удалить уровень!',
        message: 'Нельзя удалить уровень, у которого есть подуровни',
        type: 'error',
      });
      return;
    }
    (this as any).deleteCatalogue({ catalogue: this.value, parent: this.parent });
  }

  private copyIdToClipboard() {
    this.editMode = 'copyToClipboard';
    const elm = document.createElement(`textarea`);
    elm.value = this.value.code.toUpperCase();
    document.body.appendChild(elm);
    elm.select();
    document.execCommand('copy');
    elm.remove();
  }

  private saveChanges(value: Catalogue) {
    switch (this.editMode) {
      case 'create':
        this.addNewCatalogue(value);
        break;
      case 'edit':
        this.updateCatalogueAtStore(value);
        break;
      default:
        break;
    }
    this.activeCatalogueLevel = null;
  }

  private async addNewCatalogue(value: Catalogue) {
    value.id = 0;
    await (this as any).saveNewCatalogue({ catalogue: value, parent: this.value });
  }

  private async updateCatalogueAtStore(value: Catalogue) {
    await (this as any).updateCatalogue({ catalogue: value, parent: this.parent });
  }

  private editCatalogueTitle(title: string) {
    if (this.activeCatalogueLevel) {
      const id = this.activeCatalogueLevel.id;
      (this as any).updateCatalogueNodeProperty({ prop: 'title', value: title, id });
      this.activeCatalogueLevel = null;
    }
  }

  private hasEditPermissions() {
    const hasPermission = (this as any).hasAuthorityByCode(6);
    if (!hasPermission) {
      this.$notifier({
        title: 'Недостаточно прав!', message: 'У вас нет прав для редактирования каталога документов',
        type: 'error',
      });
    }
    return hasPermission;
  }

  private beforeCreate() {
    this.self = this;
  }
}
