














































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { DocumentsContent } from '../../models/user-documents.model';
import { hasAuthoritiesByCode } from '@/commons/authoritiesCheck';
import { MAX_FILE_SIZE_MB } from '@/commons/constants';

const baseClassName = 'elib-input-file';

@Component({
  name: baseClassName,
  computed: {
    ...mapGetters('session', ['hasAuthorityByCode']),
  },
})
export default class InputFile extends Vue {
  @Prop({ default: () => null }) private fileInfo!: { [key: string]: any } | null;
  @Prop({ default: () => null, required: false }) private documentId!: string | number;
  @Prop({ default: () => null, required: false }) private versionNumber!: string | number;
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  @Prop({ default: () => '' }) private placeholder!: string;
  @Prop({ default: () => '' }) private label!: string | number;
  @Prop({ default: () => null }) private b64Content!: DocumentsContent | null;
  @Prop({ default: () => false }) private disabled!: boolean;
  @Prop({ default: () => 0 }) private storeTill?: number;
  @Prop({ default: () => false, required: false}) private required!: boolean;

  @Prop({ default: () => () => null }) private fetchFucntion!: (...args: any) => any | undefined;
  private baseClassName = baseClassName;
  private dragedOver = false;
  private fileIsloading = false;
  private files: any = [];

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private triggerFileInput() {
    (this.$refs.fileInput as HTMLInputElement).click();
  }

  private onFileInput(event: Event) {
    if (!hasAuthoritiesByCode(27)) {
      return;
    }
    const filesList = (event.target as HTMLInputElement).files as FileList;
    this.files.push(...Array.from(filesList));
    if (this.isSizeTooBig()) { return; }

    this.readFilesList(filesList);
  }

  private onDrag(event: DragEvent) {

    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      this.files.push(...Array.from(event.dataTransfer.files));

      if (this.isSizeTooBig()) { return; }

      this.readFilesList(event.dataTransfer.files);
    }
    this.dragedOver = false;
  }
  private isSizeTooBig() {
    let size = 0;
    for (const file of this.files) {
      size += (file.size / 1024 / 1000);
    }
    if (size > MAX_FILE_SIZE_MB) {
      this.$notifier({
        type: 'error',
        title: 'Ошибка',
        message: `Общий размер загружаемых файлов превышает ${MAX_FILE_SIZE_MB} мб. Измените размер вложений и повторите попытку.`,
      });
      return true;
    }
    return false;
  }
  private async readFilesList(filesList: FileList) {
    const fileInfos = [];
    const files = [];
    if (filesList && filesList.length > 0) {
      this.fileIsloading = true;
      for (const file of filesList) {
        const fileInfo = await this.readFileFromInputOrDrop(file);
        fileInfos.push(fileInfo);
        files.push(file);
      }
      this.fileIsloading = false;
      this.$emit('change', { fileInfos, files });
    }
  }

  private readFileFromInputOrDrop(file: File) {
    return new Promise((resolve, rejects) => {
      const reader = new FileReader();
      reader.onload = (ev: ProgressEvent) => {
        if (ev.target && (ev.target as any).result) {
          const data = {
            fileName: file.name,
            mimeType: file.type || this.getMimeTypeBy(file.name),
            size: file.size,
          };
          resolve(data);
        }
      };
      reader.onerror = () => rejects(null);
      reader.readAsDataURL(file);
    });
  }

  private async fetchContent() {
    if (!hasAuthoritiesByCode(19)) {
      return;
    }
    if (!!this.fetchFucntion && !!this.fileInfo) {
      this.fileIsloading = true;
      const content = await this.fetchFucntion() as Blob;
      const url = URL.createObjectURL(content);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${this.fileInfo.fileName}`;
      a.setAttribute('target', '_blank');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      this.fileIsloading = false;
    }
    // if (!this.fileInfo || !content) {
    //   return;
    // }
    // const blob = this.b64toBlob(content, this.fileInfo.mimeType);
    // const url = URL.createObjectURL(blob);
  }

  private b64toBlob(b64Data: string, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  private getMimeTypeBy(filename: string) {
    if (RegExp(/^.*?(doc)$/).test(filename)) {
      return 'application/msword';
    }
    if (RegExp(/^.*?(.docx)$/).test(filename)) {
      return 'application/application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    }
    if (RegExp(/^.*?(.xls)$/).test(filename)) {
      return 'application/vnd.ms-excel';
    }
    if (RegExp(/^.*?(.xls)$/).test(filename)) {
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    if (RegExp(/^.*?(.ppt)$/).test(filename)) {
      return 'application/vnd.ms-powerpoint';
    }
    if (RegExp(/^.*?(pptx)$/).test(filename)) {
      return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
    }
    return 'text/plain';
  }
}
