






























import { Vue, Component, Prop } from 'vue-property-decorator';

const baseClassName = 'elib-modal-delete';

@Component({
  name: baseClassName,
})
export default class DeleteModal extends Vue {
  @Prop({default: () => 'Удалить', required: false, type: String}) private confirmTitle!: string;
  @Prop({default: () => 'Отмена', required: false, type: String}) private closeTitle!: string;
  private baseClassName = baseClassName;
}
