







































import { Vue, Component, Prop } from 'vue-property-decorator';

const baseClassName = 'elib-input-assumption';

@Component({
  name: baseClassName,
})
export default class InputAssumption extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  @Prop({ default: () => '' }) private label!: string;
  @Prop({ default: () => '' }) private value!: string;
  @Prop({ default: () => false }) private disabled!: boolean;
  @Prop({ default: () => [] }) private assamptions!: Array<{ id: string | number, value: any }>;
  @Prop({ default: () => false }) private required!: boolean;
  @Prop({ default: () => false }) private horizontal!: boolean;
  private baseClassName = baseClassName;
  private isFocused = false;
  private selectedItem = null;
  private valueBackup = '';

  private get isValid() {
    return (this.required && this.value !== '') || !this.required;
  }

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private onSelect(item: any) {
    this.selectedItem = item;
    this.valueBackup = item.value;
    this.$emit('select', item);
    this.isFocused = false;
  }

  private onClickOutised(event: Event) {
    const element = event.target as HTMLElement;
    this.isFocused = (this.$refs.section as HTMLElement).contains(element) || element === this.$refs.section;
    if (this.selectedItem === null && this.value.length !== 0 && this.isFocused) {
      this.$emit('input', this.valueBackup);
    }
  }

  private created() {
    this.valueBackup = this.value;
  }

  private mounted() {
    this.onComponentEnter();
  }

  private beforeDestroy() {
    this.onComponentLeave();
  }

  private onComponentEnter() {
    window.addEventListener('click', this.onClickOutised);
  }

  private onComponentLeave() {
    window.removeEventListener('click', this.onClickOutised);
  }
}
