import { TreeViewData } from '@/models/view.model';
import { fetchDictionaryValueById } from '@/controllers/dictionaries.controller';
import { AttributesType, attributeTypes, DocumentsAttribute } from '@/models/document-attributes.model';
import { DocumentsStatus, DocumentClassification, Document, DocumentFormat } from '@/models/user-documents.model';
import { getDateTimeSimpleRepresentation, getExtendedMonthDateRepresentation, downloadBase64File } from '@/commons/dom.helpers';
import { fetchFilterAttributes } from '@/controllers/filters.controller';
import { Catalogue } from '@/models/catalogues.model';
import { $app } from '@/main';
import store from '@/store/store';
import * as types from '@/store/types';
import { filters, staticAttributes } from '@/dictionaries/filters';

export interface DocumentProfileAttribute {
    title: string;
    order?: number;
    key: string;
    type: AttributesType;
    enum?: { [key: string]: any };
    handler?: (...args: any) => any;
}

export const visibleProfileAttributes: DocumentProfileAttribute[] = [

    {
        title: 'Группа компаний',
        order: 0,
        key: 'companyGroup',
        type: attributeTypes[1],
        handler: getValue,
    },
    {
        title: 'Наименование юридического лица',
        order: 1,
        key: 'legalEntity',
        type: attributeTypes[1],
        handler: getValue,
    },
    {
        title: 'ИНН юридического лица',
        order: 2,
        key: 'legalEntity',
        type: attributeTypes[1],
        handler: getINNValue,
    },
    {
        title: 'Раздел',
        order: 3,
        key: 'dossier',
        type: attributeTypes[1],
        handler: getValue,
    },
    {
        title: 'Формат документа',
        order: 4,
        key: 'documentFormat',
        type: attributeTypes[1],
        handler: getValue,
    },
    {
        title: 'Вид',
        order: 5,
        key: 'dossier_kind',
        type: attributeTypes[1],
        handler: getAttributeValueByCode,
    },
    {
        title: 'Место хранения в каталоге',
        order: 6,
        key: 'documentTypeId',
        type: attributeTypes[1],
        handler: getDocumentsTypePath,
    },
    {
        title: 'Краткое наименование',
        order: 7,
        key: 'documentShortName',
        type: attributeTypes[1],
        handler: getValue,
    },
    {
        title: 'Полное наименование',
        order: 8,
        key: 'documentFullName',
        type: attributeTypes[1],
        handler: getValue,
    },
    {
        title: 'Комментарий',
        order: 9,
        key: 'documentComments',
        type: attributeTypes[1],
        handler: getComments,

    },
    {
        title: 'Номер документа',
        order: 10,
        key: 'documentNumber',
        type: attributeTypes[1],
    },
    {
        title: 'Вид документа',
        order: 11,
        key: 'document_kind',
        type: attributeTypes[1],
        enum: DocumentFormat,
        handler: getAttributeValueByCode,
    },
    {
        title: 'Статус',
        order: 12,
        key: 'documentStatus',
        type: attributeTypes[1],
        enum: DocumentsStatus,
    },
    {
        title: 'Уведомления',
        order: 13,
        key: 'notifications',
        type: attributeTypes[1],
        handler: getAttributeValueByCode,
    },
    {
        title: 'Период документа (год)',
        order: 14,
        key: 'document_period_year',
        type: attributeTypes[1],
        handler: getAttributeValueByCode,
    },
    {
        title: 'Период документа (квартал)',
        order: 15,
        key: 'document_period',
        type: attributeTypes[1],
        handler: getAttributeValueByCode,
    },
    {
        title: 'Владелец документа',
        order: 16,
        key: 'document_ownership',
        type: attributeTypes[1],
        handler: getAttributeValueByCode,
    },
    {
        title: 'Срок действия документа',
        order: 17,
        key: 'storeTill',
        type: attributeTypes[1],
        handler: getExtendedDate,
    },
    {
        title: 'Верификация выполнена',
        order: 18,
        key: 'valid',
        type: attributeTypes[1],
        handler: getBooleanType,
    },
    {
        title: 'Дата документа',
        order: 19,
        key: 'documentsDate',
        type: attributeTypes[1],
        handler: getExtendedDate,
    },
    {
        title: 'Дата изменения документа',
        order: 20,
        key: 'updated',
        type: attributeTypes[1],
        handler: getExtendedDate,
    },

    {
        title: 'Дата создания карточки документа',
        order: 21,
        key: 'created',
        type: attributeTypes[1],
        handler: getExtendedDate,
    },
    {
        title: 'Дата удаления',
        order: 22,
        key: 'deleted',
        type: attributeTypes[1],
        handler: getExtendedDate,
    },



];

export const additionalInfoRows: DocumentProfileAttribute[] = [
    {
        title: 'Классификация',
        key: 'documentClassification',
        type: attributeTypes[1],
        enum: DocumentClassification,
    },

    {
        title: 'Версия',
        key: '',
        type: attributeTypes[1],
        handler: getVersionDescription,
    },
    {
        title: 'Наблюдатель',
        key: 'watcher',
        type: attributeTypes[1],
        handler: getWatcherString,
    },
];

export async function getProfileAttribute(source: { [key: string]: any }, attribute: DocumentProfileAttribute) {
    let content = '';
    if (attribute.handler && !source[attribute.key]) {
        return { title: attribute.title, order: attribute.order, content: await attribute.handler(source) };
    }
    if (attribute.handler && source[attribute.key]) {
        return {
            title: attribute.title,
            order: attribute.order,
            content: await attribute.handler(source, source[attribute.key]),
        };
    }
    if (!source[attribute.key] || source[attribute.key] === null) {
        return { title: attribute.title, order: attribute.order, content };
    }
    if (attribute.enum) {
        content = attribute.enum[source[attribute.key]];
        return { title: attribute.title, order: attribute.order, content };
    }
    return { title: attribute.title, order: attribute.order, content: source[attribute.key] };
}

function getVersionDescription(document: Document) {
    return `${document.currentVersionNumber} от ${getDateTimeSimpleRepresentation(document.updated || document.created)}`;
}

function getDocumentsTypePath(document: Document) {

    const node = store.getters[`catalogues/cataloguesTreeViewNodeById`](document.documentTypeId) as TreeViewData;
    if (!node) {
        return;
    }
    return node.path;
}

function getSignatoriesString(document: Document) {
    if (!document.signatories || document === null) {
        return '';
    }
    if (document.signatories.length === 0) {
        return '';
    }
    return (document.signatories as string[]).
        filter((v) => typeof v === 'string' && v.trim().length > 0).
        join(', ');
}

function getWatcherString(document: Document) {
    if (document.watchers) {
        return document.watchers.map((watcher) => watcher.fullName).join(', ');
    }
    return document.watcher && !!document.watcher ? document.watcher.fullName : '---';
}

function getAuthorString(document: Document) {
    if (document.author) {
        return document.author.fullName;
    }
}

function getValue(document: Document, value: any) {
    return value?.value;
}

async function getAttributeValueByCode(this: any, document: Document) {
    const attribute = document.attributes.filter((attr) => attr.code === this.key);
    const data = attribute[0]?.value ? await fetchDictionaryValueById(attribute[0]?.value) : null;
    return data?.value;
}

function getINNValue(document: Document, value: any) {
    return value.description;
}

function getComments(document: Document, value: number) {
    if (!document.documentComments.length) {
        return '';
    }
    return document.documentComments.join(', ');
}

function getExtendedDate(document: Document, value: number) {
    return getExtendedMonthDateRepresentation(value);
}

function getBooleanType(document: Document, value: boolean) {
    return value ? 'Да' : 'Нет';
}

export function csvListFromDocumentsList(documentsList: Document[], docTypeFunction: (...args: any) => any) {
    const data = documentsList.map((doc: Document) => {
        const catalogue = doc.documentTypeId ? docTypeFunction(doc.documentTypeId) : undefined;
        const name = doc.documentName;
        const type = catalogue ? (catalogue as Catalogue).path.replace(/,/gi, ' ') : '';
        const docNumber = doc.documentNumber;
        const classification = doc.documentClassification || '';
        const docDate = new Date(doc.documentsDate || 0).toString();
        const system = doc.outerSystem && !!doc.outerSystem ? doc.outerSystem.name : '';
        const storeTill = new Date(doc.storeTill || 0).toString();
        const status = doc.documentStatus || '';
        const valid = doc.valid;
        const signatures = doc.signatories && !!doc.signatories ?
            doc.signatories.map((v: string | null) => v ? v : '').join(' ') : '';
        const storeOriginalTill = new Date(doc.storeOriginalTill || 0).toString();

        return [name, type, docNumber, classification, docDate,
            system, storeTill, status, valid, signatures, storeOriginalTill];
    });
    const csvContent = `Наименование, тип, номер, Классификация, дата документа,  годен до, статус, валидность, подписанты, оригинал хранится до \n` +
        data.map((v) => v.join(', ')).join('\n');
    downloadBase64File(`data:text/csv;charset=utf-8,\uFEFF ` + csvContent, 'csv-report.csv');
    return csvContent;
}

export function navigateToDocumentsEditForm(document: Document): void {
    const canEdit = store.getters[`session/${types.CHECK_AUTHORITIES_FOR_DOCUMENTS_EDIT}`](document);
    if (!canEdit) {
        $app.$notifier({
            title: 'Недостаточно прав!', message: 'У вас нет прав для редактирования документа',
            type: 'error',
        });
        return;
    }
    if (document.deleted) {
        $app.$notifier({
            title: 'Ошибка!',
            message: `Нельзя редактировать удаленный документ`,
            type: 'error',
        });
        return;
    }
    $app.$router.push({ name: 'edit-document', params: { id: document.documentId.toString() } });
}
