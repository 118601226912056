
















import { Vue, Component, Prop } from 'vue-property-decorator';

const baseClassName = 'elib-icon-arrow-right';

@Component({
  name: baseClassName,
})
export default class IconArrowRight extends Vue {
  @Prop({ default: () => 6 }) private width!: number;
  @Prop({ default: () => 10 }) private height!: number;
  private baseClassName = baseClassName;
}
