import { render, staticRenderFns } from "./RolesAttributesEdit.vue?vue&type=template&id=9756b4fc&scoped=true&"
import script from "./RolesAttributesEdit.vue?vue&type=script&lang=ts&"
export * from "./RolesAttributesEdit.vue?vue&type=script&lang=ts&"
import style0 from "./RolesAttributesEdit.vue?vue&type=style&index=0&id=9756b4fc&lang=scss&scoped=true&"
import style1 from "./RolesAttributesEdit.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9756b4fc",
  null
  
)

export default component.exports