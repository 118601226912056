






























































































import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { HeaderMixin } from '@/components/UserDocuments/EditForm/header.mixin';
import { PredefinedAttributes } from '@/models/document-attributes.model';

@Component
export default class UserDocumentHeader extends mixins(HeaderMixin) {
  private className = 'user-document-header';

  private get companyGroupAttribute() {
    return this.activeDocument.attributes.find((attribute) => attribute.code === PredefinedAttributes.COMPANY_GROUP);
  }

  private get legalEntityAttribute() {
    return this.activeDocument.attributes
      .find((attribute) => attribute.code === PredefinedAttributes.NAME_OF_THE_LEGAL_ENTITY);
  }

  private get innAttribute() {
    return this.activeDocument.attributes
      .find((attribute) => attribute.code === PredefinedAttributes.INN);
  }

  private get dossierAttribute() {
    return this.activeDocument.attributes
      .find((attribute) => attribute.code === PredefinedAttributes.DOSSIER_SECTION);
  }

  private get shortTitleAttribute() {
    return this.activeDocument.attributes
      .find((attribute) => attribute.code === PredefinedAttributes.SHORT_TITLE);
  }

  private get fullTitleAttribute() {
    return this.activeDocument.attributes
      .find((attribute) => attribute.code === PredefinedAttributes.FULL_TITLE);
  }

  private get formatAttribute() {
    return this.activeDocument.attributes
      .find((attribute) => attribute.code === PredefinedAttributes.DOCUMENT_FORMAT);
  }
  private get kindAttribute() {
    return this.activeDocument.attributes
      .find((attribute) => attribute.code === PredefinedAttributes.DOSSIER_KIND);
  }
  private updateDocumentType($event: any) {
    this.updateActiveDocumentProperty({ prop: 'documentTypeId', value: $event.id });
  }
  private udateDocumentFullname($event: { [key: string]: any }) {
    this.updateActiveDocumentProperty({ prop: 'documentFullName', value: $event });
    this.updateActiveDocumentProperty({ prop: 'documentName', value: $event.value });
  }

  private handleCompanyHint() {
    this.$notifier({
      title: 'Отсутствуют необходимые ГК или ЮЛ?',
      message: 'Обратитесь с заявкой на IT Help, для добавления необходимых данных',
      type: 'warn',
    });
  }
}
