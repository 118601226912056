






















import { Vue, Component, Prop } from 'vue-property-decorator';
import { AttributesTypeFilter } from '@/models/document-attributes.model';

const baseClassName = 'elib-dropdown-filters-menu';

@Component({
  name: baseClassName,
})
export default class DropdownFiltersMenu extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  private baseClassName = baseClassName;
  private isFocused = false;

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private mounted() {
    const self = this;
    this.onComponentEnter();

  }

  private beforeDestroy() {
    this.onComponentLeave();
  }

  private onComponentEnter() {
    window.addEventListener('click', this.onClickOutised);
  }

  private onComponentLeave() {
    window.removeEventListener('click', this.onClickOutised);
  }

  private onClickOutised(event: Event) {
    const element = event.target as HTMLElement;
    this.isFocused = (this.$refs.section as HTMLElement).contains(element) ||
      element === this.$refs.section || element.classList[0] === 'elib-input-select__option';
  }

  private onApply(ev: Event) {
    this.$emit('apply');
    this.isFocused = false;
    ev.stopPropagation();
  }

  private onDrop(ev: Event) {
    this.$emit('drop');
    this.isFocused = false;
    ev.stopPropagation();
  }
}
