






















import { Vue, Component, Prop } from 'vue-property-decorator';

const baseClassName = 'elib-icon-document';

@Component({
  name: baseClassName,
})
export default class IconDocument extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  @Prop({ default: () => '' }) private type?: string;
  @Prop({ default: () => 24 }) private width!: number;
  @Prop({ default: () => 30 }) private height!: number;
  @Prop({ default: () => 0 }) private storeTill?: number;
  private baseClassName = baseClassName;

  private get className() {
    return (this.incomingClasses || []).reduce(
      (acc: string, value: string) => `${acc} ${value}`,
      baseClassName,
    );
  }
  private get expired() {
    const today = Date.now().valueOf();
    if (this.storeTill && (this.storeTill < today)) {
      return true;
    }
    return false;
  }
}
