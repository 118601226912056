import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import types from '@/store/types';
import { Document, DocumentFormat, DocumentFileType, DocumentClassification } from '@/models/user-documents.model';
import { Store } from '@/models/commons';
import { PREDEFINED_DICTIONARY_NAMES, DictionaryValueDTO, QueryDictionaryParams } from '@/models/dictionaries.model';
import DocumentAttribute from '@/components/UserDocuments/EditForm/FormAttribute.vue';

@Component({
    components: {
        DocumentAttribute,
    },
    computed: {
        ...mapState('documents', [types.ACTIVE_DOCUMENT]),
        ...mapGetters('dictionaries', [types.DOCUMENT_FORM_DICTIONARIES]),
        ...mapGetters('catalogues', ['catalogueTreeViewData', 'catalogueByNodeId']),
        ...mapGetters('session', [types.HAS_AUTHORITY_BY_CODE]),
    },
    methods: {
        ...mapMutations('documents', ['updateActiveDocumentProperty']),
        ...mapActions('dictionaries', [types.QUERY_DICTIONARY_VALUES]),
        ...mapMutations('documents', [types.CREATE_NEW_DOCUMENT_ON_BASIS]),
    },
})
export class HeaderMixin extends Vue {
    // Vuex bindings
    protected activeDocument!: Document;
    protected documentFormDictionaries!: { [key in PREDEFINED_DICTIONARY_NAMES]: DictionaryValueDTO[] };
    protected updateActiveDocumentProperty!: (value: { prop: string, value: Store | string | number | null }) => void;
    protected queryDictionaryValues!: (params: QueryDictionaryParams) => Promise<DictionaryValueDTO[]>;
    protected catalogueByNodeId!: (id?: number) => Store | null;
    // Rendering props getters
    protected get companyGroup() {
        return this.activeDocument?.companyGroup?.value;
    }
    protected get legalEntity() {
        return this.activeDocument?.legalEntity?.value || '';
    }
    protected get documentINN() {
        return this.activeDocument?.legalEntity?.description || '';
    }
    protected get dossier() {
        return this.activeDocument?.dossier?.title || '';
    }
    protected get documentFormat() {
        return (DocumentFormat as Store)[this.activeDocument?.documentFormat || ''];
    }
    protected get _documentFileType() {
        return this.activeDocument?._documentFileType || 'Digital';
    }
    protected get documentShortName() {
        return this.activeDocument?.documentShortName?.value;
    }
    protected get documentFullName() {
        return this.activeDocument?.documentFullName?.value;
    }
    protected get documentTypeName() {
        return this.catalogueByNodeId(this.activeDocument?.documentTypeId)?.title;
    }
    protected get isNewDocument() {
        return this.activeDocument.documentId === 0 || this.$route.name === 'create-new-document';
    }
    // Dictionaries and items for select of document form
    protected get companyGroupItems() {
        return this.documentFormDictionaries.dictionaryCompanyGroup;
    }
    protected get legalEntitiesItems() {
        return this.activeDocument?.companyGroup?.related || [];
    }
    protected get dossierItems() {
        return this.documentFormDictionaries.dictionaryDossier;
    }
    protected get documentFormatItems() {
        return Object.keys(DocumentFormat).map((item, index) => {
            return { id: index, name: (DocumentFormat as Store)[item], selected: false, item };
        });
    }
    protected get documentShortNameItems() {
        return this.documentFormDictionaries.dictionaryDocumentShortName;
    }
    protected get documentFullNameItems() {
        return this.activeDocument.documentShortName?.related || [];
    }
    protected get documentFileTypeItems() {
        const currentValue = this.activeDocument._documentFileType || 'Digital';
        return Object.keys(DocumentFileType).map((item, index) => {
            return { id: index, value: item === currentValue, label: (DocumentFileType as any)[item], item };
        });
    }
    protected get documentClassificationItems() {
        const activeDocument = this.activeDocument as Document;
        const currentValue = activeDocument.documentClassification || 'ГС РегСеть';
        return Object.keys(DocumentClassification).
            map((item, index) => {
                return {
                    id: index,
                    value: item === currentValue,
                    label: (DocumentClassification as any)[item],
                    item,
                };
            });
    }
    protected onCompanyGroupSelect(value: Store) {
        this.updateActiveDocumentProperty({ prop: 'companyGroup', value });
        this.updateActiveDocumentProperty({ prop: 'legalEntity', value: null });
    }
    protected onShortNameSelect(value: Store) {
        this.updateActiveDocumentProperty({ prop: 'documentShortName', value });
        this.updateActiveDocumentProperty({ prop: 'documentFullName', value: null });
        this.updateActiveDocumentProperty({ prop: 'documentName', value: '' });
    }
}
