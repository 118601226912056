





























import { Component, Vue, Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { SelectOption } from '@/models/view.model';
import { attributeTypes, AttributesType } from '@/models/document-attributes.model';
import { Dictionary, DictionariesValue } from '@/models/dictionaries.model';

const baseClassName = 'elib-document-attributes-filter';

@Component({
  name: baseClassName,
  computed: {
    ...mapState('attributes', ['dictionaryValues']),
  },
})
export default class DocumentAttributesFilter extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  private baseClassName = baseClassName;
  private selectedType: AttributesType | null = null;
  private selectedDictionary: Dictionary | null = null;
  private selectedDictionariesValue: DictionariesValue | null = null;

  private get className() {
    return (this.incomingClasses || []).reduce(
      (acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private get selectedTypeName() {
    return !!this.selectedType ? this.selectedType.title : '';
  }

  private get selectedDictionariesName() {
    return !!this.selectedDictionary ? this.selectedDictionary.title : '';
  }

  private get selectedDictionaryValueName() {
    return !!this.selectedDictionariesValue ? this.selectedDictionariesValue.value : '';
  }

  private get typeOptions(): SelectOption[] {
    return attributeTypes.map((type) => {
      return {
        id: type.id,
        name: type.title,
        selected: false,
        type,
      };
    });
  }

  private get isDictionariesType() {
    return !!this.selectedType && this.selectedType.code === 'dictionary';
  }

  private get dictionaryOptions(): SelectOption[] {
    return ((this as any).dictionaryValues as Dictionary[]).
      map((v) => {
        return {
          id: v.id,
          name: v.title,
          selected: false,
          dictionary: v,
        };
      });
  }

  private get dictionaryValuesList(): SelectOption[] {
    if (this.selectedDictionary === null) {
      return [];
    }
    return (this.selectedDictionary as Dictionary).dictionaryValues.
      map((v) => {
        return {
          id: v.id,
          name: v.title,
          selected: false,
          dictionariesValue: v,
        };
      });
  }

  private onTypeSelect(value: { type: AttributesType }) {
    this.selectedType = value.type;
    this.applyFilter();
  }

  private onDictionarySelect(value: { dictionary: Dictionary }) {
    this.selectedDictionary = value.dictionary;
    this.applyFilter();
  }

  private onDictionaryValueSelect(value: { dictionariesValue: DictionariesValue }) {
    this.selectedDictionariesValue = value.dictionariesValue;
    this.applyFilter();
  }

  private applyFilter() {
    this.$emit('select', {
      type: this.selectedType,
      dictionary: this.selectedDictionary,
      dictionariesValue: this.selectedDictionariesValue,
    });
  }
}
