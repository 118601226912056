
















import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  DocumentsAttribute,
  getEmptyDocumentsAttribute,
} from '@/models/document-attributes.model';

const baseClassName = 'elib-document-attributes-features';

@Component({
  name: baseClassName,
})
export default class DocumentAttributesFeatures extends Vue {
  @Prop() private value!: DocumentsAttribute;
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  private baseClassName = baseClassName;

  private get className() {
    return (this.incomingClasses || []).reduce(
      (acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }
}
