










import { Vue, Component } from 'vue-property-decorator';
import { CreateElement } from 'vue';

const baseClassName = 'elib-notifier';

@Component({
  name: baseClassName,
})
export default class Notifier extends Vue {
    private baseClassName = baseClassName;
    private baseClassNames = [baseClassName];
    private onClose!: () => any;
    private offset!: number;
    private animationDuration!: number;

    private get styleBindings() {
        return {
            top: `${this.offset}px`,
            animationDuration: `${this.animationDuration}ms`,
        };
    }

    private created() {
        setTimeout(() => this.close(), 4000);
    }

    private close() {
        this.baseClassNames.push('closing');
        setTimeout(() => this.onClose(), 2000);
    }
}
