import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import types from '@/store/types';
import { Document, createEmptyDocument } from '@/models/user-documents.model';
import { DictionaryDTO } from '@/models/dictionaries.model';
import { Store } from '@/models/commons';
import { DocumentsAttribute } from '@/models/document-attributes.model';
import { QueryParams } from '@/commons/axios.config';
import { User } from '@/models/users.model';
import { ExternalSystem } from '@/models/external-systems.model';
import { Catalogue } from '@/models/catalogues.model';
import { Route } from 'vue-router';

@Component({
    computed: {
        ...mapState('documents', [types.ACTIVE_DOCUMENT]),
        ...mapState('systems', ['externalSystemList']),
        ...mapState('catalogues', ['cataloguesList']),
        ...mapState('users', ['usersList']),
        ...mapState('dictionaries', [types.DICTIONARIES]),
        ...mapGetters('documents', [types.DOCUMENT_HAS_CHANGES]),
    },
    methods: {
        ...mapMutations('documents', [types.UPDATE_ACTIVE_DOCUMENTS_PROP, types.CREATE_NEW_DOCUMENT_ON_BASIS]),
        ...mapActions('dictionaries', [types.FETCH_DICTIONARIES_INFO, types.QUERY_DICTIONARY_VALUES]),
        ...mapActions('documents', [types.FETCH_REQUIRED_DOCUMENT_ATTRIBUTES, types.FETCH_DOCUMENT_BY_ID,
        types.DELETE_DOCUMENT]),
        ...mapActions('users', [types.FETCH_USERS_ACTION]),
        ...mapActions('systems', ['getExternalSystems']),
        ...mapActions('catalogues', ['getCatalogues']),
    },
})
export class FormMixin extends Vue {
    protected activeDocument!: Document;
    protected externalSystemList!: ExternalSystem[];
    protected cataloguesList!: Catalogue[];
    protected usersList!: User[];
    protected dictionaries!: DictionaryDTO[];
    protected documentHasChanges!: boolean;
    protected setActiveDocument!: (doc: Document) => void;
    protected fetchDictionariesInfo!: () => Promise<DictionaryDTO[]>;
    protected fetchRequiredDocumentAttributes!: () => Promise<DocumentsAttribute[]>;
    protected fetchUsersAction!: (param?: QueryParams) => Promise<User[]>;
    protected getCatalogues!: (param?: QueryParams) => Promise<Catalogue[]>;
    protected getExternalSystems!: (param?: QueryParams) => Promise<ExternalSystem[]>;
    protected updateActiveDocumentProperty!: (props: { prop: string, value: any }) => void;
    protected fetchDocumentById!: (id: number | string) => Promise<Document>;
    protected deleteDocument!: (doc: Document) => Promise<boolean>;
    protected loading = false;
    protected showLeaveModal = false;
    protected nextRouteAction: any | null  = null;

    protected get documentId(): number | string {
        return this.$route.params.id || this.activeDocument?.documentId;
    }

    protected get newDocumentsMode() {
        return this.activeDocument && (this.activeDocument.documentId === 0 || this.$route.name === 'create-new-document');
    }

    protected created() {
        this.loading = true;
        this.fetchFormRequiredData()
            .then(() => this.formDataInitialize())
            .catch((err) => this.onError(err))
            .finally(() => this.loading = false);
    }

    protected async formDataInitialize() {
        this.documentId ? await this.fetchDocumentById(this.documentId)
        : this.activeDocument ?
        await Promise.resolve(this.activeDocument) : this.setActiveDocument(createEmptyDocument());
        if (this.newDocumentsMode) {
            this.updateActiveDocumentProperty({ prop: 'documentName', value: `${this.activeDocument.documentName} (Копия)` });
            this.updateActiveDocumentProperty({ prop: 'created', value: 0 });
            this.updateActiveDocumentProperty({ prop: 'documentId', value: 0 });
            this.updateActiveDocumentProperty({ prop: 'contents', value: [] });
        }
        this.checkAccess();
        await this.fetchRequiredDocumentAttributes();
    }

    protected async fetchFormRequiredData() {
        if (!this.dictionaries || !this.dictionaries.length) {
            await this.fetchDictionariesInfo();
        }
        await this.getCatalogues({all: true});
        if (!this.usersList.length) {
            await this.fetchUsersAction();
        }
        if (!this.externalSystemList) {
            await this.getExternalSystems();
        }
    }

    protected onError(err: Store) {
        this.loading = false;
        this.$notifier({
            title: 'Ошибка!',
            message: `Возникла ошибка при получении документа`,
            type: 'error',
        });
        this.$router.push({ name: 'documents' });
    }

    protected checkAccess() {
        if (this.activeDocument && this.activeDocument.deleted) {
            this.$notifier({
                title: 'Ошибка!',
                message: `Нельзя редактировать удаленный документ`,
                type: 'error',
            });
            this.$router.push({ name: 'documents' });
        }
    }

    protected async removeDocument() {
        await this.deleteDocument(this.activeDocument);
        this.$router.push({ name: 'documents' });
    }

    protected documentIsValid() {
        const mainForm = this.$refs.mainForm as HTMLFormElement;
        if (!mainForm.checkValidity()) {
            this.$notifier({title: 'Ошибка формы!', message: 'Не заполнены обязательны поля', type: 'error'});
            this.loading = false;
            return false;
        }
        if (!this.activeDocument.contents || !this.activeDocument.contents.length) {
            this.$notifier({
                title: 'Ошибка формы!',
                message: 'Незаполнено обязательное поле - Вложение',
                type: 'error',
            });
            this.loading = false;
            return false;
        }
        return true;
    }

    protected beforeRouteLeave(to: Route, from: Route, next: (...args: any) => any) {
        if (this.documentHasChanges && !this.nextRouteAction) {
            this.nextRouteAction = next;
            this.showLeaveModal = true;
            return;
        }
        next();
    }

    protected skipDocumentChanges() {
        this.showLeaveModal = false;
        if (typeof this.nextRouteAction === 'function') {
            this.nextRouteAction();
        }
    }

    protected continueDocumentEditing() {
        this.nextRouteAction = null;
        this.showLeaveModal = false;
    }
}
