import Vue from 'vue';
import InputSearch from '@/components/Inputs/InputSearch.vue';
import InputDefault from '@/components/Inputs/Input__default.vue';
import InputCheckbox from '@/components/Inputs/Input__checkbox.vue';
import InputAssumption from '@/components/Inputs/Input__assumption.vue';
import InputTextarea from '@/components/Inputs/Input__textarea.vue';
import InputRadio from '@/components/Inputs/Input__radio.vue';
import InputSelect from '@/components/Inputs/Select.vue';
import InputMultiselect from '@/components/Inputs/Input__multiselect.vue';
import InputAutocomplete from '@/components/Inputs/Input__autocomplete.vue';
import InputAutocompleteFiles from '@/components/Inputs/Input__autocomplete-files.vue';
import InputAutocompleteTree from '@/components/Inputs/Input__autocomplete-tree.vue';
import InputFile from '@/components/Inputs/Input__file.vue';
import InputClassification from '@/components/Inputs/InputClassification.vue';
import InputColors from '@/components/Inputs/Input__colors.vue';
import InputCategories from '@/components/Inputs/Input__categories.vue';
import Datepicker from '@/components/Inputs/Datepicker.vue';
import InputDatepickerRange from '@/components/Inputs/Input__datepicker-range.vue';
import AutocompleteSelectable from '@/components/Inputs/AutocompleteSelectable.vue';
import InputBoolean from '@/components/Inputs/Input__boolean.vue';
import InputMultiselectFunctional from '@/components/Inputs/Input__multiselect__functional.vue';
import InputAutocompleteFunctional from '@/components/Inputs/Input__autocomplete__functional.vue';
import InputCheckboxDictionary from '@/components/Inputs/InputCheckboxDictionary.vue';


export default (vue: typeof Vue, options: { [key: string]: any }): void => {
  Vue.component('elib-input-search', InputSearch);
  Vue.component('elib-input-default', InputDefault);
  Vue.component('elib-input-checkbox', InputCheckbox);
  Vue.component('elib-input-assumption', InputAssumption);
  Vue.component('elib-input-textarea', InputTextarea);
  Vue.component('elib-input-radio', InputRadio);
  Vue.component('elib-input-select', InputSelect);
  Vue.component('elib-input-multiselect', InputMultiselect);
  Vue.component('elib-input-autocomplete', InputAutocomplete);
  Vue.component('elib-input-autocomplete-tree', InputAutocompleteTree);
  Vue.component('elib-input-file', InputFile);
  Vue.component('elib-input-classification', InputClassification);
  Vue.component('elib-input-colors', InputColors);
  Vue.component('elib-input-categories', InputCategories);
  Vue.component('elib-input-datepicker', Datepicker);
  Vue.component('elib-input-datepicker-range', InputDatepickerRange);
  Vue.component('elib-input-assumption-select', AutocompleteSelectable);
  Vue.component('elib-input-boolean', InputBoolean);
  Vue.component('elib-input-multiselect-functional', InputMultiselectFunctional);
  Vue.component('elib-input-autocomplete-functional', InputAutocompleteFunctional);
  Vue.component('elib-input-autocomplete-files', InputAutocompleteFiles);
  Vue.component('elib-input-dictionary-checkbox', InputCheckboxDictionary);
};
