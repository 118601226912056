








































import { DictionaryValueDTO } from '@/models/dictionaries.model';
import { Vue, Component, Prop } from 'vue-property-decorator';

const baseClassName = 'elib-input-dictionary-checkbox';

@Component({
  name: baseClassName,
})
export default class InputDictionaryCheckbox extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  @Prop({ default: () => '' }) private label!: string;
  @Prop({ default: () => '' }) private name!: string;
  @Prop({ default: () => '' }) private value!: string | number;
  @Prop({ default: () => [] }) private items!: DictionaryValueDTO[];
  @Prop({ default: () => false }) private disabled!: boolean;
  @Prop({ default: () => false }) private horizontal!: boolean;
  @Prop({ default: () => false }) private required!: boolean;
  @Prop({ default: () => false }) private useId!: boolean;
  @Prop({ default: () => false }) private fullWidth!: boolean;
  private baseClassName = baseClassName;

  private get className() {
    return (this.incomingClasses || []).reduce(
      (acc: string, value: string) => `${acc} ${value}`,
      baseClassName,
    );
  }

  private get isValid() {
    return !this.required || (this.required && !!this.value);
  }

  private isChecked(item: DictionaryValueDTO) {
    return item.value === this.value || (this.useId ? item.id === this.value : false);
  }
}
