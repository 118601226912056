



















































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { mapActions, mapMutations, mapState } from 'vuex';
import types from '@/store/types';
import { fetchDictionaryValueById, fetchDictionaryValuesById, fetchRelatedDictionaryValuesById } from '@/controllers/dictionaries.controller';
import { DictionaryValueDTO, QueryDictionaryParams } from '@/models/dictionaries.model';
import { FieldsDictionary } from '@/dictionaries/fields';
import { AttributesTypeEnum } from '@/models/document-attributes.model';
import { DocumentCustomAttribute, Document } from '@/models/user-documents.model';
import { Func, Store } from '@/models/commons';

@Component({
  name: 'document-attribute',
  computed: {
    ...mapState('documents', [types.ACTIVE_DOCUMENT]),
  },
  methods: {
    ...mapMutations('documents', ['updateDocumentActiveAttribute', 'deleteCustomAttributeFromDocument']),
    ...mapActions('documents', ['updateActiveDocumentHashSum']),
  },
})
export default class DocumentAttribute extends Vue {
  @Prop({ type: Object, required: true }) protected attribute!: DocumentCustomAttribute;
  @Prop({ type: Boolean, required: false, default: () => false }) protected horizontal!: boolean;
  @Prop({ default: () => false }) protected disabled!: boolean;
  @Prop({ default: () => false }) protected readonly!: boolean;
  @Prop({ default: () => false }) protected required!: boolean;
  @Prop({ default: () => '' }) protected placeholder!: string;
  @Prop({ default: () => '' }) protected documentTypeId!: string;
  protected className = 'document-attribute';
  protected activeDocument!: Document;
  protected updateDocumentActiveAttribute!: (payload: DocumentCustomAttribute) => void;
  protected deleteCustomAttributeFromDocument!: (payload: DocumentCustomAttribute) => void;
  protected dictionaryValues: DictionaryValueDTO[] = [];
  protected dictionaryValue: DictionaryValueDTO | null = null;
  protected updateActiveDocumentHashSum!: () => void;

  protected get value() {
    return this.isDictionary ? this.dictionaryValue?.value :
      this.isPathValue ? this.pathValue : this.attribute.value;
  }

  protected get pathValue() {
    return this.isPathValue && this.masterValue ?
      this.valuePath.reduce((acc, path) => acc ? acc[path] : null, this.masterValue) : null;
  }

  protected get label() {
    return this.attribute.attributeDefinition.title;
  }

  protected get isDictionary() {
    return this.attribute.attributeDefinition &&
      this.attribute.attributeDefinition.dictionary &&
      this.attribute.attributeDefinition.attributeType.code === AttributesTypeEnum.DICTIONARY;
  }

  protected get isPathValue() {
    return this.fieldParams && this.fieldParams.path &&
      this.fieldParams.path.length && this.fieldParams.dependsOn;
  }

  protected get dictionary() {
    return this.attribute.attributeDefinition.dictionary;
  }

  protected get typeCode() {
    return this.attribute.attributeDefinition.attributeType.code.toLowerCase();
  }

  protected get requiredAttribute() {
    return this.attribute.attributeDefinition.required || this.required;
  }

  protected get dictionaryCode() {
    return this.attribute.attributeDefinition.dictionary?.code || '';
  }

  protected get fieldParams(): Store {
    const code = this.isDictionary ? this.dictionaryCode : this.attribute.code;
    return FieldsDictionary[code];
  }

  protected get isSelect() {
    return this.isDictionary
      && (!this.fieldParams || this.fieldParams.component === 'SELECT');
  }

  protected get isCheckbox() {

    return this.isDictionary && this.fieldParams && this.fieldParams.component === 'CHECKBOX';
  }
  protected get isAutocomplete() {
    return this.isDictionary && this.fieldParams && this.fieldParams.component === 'AUTOCOMPLETE';
  }

  protected get masterCode() {
    return this.fieldParams && this.fieldParams.dependsOn;
  }

  protected get masterValue(): Store | null | undefined {
    return this.masterCode &&
      this.activeDocument.attributes.find((attrib) => attrib.code === this.masterCode);
  }

  protected get valuePath(): string[] {
    return this.isPathValue ? this.fieldParams.path : [];
  }

  protected get queryHandler() {
    return this.masterCode ? this.queryRelatedValues : this.queryDictionaryValues;
  }

  protected get dictionaryHandler(): Func {
    return this.fieldParams && this.fieldParams.handler ? this.fieldParams.handler : () => null;
  }

  protected async onDictionaryValueChange(item: DictionaryValueDTO) {
    const payload = { ...this.attribute, value: item.id, dictionaryValue: item };
    this.updateDocumentActiveAttribute(payload);
    this.dictionaryHandler(payload);
    this.dictionaryValue = item || null;
  }

  protected async queryDictionaryValues(query?: string) {
    if (this.dictionary?.id) {
      this.dictionaryValues = await fetchDictionaryValuesById(this.dictionary.id,
        { query, documentTypeId: this.documentTypeId, limit: 30 });
    }
  }

  protected async queryRelatedValues(query?: string) {
    const dictionaryId = this.dictionary?.id;
    const masterValueId = this.masterValue?.value;
    const documentTypeId = this.documentTypeId;
    if (dictionaryId && masterValueId) {
      this.dictionaryValues = await fetchRelatedDictionaryValuesById(dictionaryId,
        parseInt(masterValueId, 10), { query, documentTypeId });

      this.dictionaryValue = this.dictionaryValues && this.dictionaryValues.length ?
        this.intializeDependableValue(this.dictionaryValues[0]) : null;

    }
    if (this.dictionaryValue) {
      const correctValue = this.dictionaryValues.filter((dicValue) => dicValue.id === (this as any).dictionaryValue.id);
      if (!correctValue.length) {

        this.dictionaryValue = this.dictionaryValues && this.dictionaryValues.length ?
          this.intializeDependableValue(this.dictionaryValues[0]) : this.dictionaryValue;
      }
    }
  }

  @Watch('masterValue', { deep: true, immediate: true })
  protected onMasterValueChange(val: Store, oldVal: Store) {
    const changed = val && val.value && (!oldVal || (val.value !== oldVal.value));
    if (changed) {
      this.queryRelatedValues();
    }
  }

  protected created() {
    this.intializeDictionaryAttribute();
  }

  protected async intializeDictionaryAttribute() {
    if (!this.isDictionary) {
      return;
    }
    if (this.attribute.title && this.attribute.identity) {
      this.dictionaryValue = {
        id: this.attribute.value as number,
        value: this.attribute.identity as string,
        title: this.attribute.title,
        description: this.attribute.description as string,
      };
      this.updateDocumentActiveAttribute({ ...this.attribute, dictionaryValue: this.dictionaryValue });
    }
    if (this.attribute.value && !this.attribute.title && !this.attribute.identity) {
      this.dictionaryValue = await fetchDictionaryValueById(parseInt(this.attribute.value, 10)) || null;
      this.updateDocumentActiveAttribute({ ...this.attribute, dictionaryValue: this.dictionaryValue });
    }
    if (this.dictionary && this.dictionary.id && !this.masterCode) {
      this.queryDictionaryValues();
    }
    if (this.dictionary && this.dictionary.id && this.masterCode) {
      this.queryRelatedValues();
    }
    this.updateActiveDocumentHashSum();
  }

  protected intializeDependableValue(value: DictionaryValueDTO) {
    this.onDictionaryValueChange(value);
    return value;
  }
}
