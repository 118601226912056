



























import { Vue, Component, Prop, Model } from 'vue-property-decorator';

const baseClassName = 'elib-input-default';

@Component({
  name: baseClassName,
})
export default class InputDefault extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  @Prop({ default: () => '' }) private label!: string | number;
  @Model('change', { type: [String, Number] }) private value!: string;
  @Prop({ default: () => false }) private disabled!: boolean;
  @Prop({ default: () => false }) private readonly!: boolean;
  @Prop({ default: () => false }) private required!: boolean;
  @Prop({ default: () => '' }) private placeholder!: string;
  @Prop({ default: () => false }) private horizontal!: boolean;
  @Prop({ default: () => false }) private tooltip!: boolean;
  @Prop({ default: () => 'text' }) private inputType!: string;
  @Prop({ default: () => '' }) private defaultValue!: string;
  private baseClassName = baseClassName;
  private focused = false;

  get isValid() {
    return this.required && typeof this.value === 'string' ? this.value.trim() !== '' : true;
  }

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }
}
