












import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';
import AuthForm from '@/components/TheAuthForm.vue';
import Logo from '@/components/TheLogo.vue';

const baseClassName = 'elib-auth-page';

@Component({
  name: baseClassName,
  components: {
    'elib-logo': Logo,
    'elib-auth-from': AuthForm,
  },
  beforeRouteEnter(to: Route, from: Route, next: (...args: any) => any) {
    next((vm: Vue) => {
      vm.$store.commit('documents/resetState');
      vm.$store.commit('catalogues/resetState');
      vm.$store.commit('session/resetState');
      vm.$store.commit('filters/resetState');
    });
  },
})
export default class Home extends Vue {
  private baseClassName = baseClassName;
}
