import Vue from 'vue';
import Component from 'vue-class-component';
import { mapActions, mapMutations, mapState } from 'vuex';
import { Document, DocumentsFilterItem } from '@/models/user-documents.model';
import { getFilterItems, valueIsProvided, getComputedFilterCode, createFilterDTO,
    setFilterValues, setFilterItemsValue } from './filters.module';
import types from '@/store/types';
import { QueryParams } from '@/commons/axios.config';
import { DocumentsFilterDto, DocumentsFilterItemDto } from '@/models/filters.model';
import { ProvideReactive } from 'vue-property-decorator';

@Component({
    computed: {
        ...mapState('filters', ['activeFilter']),
        ...mapState('documents', [types.FILTERS_LIST]),
    },
    methods: {
        ...mapActions('documents', [types.FETCH_DOCUMENTS_BY_FILTERS, types.GET_USER_DOCUMENTS]),
        ...mapMutations('documents', [types.SET_FILTERS_LIST]),
        ...mapActions('filters', [types.SAVE_FILTER]),
        ...mapMutations('filters', [types.SET_ACTIVE_FILTER]),
    },
})
export default class FilterBarMixin extends Vue {
    protected activeFilter!: DocumentsFilterDto | null;
    protected filtersList!: DocumentsFilterItemDto[];
    @ProvideReactive('scopedFilterItems') protected filterItems: DocumentsFilterItem[] = [];
    @ProvideReactive('scopedFilters') protected filters: DocumentsFilterItemDto[] = [];
    protected readonly filter!: DocumentsFilterDto | null;
    protected name: string = '';
    protected loading = true;
    protected getUserDocuments!: (params?: QueryParams) => Promise<Document[]>;
    protected fetchDocumentsByFilters!: (specification: DocumentsFilterItemDto[]) => Promise<Document[]>;
    protected saveFilter!: (filter: DocumentsFilterDto) => Promise<DocumentsFilterDto | undefined>;
    protected setActiveFilter!: (filter: DocumentsFilterDto | null) => void;
    protected setFiltersList!: (filters: DocumentsFilterItemDto[]) => void;

    protected async reset() {
        this.setFiltersList([]);
        this.getUserDocuments();
        this.setActiveFilter(this.activeFilter ? {...this.activeFilter, specification: []} : null);
        this.onEnter();
        this.filters = [];
        this.filterItems = [];
    }
    protected created() {
        this.onEnter();
    }
    protected async onEnter() {
        this.loading = true;
        this.filterItems = await getFilterItems({ limit: 1000, offset: 0 });
        this.filterItems = setFilterItemsValue(this.filtersList, this.filterItems);
        this.name = this.activeFilter?.name || '';
        this.onActiveFilterChange();
        this.loading = false;
    }
    protected onItemChange(value: DocumentsFilterItemDto | DocumentsFilterItemDto[], code: string) {
        if (value instanceof Array) {
            this.filters = [...this.filters.filter((item) => code !== getComputedFilterCode(item)), ...value]
                .filter(valueIsProvided);
            return;
        }
        this.filters = [...this.filters.filter((item) => getComputedFilterCode(item) !== code), value]
            .filter(valueIsProvided);
    }
    protected applyFilters() {
        if (this.filters.length) {
            this.fetchDocumentsByFilters(this.filters);
            this.setFiltersList(this.filters);
            return;
        }
        this.getUserDocuments({ offset: 0 });
    }
    protected saveFilterChanges() {
        if (this.filters.length && this.name) {
            const filter = JSON.parse(JSON.stringify(this.activeFilter));
            const dto = this.activeFilter ? filter : createFilterDTO(filter);
            dto.name = this.name;
            dto.specification = this.filters;
            this.saveFilter(dto);
        }
    }
    protected onActiveFilterChange() {
        if (this.activeFilter && this.activeFilter.specification) {
            this.filters = this.activeFilter.specification;
            this.filterItems = setFilterValues(this.activeFilter, this.filterItems);
            this.fetchDocumentsByFilters(this.activeFilter.specification);
        }
    }
}
