import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import { $app } from '@/main';
import { AUTH_TOKEN_HEADER, AUTH_TOKEN_NAME } from './constants';
import { router } from '@/router';
import { AUTH_ROUTE_NAME } from '@/commons/constants';

interface QueryParams {
    limit?: number;
    offset?: number;
    query?: string;
    withDeleted?: boolean;
    documentTypeId?: number | string;
    masterDictionaryValueId?: number | string;
    all?: boolean;
}

type QueryTuple = [string, any];

function getQueryParams(params: { [key: string]: any } | undefined) {
    if (!params) {
        return '';
    }
    const keys = Object.keys(params);
    return keys.
        filter((key) => params[key] !== undefined &&
            params[key] !== null && params[key] !== '').
        reduce((acc: string, key: string, index: number) => {
            const param = index ? `&${key}=${params[key]}` : `?${key}=${params[key]}`;
            return acc + param;
        }, '');
}

function getUrlString(url: string, params: QueryParams | undefined) {
    const queryString = getQueryParams(params);
    return `/${url}${queryString}`;
}

function getUrlStringFromTuple(url: string, params: QueryTuple[]) {
    return '/' + url + params.reduce((acc: string, value: QueryTuple, index: number) => {
        return index ? `&${value[0]}=${value[1]}` : `?${value[0]}=${value[1]}`;
    }, '');
}

const $HTTPServicesClient = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? '/elib' : '',
    headers: {
        [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME),
    },
    validateStatus: (status) => {
        return status >= 200 && status < 400;
    },
});

$HTTPServicesClient.interceptors.response.use(
    (response: AxiosResponse<any>) => {
        return response;
    },
    (error: AxiosError) => {
        let title = `${error.response && error.response.status} ${error.response && error.response.statusText}`;
        let message = error.response && error.response.data &&
            error.response.data.error && error.response.data.error.text;
        message = message || (error.response && error.response.data && error.response.data.message) || error.message;
        if ((error.response && error.response.status) === 401 && router.currentRoute.name !== AUTH_ROUTE_NAME) {
            router.push({name: AUTH_ROUTE_NAME});
        }
        if ((error.response && error.response.status) === 401 && router.currentRoute.name === AUTH_ROUTE_NAME) {
            title = 'Ошибка авторизации!';
            message = error.response?.data?.message || 'Неправильно введен логин или пароль';
        }
        if (message.indexOf('Users in role with id') > -1) {
            message = 'Невозможно удалить роль, так как имеются связанные с ней пользователи';
        }
        $app.$notifier({
            type: 'error',
            title,
            message,
        });
        return Promise.reject(error);
    },
);

export { $HTTPServicesClient, getUrlString, getQueryParams, QueryParams, getUrlStringFromTuple, QueryTuple };
