import { render, staticRenderFns } from "./Icon__required.vue?vue&type=template&id=229db2f2&scoped=true&"
import script from "./Icon__required.vue?vue&type=script&lang=ts&"
export * from "./Icon__required.vue?vue&type=script&lang=ts&"
import style0 from "./Icon__required.vue?vue&type=style&index=0&id=229db2f2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "229db2f2",
  null
  
)

export default component.exports