import { DirectiveOptions, VNode } from 'vue';
import { DirectiveBinding } from 'vue/types/options';

interface DirectiveInstance {
    el: HTMLElement;
    eventName: string;
    handler: () => any;
    middleware: (...args: any) => any;
}

const directiveInstances: DirectiveInstance[] = [];


export const directive: DirectiveOptions = {
    bind: (el: HTMLElement, bindings: DirectiveBinding, newNode: VNode, oldVNode: VNode) => {
        const instance = createInstance(el, bindings.value);
        directiveInstances.push(instance);
        setTimeout(addEventListener, 0, instance);
    },
};

function createInstance(el: HTMLElement, handler: () => any): DirectiveInstance {
    if (!bindingsValueIsValid(handler)) {
        throw new Error(`Value has to be function for click-outside directive`);
    }
    Object.defineProperty(handler, 'elib_name', {
        value: el.className,
        configurable: false,
        writable: false,
    });
    return {
        el,
        eventName: 'click',
        handler,
        middleware: (isClickOutside: any) => {
            return isClickOutside;
        },
    };
}

function addEventListener(instance: DirectiveInstance) {
    document.addEventListener('click', (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        const trackedEl = instance.el;
        const isClickOutside = target !== trackedEl && !trackedEl.contains(target);
        if (!isClickOutside) {
            return;
        }
        if (instance.middleware(instance.eventName, instance.el)) {
            instance.handler();
        }
    });
}

function bindingsValueIsValid(value: () => any): boolean {
    return value instanceof Function;
}
