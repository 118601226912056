


























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { SelectOption } from '@/models/view.model';
import { Store } from '@/models/commons';

const baseClassName = 'elib-input-select';

@Component({
  name: baseClassName,
})
export default class InputSelect extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  @Prop({ default: () => '' }) private label!: string;
  @Prop({ default: () => '' }) private placeholder!: string;
  @Prop({ default: () => '' }) private value!: string;
  @Prop({ default: () => 'text' }) private type!: string;
  @Prop({ default: () => false }) private disabled!: boolean;
  @Prop({ default: () => false }) private readonly!: boolean;
  @Prop({ default: () => [] }) private options!: SelectOption[];
  @Prop({ default: () => false }) private horizontal!: boolean;
  @Prop({ default: () => false }) private required!: boolean;
  private baseClassName = baseClassName;
  private isFocused = false;
  private query = '';
  private currentValue = '';
  private selectedItem: Store | null | undefined = null;


  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private get isValid() {
    return (this.required && this.value !== '') || !this.required;
  }

  private onSelect(option?: SelectOption) {
    this.query = option?.name || option?.value;
    this.currentValue = option?.name || option?.value;
    this.selectedItem = option;
    this.$emit('selected', option);
    this.isFocused = false;
  }

  private onInput($event: any) {
    this.query = $event.target?.value;
    this.selectedItem = null;
    this.$emit('input', $event.target.value);
  }

  private created() {
    this.currentValue = this.value;
    this.query = this.value;
  }

  private mounted() {
    this.onComponentEnter();
  }

  @Watch('value', { immediate: true, deep: true })
  private onPropValueChange(val: string, oldVal: string) {
    this.query = val;
    this.currentValue = this.value;
  }

  private beforeDestroy() {
    this.onComponentLeave();
  }

  private onComponentEnter() {
    window.addEventListener('click', this.onClickOutside);
  }

  private onComponentLeave() {
    window.removeEventListener('click', this.onClickOutside);
  }

  private onClickOutside(event: Event) {
    const element = event.target as HTMLElement;
    this.isFocused = (this.$refs.mainContainer as HTMLElement).contains(element) ||
      element === this.$refs.mainContainer;
    if (this.selectedItem === null && this.value.length !== 0 && this.isFocused) {
      this.$emit('input', this.value);
    }
    if (this.selectedItem === null && !this.options.length) {
      this.query = this.currentValue.trim();
      this.$emit('input', '');
    }
  }
}
