import { User } from '@/models/users.model';

export const months = ['Январь',
    'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
    'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

export function windowBottomIsReached() {
    return document.body.scrollHeight === document.body.scrollTop + window.innerHeight;
}

export function getDateTimeSimpleRepresentation(value: number) {
    const dateValue = new Date(value);
    if (isNaN(dateValue.valueOf())) {
        return '';
    }
    const dateString = dateValue.getDate() < 9 ? `0${dateValue.getDate()}` : dateValue.getDate();
    const monthString = dateValue.getMonth() < 9 ? `0${dateValue.getMonth() + 1}` : dateValue.getMonth() + 1;
    const yearString = dateValue.getFullYear().toString();
    const hours = dateValue.getHours() < 9 ? `0${dateValue.getHours()}` : dateValue.getHours();
    const minutes = dateValue.getMinutes() < 9 ? `0${dateValue.getMinutes()}` : dateValue.getMinutes();
    return `${dateString}.${monthString}.${yearString} ${hours}:${minutes}`;
}

export function getExtendedMonthDateRepresentation(value: number, literalMonth = false) {
    const dateValue = new Date(value);
    if (isNaN(dateValue.valueOf())) {
        return '';
    }
    const dateString = dateValue.getDate() < 9 ? `0${dateValue.getDate()}` : dateValue.getDate();
    const monthString = months[dateValue.getMonth()];
    const yearString = dateValue.getFullYear().toString();
    return `${dateString} ${monthString} ${yearString}`;
}

export function getUserShortFullName(user: User) {
    if (!user || user === null) {
        return '';
    }
    if (!user.fullName) {
        return user.login;
    }
    const nameArray = user.fullName.split(' ').filter((v) => v.trim().length > 0);
    if (!nameArray.length) {
        return user.login;
    }
    if (nameArray.length === 3) {
        return `${nameArray[0]} ${nameArray[1][0]}. ${nameArray[2][0]}.`;
    }
    return user.fullName;
}

export function parseBooleanAsString(value: string) {
    return value === 'true' ? true : false;
}

export function downloadBase64File(content: string, fileName: string) {
    const downloadLink = document.createElement('a');
    // const url = URL.createObjectURL(blob);
    downloadLink.href = content;
    downloadLink.download = 'csv-report.csv';
    downloadLink.target = '_self';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    setTimeout(() => {
        document.body.removeChild(downloadLink);
        // URL.revokeObjectURL(url);
    }, 0);
}
