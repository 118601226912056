import { User } from '@/models/users.model';
import { $HTTPServicesClient as axiosClient, getUrlString, QueryParams   } from '@/commons/axios.config';
import { AUTH_TOKEN_HEADER, AUTH_TOKEN_NAME } from '@/commons/constants';

interface AuthResponse {
  data: User[];
}

export async function fetchUsers(params: QueryParams) {
  let users;
  try {
    const request = await axiosClient.get('/users', {
      params: {...params, sortColumn: 'fullName' },
      headers: {
        [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME),
      },
    });
    users = (request.data as AuthResponse).data as User[];
    users.forEach((user) => setDefaultParamsToUser(user));
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
  return users;
}

export async function fetchHdfsUsers(token: string, params: QueryParams) {
  let users;
  try {
    const url = getUrlString('users', params) + `&hdfsUser=true`;
    const request = await axiosClient.get(url, {headers: {'x-auth-token': token}});
    users = (request.data as AuthResponse).data as User[];
    users.forEach((user) => setDefaultParamsToUser(user));
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
  return users;
}

export async function fetchUserById(id: number) {
  try {
    const request = await axiosClient.get(`/users/${id}`, {
      headers: {
        [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME),
      },
    });
    const user = (request.data as { data: User }).data;
    return setDefaultParamsToUser(user);
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
}

export async function updateExistingUser(token: string, data: User) {
  let user;
  try {
    const request = await axiosClient.put(
      `/users/${data.id}`,
      JSON.stringify(data),
      {
        headers: {
          'x-auth-token': token,
          'content-type': 'application/json',
        },
      },
    );
    user = (request.data as { data: User }).data;
    setDefaultParamsToUser(user);
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
  return user;
}

function setDefaultParamsToUser(user: User) {
  user.roles = user.roles.map((role) => {
    role.roleAuthorities = role.roleAuthorities.filter((v) => !!v && v);
    return role;
  });
  return user;
}

export async function syncUsersRdm() {
  try {
    await axiosClient.get(`/sync-task/users`, {
      headers: {
        'x-auth-token': localStorage.getItem('elib-token'),
      },
    });
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
}

export async function syncDepartmentsRdm() {
  try {
    await axiosClient.get(`/sync-task/departments`, {
      headers: {
        'x-auth-token': localStorage.getItem('elib-token'),
      },
    });
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
}
