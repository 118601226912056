























import { Vue, Component, Prop } from 'vue-property-decorator';
import { DropDownActions } from '@/models/view.model';
import { mapGetters, mapMutations } from 'vuex';
import { Document, DocumentsContent } from '@/models/user-documents.model';
import { hasAuthoritiesByCode } from '@/commons/authoritiesCheck';
import { fetchDocumentsContent } from '@/controllers/user-documents.controllers';
import * as types from '@/store/types';

const baseClassName = 'elib-documents-edit-form-fileInfos';

@Component({
  name: baseClassName,
  computed: {
    ...mapGetters('session', [types.HAS_AUTHORITY_BY_CODE]),
  },
  methods: {
    ...mapMutations('documents', [types.CREATE_NEW_DOCUMENT_ON_BASIS, types.UPDATE_ACTIVE_DOCUMENTS_PROP]),
  },
})
export default class DocumentsEditFormAttachments extends Vue {
  @Prop({ required: true }) private activeDocument!: Document;
  private baseClassName = baseClassName;
  private self: any;
  private fetchDocumentsContent = fetchDocumentsContent;

  private beforeCreate() {
    this.self = this;
  }

  private get fileInfos(): DocumentsContent[] {
    return this.activeDocument.contents || [];
  }

  private get attachments(): File[] {
    return this.activeDocument.attachments || [];
  }

  private appendAttachmentToDocument(data: { fileInfos: DocumentsContent[], files: File[] }) {
    if (!this.checkUploadedFileUnique(data)) {
      this.$notifier({ title: 'Ошибка при добавлении файла!', message: 'Загружаемый документ был загружен ранее, выберите другой файл.', type: 'error' });
      return;
    }
    const fileInfos: DocumentsContent[] = [...this.fileInfos, ...data.fileInfos];
    this.self[types.UPDATE_ACTIVE_DOCUMENTS_PROP]({ prop: 'contents', value: fileInfos });
    const attachments: File[] = [...this.attachments, ...data.files];
    this.self[types.UPDATE_ACTIVE_DOCUMENTS_PROP]({ prop: 'attachments', value: attachments });
  }

  private checkUploadedFileUnique(data: { fileInfos: DocumentsContent[], files: File[] }) {
    const fileNames = data.fileInfos.map((info) => info.fileName);
    const hasDuplicates = this.fileInfos.some((info) => fileNames.includes(info.fileName));
    return !hasDuplicates;
  }

  private deleteFilesAttachment(data: DocumentsContent) {
    const value: DocumentsContent[] = [...this.fileInfos].filter((v) => v.fileName !== data.fileName);
    this.self[types.UPDATE_ACTIVE_DOCUMENTS_PROP]({ prop: 'contents', value });
    const attachments: File[] = [...this.attachments].filter((v) => v.name !== data.fileName);
    this.self[types.UPDATE_ACTIVE_DOCUMENTS_PROP]({ prop: 'attachments', value: attachments });
  }
}
