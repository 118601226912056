import { Dictionary, DictionariesValue, DictionaryValueDTO } from './dictionaries.model';
import { queryDocumentTypes, fetchDocumentTypeView } from '@/controllers/catalogues.controllers';


export enum PredefinedAttributes {
    COMPANY_GROUP = 'company_group',
    NAME_OF_THE_LEGAL_ENTITY = 'name_of_the_legal_entity',
    INN = 'inn',
    DOSSIER_SECTION = 'dossier_section',
    DOCUMENT_FORMAT = 'document_format',
    SHORT_TITLE = 'short_title',
    FULL_TITLE = 'full_title',
    DOCUMENT_KIND = 'document_kind',
    NOTIFICATIONS = 'notifications',
    DOCUMENT_OWNERSHIP = 'document_ownership',
    DOCUMENT_PERIOD_YEAR = 'document_period_year',
    DOSSIER_KIND = 'dossier_kind',
    DOCUMENT_PERIOD = 'document_period',
}

export enum AttributesTypeEnum {
    STRING = 'STRING',
    DATE = 'DATE',
    BOOLEAN = 'BOOLEAN',
    NUMBER = 'NUMBER',
    DICTIONARY = 'DICTIONARY',
}

export enum DocumentStaticAttributes {
    DOCUMENT_TYPE = 'document_type',
    DOCUMENT_NUMBER = 'document_number',
    DOCUMENT_DATE = 'document_date',
    DOCUMENT_OPERATION = 'document_operation',
}

export const attributeTypes: AttributesType[] = [
    { id: 1, code: 'STRING', title: 'Строка' },
    { id: 2, code: 'DATE', title: 'Дата' },
    { id: 3, code: 'BOOLEAN', title: 'Логический' },
    { id: 4, code: 'NUMBER', title: 'Число' },
    { id: 7, code: 'DICTIONARY', title: 'Справочник' },
];


export const staticAttributesDictionary = [
    {
        code: 'document_type',
        placeholder: 'Введите названия типа документа',
        valueKey: 'title',
        fetchFunction: queryDocumentTypes,
        fetchViewFunction: fetchDocumentTypeView,
    },
];

export interface DocumentsAttribute {
    attributeType: AttributesType;
    authorId: number;
    code: string;
    defaultValue: string | number | boolean | null | DictionaryValueDTO;
    deleted: boolean;
    dictionary?: Dictionary;
    documentDuplicatesControl: boolean;
    id: number;
    group: string;
    guid: string;
    required: boolean;
    searchable: boolean;
    title: string;
}

export interface DocumentsAttributeOriginal extends DocumentsAttribute {
    originalAttributeType?: AttributesType;
    showInRoles?: boolean;
}

export interface DocumentsStaticAttribute {
    attributeType: AttributesType;
    code: string;
    id: number | string;
    title: string;
    static?: true;
    idKey?: string;
    valueKey?: string;
    assumptionsFunction?: (...args: any) => any | void;
    formatFunction?: (...args: any) => any | void;
}

export interface AttributesType {
    id: number;
    code: string;
    title: string;
}

export interface AttributesTypeFilter {
    type: AttributesType | null;
    dictionary: Dictionary | null;
    dictionariesValue: DictionariesValue | null;
}

export function getEmptyDocumentsAttribute(): DocumentsAttribute {
    return {
        attributeType: { id: 1, code: 'string', title: 'Строка' },
        authorId: 0,
        code: '',
        defaultValue: '',
        deleted: false,
        documentDuplicatesControl: false,
        id: 0,
        group: '',
        guid: '',
        required: false,
        searchable: false,
        title: '',
    };
}
export function defaultValueIsBoolean(attribute: DocumentsAttribute) {
    return attribute.attributeType &&
        attribute.attributeType.code === 'BOOLEAN'
        && typeof attribute.defaultValue === 'string';
}
