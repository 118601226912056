













































import { Vue, Component, Prop, Model, Watch } from 'vue-property-decorator';
import { TreeViewData } from '../../models/view.model';

const baseClassName = 'elib-input-autocomplete-tree';

@Component({
  name: baseClassName,
})
export default class InputAutocomplete extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  @Prop({ default: () => '' }) private placeholder!: string;
  @Prop({ default: () => '' }) private treeData!: TreeViewData[];
  @Model('change', { type: String, default: () => '' }) private value!: string;
  @Prop({ default: () => '' }) private lastLevelIcon!: string;
  @Prop({ default: () => false }) private required!: boolean;
  private baseClassName = baseClassName;
  private selectIsActive = false;
  private inputvalue!: string;
  private showFoundedList = false;
  private foundedNodes: TreeViewData[] = [];

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private get showSearchResults() {
    return this.selectIsActive && this.showFoundedList;
  }

  private get showTree() {
    return this.selectIsActive && !this.showFoundedList;
  }

  private created() {
    this.inputvalue = this.value;
  }

  private onClickOutside() {
    this.selectIsActive = false;
  }

  private onTreeNodeSelect(node: TreeViewData) {
    this.selectIsActive = false;
    this.showFoundedList = false;
    this.foundedNodes = [];
    this.$emit('select', node);
  }

  @Watch('value')
  private onValueChange(newValue: string) {
    this.inputvalue = newValue;
  }

  private onInput(value: string) {
    this.foundedNodes = this.findTreeNodes(this.treeData, value);
    this.inputvalue = value;
    this.showFoundedList = true && this.inputvalue.trim().length > 0;
  }

  private findTreeNodes(treesData: TreeViewData[], filter: string): TreeViewData[] {
    let stack = [...treesData];
    const foundedNodes = [];
    while (stack.length > 0) {
      const currentNode = stack.shift() as TreeViewData;
      if (RegExp(filter, 'gi').test(currentNode.value)) {
        foundedNodes.push(currentNode);
      }
      stack = [...stack, ...currentNode.children];
    }
    return foundedNodes;
  }
}
