















import { Vue, Component, Prop } from 'vue-property-decorator';

const baseClassName = 'elib-input-boolean';

@Component({
  name: baseClassName,
})
export default class InputBoolean extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  @Prop({ default: () => '' }) private label!: string;
  @Prop({ default: () => '' }) private value!: boolean;
  @Prop({ default: () => false }) private horizontal!: boolean;
  @Prop({ default: () => false }) private readonly!: boolean;
  private baseClassName = baseClassName;
}
